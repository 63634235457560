import { PREVIEW, PROJECT_LINK } from "../../project-config";

export default function footerContent(langState, isLoggedIn) {
  let langCode = langState == "en" || langState == "tr" ? "" : `/${langState}`;

  const hideIfLoggedIn = () => {
    if (isLoggedIn) return { style: { display: isLoggedIn ? "none" : "" } };
    return {};
  };

  return {
    about: [
      {
        parent: { attributes: {} },
        children: [
          {
            attributes: {
              to: `${PREVIEW}/page/terms-of-use`
            },
            text: "Terms Of Use"
          },
          {
            attributes: {
              to: `${PREVIEW}/page/shipping-information`
            },
            text: "Shipping Information"
          },
          {
            attributes: {
              to: `${PREVIEW}/page/return-policy`
            },
            text: "Return Policy"
          },
          {
            attributes: {
              to: `${PREVIEW}/page/privacy-policy`
            },
            text: "Privacy Policy"
          }
        ]
      }
    ],

    shop: [
      {
        parent: { attributes: {} },
        children: [
          /*   {
            attributes: {
              href: `mailto:ecommerce@avetti.com`
            },
            text: "E-mail : ecommerce@avetti.com"
          }, */
          {
            attributes: {
              href: `mailto:BankService@deluxe.ca`
            },
            text: "Contact Us"
          }
          /*    {
            attributes: {
              target: "_blank",
              href: `${PREVIEW}/register.html?vid=20200603781&mt=1&ml=en`
            },
            text: "Seller Registration"
          } */
        ]
      }
    ],
    socialMedia: [
      {
        parent: { attributes: { className: "socialMedia" } },
        children: [
          {
            attributes: {
              target: "_blank",
              href: "https://www.facebook.com/avetticommerce"
            },
            image: `https://dkadmin.avetti.ca/preview/store//20170828404/category/Facebook.png`,
            alt: "facebook icon"
          },
          {
            attributes: {
              target: "_blank",
              href: "https://www.linkedin.com/"
            },
            image: `https://dkadmin.avetti.ca/preview/store//20170828404/category/YouTube.png`,
            alt: "youtube icon"
          },

          {
            attributes: {
              target: "_blank",
              href: "https://www.twitter.com/avetticom"
            },
            image: `https://dkadmin.avetti.ca/preview/store//20170828404/category/Twitter.png`,
            alt: "twitter icon"
          }
        ]
      }
    ]
  };
}
