import React, { useContext, useMemo } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { I18nContext } from "../../../../../../i18n/index";
import Button from "../../../ChequeCustomization/components/Button";
import { validateEmail } from "../../../../../../functions/Utilities";

import { FormattedNumber } from "react-intl";
import { VID } from "../../../../../../project-config";
import {
  getDeliveryOptions,
  addToLocalMiniCart,
  setAddToCartValidationErrors,
  setProductOutOfStockError,
  addToCartProduct,
  setPersonalizedQuestionsProgressAction,
  showContinueModalAfterAddingToCartAction,
  setChequeValidationErrors,
  reFetchProductInitialState,
  fetchingProductRequestSaga,
  requestPersonalizedQuestionsAction,
  setPersonalizedQuestionsAction,
  saveInputsAction,
  displayLoadingForReviewProcessAction,
  jumpToReviewTabOnCustomizerAction
} from "../../../../../../redux/actions/productAction";
import postPersonalizedQuestions from "../../../ChequeCustomization/utils/postPersonalizedQuestions";
import { LIFT_REVIEW_FLAG } from "../../../../../../redux/links";
import {
  addToCartActions,
  REQUEST_BASKET_AFTER_ADDING_TO_CART
} from "../../../../../../redux/types";
// import arrow_forward_white from "../../../../assets/icons/arrow_forward_white.svg";
// import arrow_back_white from "../../../../assets/icons/arrow_back_white.svg";

export default function GrandTotalAndAddToCartButton({
  addToCartBtnRef,
  selectedPackage,
  packagePrices,
  inStock,
  distributorId,
  numberOfItems,
  renderedBy
}) {
  const { translate, currency, priceConvert } = useContext(I18nContext);
  const dispatch = useDispatch();
  const langState = useSelector(state => state.mainReducer.lang, shallowEqual);

  const isProductDetailsLoading = useSelector(
    state => state.productReducer.loading,
    shallowEqual
  );

  const requestingAddToCartState = useSelector(
    state => state.productReducer.requestingAddToCart,
    shallowEqual
  );

  const hiddenPropertiesState = useSelector(
    state => state.productReducer.itemDetail.hiddenProperties,
    shallowEqual
  );

  const attributesState = useSelector(
    state => state.productReducer.itemDetail.attributes,
    shallowEqual
  );

  const startNumberPrefixMaxLengthProp = useMemo(() => {
    if (hiddenPropertiesState) {
      const foundProp = hiddenPropertiesState.find(
        p => p.propname.toLowerCase() === "comet_startnumberprefix_maxlength"
      );

      if (foundProp) return foundProp.propvalue;
      else return null;
    }
    return null;
  }, [hiddenPropertiesState]);

  const personalizedQuestionInputsState = useSelector(
    state => state.productReducer.personalizedQuestionsProgress.inputs,
    shallowEqual
  );
  const personalizedQuestionsState = useSelector(
    state => state.productReducer.personalizedQuestions,
    shallowEqual
  );

  const personalizedQuestionsValidationsState = useSelector(
    state => state.productReducer.personalizedQuestionsValidations,
    shallowEqual
  );

  const personalizedItemState = useSelector(
    state => state.productReducer.itemDetail.personalized,
    shallowEqual
  );

  const personalizedQuestionsProgressState = useSelector(
    state => state.productReducer.personalizedQuestionsProgress,
    shallowEqual
  );

  const packagePriceEnabledState = useSelector(
    state => state.productReducer.itemDetail.packagePriceEnabled,
    shallowEqual
  );

  const selectedProductAttributesState = useSelector(
    state => state.productReducer.selectedProductAttributes,
    shallowEqual
  );

  const attributeDetailsState = useSelector(
    state => state.productReducer.itemDetail.attributeDetails,
    shallowEqual
  );

  const productInitialProperties = useSelector(
    state => state.productReducer.productInitial.properties,
    shallowEqual
  );

  const bundleItemsProgressState = useSelector(
    state =>
      state.productReducer.personalizedQuestionsProgress.bundleItemsProgress,
    shallowEqual
  );

  const bundleItemsState = useSelector(
    state => state.productReducer.personalizedQuestionsProgress.bundleItems,
    shallowEqual
  );

  const productInitialBundleState = useSelector(
    state => state.productReducer.productInitial.bundle,
    shallowEqual
  );

  const productHaveBundleItems = useMemo(() => {
    if (productInitialBundleState) {
      if (
        productInitialBundleState &&
        Array.isArray(productInitialBundleState) &&
        productInitialBundleState.length > 0
      ) {
        return true;
      } else return false;
    }
    return undefined;
  }, [productInitialBundleState]);

  const currentItemIsBundleItemState = useSelector(
    state =>
      state.productReducer.personalizedQuestionsProgress
        .currentItemIsBundleItem,
    shallowEqual
  );

  const biidState = useSelector(
    state => state.productReducer.biid,
    shallowEqual
  );

  const biidReviewState = useSelector(
    state => state.productReducer.biidReview,
    shallowEqual
  );

  const displayLoadingForReviewProcessState = useSelector(
    state => state.productReducer.displayLoadingForReviewProcess,
    shallowEqual
  );

  const basketState = useSelector(
    state => state.sessionReducer.basket,
    shallowEqual
  );

  const selectedPackageForKitItemState = useSelector(
    state =>
      state.productReducer.personalizedQuestionsProgress
        .selectedPackageForKitItem,
    shallowEqual
  );

  const itemDetailState = useSelector(
    state => state.productReducer.itemDetail,
    shallowEqual
  );
  const mainItemIdState = useSelector(
    state => state.productReducer.itemDetail.mainitemid,
    shallowEqual
  );

  const skusState = useSelector(
    state => state.productReducer.itemDetail.skus,
    shallowEqual
  );

  const itemIdState = useSelector(
    state => state.productReducer.itemDetail.itemid,
    shallowEqual
  );

  const handleAddToCart = (quoteMode = false) => {
    if (!inStock) {
      dispatch(setProductOutOfStockError(true));
      return;
    }
    let attributesObject = null;

    let requiredFields = [];

    if (
      selectedProductAttributesState.hasOwnProperty(
        mainItemIdState || itemIdState
      )
    ) {
      attributesObject =
        selectedProductAttributesState[mainItemIdState || itemIdState];

      let expectedAttributes = attributeDetailsState.reduce((p, c) => {
        const { screenname, attributeid, dataname } = c;
        p = [...p, { screenname, attributeid, dataname }];
        return p;
      }, []);

      let receivedAttributeIds = Object.keys(attributesObject);

      const attrHaveEmptyText = (attributesObject, attr) => {
        return (
          attributesObject &&
          attributesObject[attr.attributeid] &&
          attributesObject[attr.attributeid].value === ""
        );
      };

      if (!currentItemIsBundleItemState) {
        expectedAttributes.forEach(attr => {
          console.info("attr444", mainItemIdState, expectedAttributes, attr);

          if (
            !receivedAttributeIds.includes(attr.attributeid.toString()) ||
            attrHaveEmptyText(attributesObject, attr)
          )
            if (!attr.dataname.toLowerCase().includes("customization"))
              requiredFields.push(attr.screenname);
        });
      }
    } else {
      if (
        attributeDetailsState &&
        attributeDetailsState.length > 0 &&
        !currentItemIsBundleItemState
      ) {
        let expectedAttributes = attributeDetailsState.reduce((p, c) => {
          const { screenname, attributeid } = c;
          p = [...p, { screenname, attributeid }];
          return p;
        }, []);

        expectedAttributes.forEach(attr => {
          requiredFields.push(attr.screenname);
        });
      }
    }

    dispatch(setAddToCartValidationErrors(requiredFields));

    if (requiredFields.length > 0) {
      return;
    }

    if (itemDetailState.personalized) {
      if (
        bundleItemsState &&
        bundleItemsState.length > 0 &&
        currentItemIsBundleItemState === false
      ) {
        dispatch(
          setPersonalizedQuestionsProgressAction({
            displayBundleItemsMessage: true
          })
        );
      }

      console.info(
        "cheque initial6",
        currentItemIsBundleItemState,
        bundleItemsState
      );
      if (currentItemIsBundleItemState === true && bundleItemsState) {
        let options = { startNumberPrefixMaxLengthProp };
        requestPersonalizedQuestionsAction(
          dispatch,
          itemDetailState.itemid,
          null,
          options
        );
        return;
      }

      let invhistid = "";
      dispatch(
        addToCartProduct(
          itemDetailState.itemid,
          selectedPackage.text,
          invhistid,
          distributorId,
          attributesObject,
          quoteMode,
          null,
          itemDetailState.personalized,
          false,
          null,
          false,
          null,
          biidState,
          biidReviewState
        )
      );
    } else if (bundleItemsState && bundleItemsState.length > 0) {
      let invhistid = "";
      dispatch(
        addToCartProduct(
          itemDetailState.itemid,
          selectedPackage.text,
          invhistid,
          distributorId,
          attributesObject,
          quoteMode,
          null,
          false,
          true,
          bundleItemsState,
          currentItemIsBundleItemState,
          bundleItemsProgressState,
          biidState,
          biidReviewState
        )
      );
    } else {
      dispatch(
        getDeliveryOptions(
          distributorId,
          itemDetailState.code,
          packagePriceEnabledState ? selectedPackage.text : numberOfItems,
          itemDetailState.itemid,
          attributesObject,
          quoteMode,
          null,
          biidState,
          biidReviewState
        )
      );
    }

    /* 
  
   let firstDist = supplierInfoState[0].distributorOrder[0];
    let vid = firstDist.supplier_store_vid;

  dispatch(
      getDeliveryOptions(
        priceState.prices.find(inv => inv.distributorId == firstDistId)
          .distributorId,
        priceState.code,
        numberOfItems,
        priceState.itemid,
        attributesObject,
        quoteMode,
        vid
      ) 
    ) */
  };

  const handleValidation = progress => {
    let failedValidationFields = {};

    if (
      personalizedQuestionsState &&
      Object.keys(personalizedQuestionsState).length > 0 &&
      personalizedQuestionsValidationsState &&
      Object.keys(personalizedQuestionsValidationsState).length > 0
    ) {
      const handleValidationForTheSection = field => {
        let tabQuestionsIds = personalizedQuestionsState[field].reduce(
          (a, c) => {
            a.push(c.quesID);
            return a;
          },
          []
        );

        const validationsQuestionIds = Object.keys(
          personalizedQuestionsValidationsState
        );

        validationsQuestionIds.forEach(qID => {
          if (
            tabQuestionsIds.includes(qID) &&
            personalizedQuestionsValidationsState[qID]
          ) {
            if (personalizedQuestionsValidationsState[qID] === "required") {
              const input = personalizedQuestionInputsState[qID];

              if (!input) {
                failedValidationFields[qID] = {
                  qID,
                  dID: null,
                  validationType: "required"
                };
              }
            }

            const detailIds =
              typeof personalizedQuestionsValidationsState[qID] === "object" &&
              Object.keys(personalizedQuestionsValidationsState[qID]);

            detailIds &&
              detailIds.forEach(dID => {
                const validationTypes = Object.keys(
                  personalizedQuestionsValidationsState[qID][dID]
                ).filter(
                  key =>
                    !["message", "active", "setActiveOnMount"].includes(key)
                );

                const active =
                  personalizedQuestionsValidationsState[qID][dID].active ||
                  personalizedQuestionsValidationsState[qID][dID].active ===
                    undefined;

                validationTypes.forEach(validationType => {
                  if (validationType === "type" && active) {
                    const validation =
                      personalizedQuestionsValidationsState[qID][dID][
                        validationType
                      ];

                    const _for =
                      personalizedQuestionsValidationsState[qID][dID].for;

                    const message =
                      personalizedQuestionsValidationsState[qID][dID].message;

                    if (validation === "invalid") {
                      if (personalizedQuestionInputsState[qID] === dID) {
                        const input = "Yes";
                        if (
                          failedValidationFields.hasOwnProperty(qID) === false
                        ) {
                          failedValidationFields[qID] = {
                            qID,
                            dID,
                            for: _for,
                            validationType,
                            validation,
                            input
                          };
                        }
                      }
                    }

                    if (
                      personalizedQuestionInputsState &&
                      personalizedQuestionInputsState[dID] !== undefined
                    ) {
                      const input = personalizedQuestionInputsState[dID];

                      if (validation === "email") {
                        if (!validateEmail(input)) {
                          if (
                            failedValidationFields.hasOwnProperty(qID) === false
                          ) {
                            failedValidationFields[qID] = { [dID]: [] };
                          }
                          failedValidationFields[qID][dID].push({
                            qID,
                            dID,
                            validationType,
                            validation,
                            input
                          });
                        }
                      } else if (validation === "required") {
                        const _for =
                          personalizedQuestionsValidationsState[qID][dID].for;

                        if (!input) {
                          if (
                            failedValidationFields.hasOwnProperty(qID) === false
                          ) {
                            failedValidationFields[qID] = { [dID]: [] };
                          }
                          failedValidationFields[qID][dID].push({
                            qID,
                            dID,
                            message,
                            for: _for,
                            validationType,
                            validation,
                            input
                          });
                        }
                      }
                    }
                  }
                  if (validationType === "length" && active) {
                    const validation =
                      personalizedQuestionsValidationsState[qID][dID][
                        validationType
                      ];
                    const { allowBlank, min, max } = validation;

                    if (
                      personalizedQuestionInputsState &&
                      personalizedQuestionInputsState[dID] !== undefined
                    ) {
                      const input = personalizedQuestionInputsState[dID];

                      if (allowBlank && input === "") {
                      } else if (
                        input === "Not provided:  Leave blank" ||
                        input.length < min ||
                        input.length > max
                      ) {
                        if (
                          failedValidationFields.hasOwnProperty(qID) === false
                        ) {
                          failedValidationFields[qID] = { [dID]: [] };
                        } else if (
                          failedValidationFields[qID].hasOwnProperty(dID) ===
                          false
                        ) {
                          failedValidationFields[qID] = {
                            ...failedValidationFields[qID],
                            [dID]: []
                          };
                        }
                        failedValidationFields[qID][dID].push({
                          qID,
                          dID,
                          validationType,
                          validation,
                          input
                        });
                      }
                    }
                  }
                  if (validationType === "digitsOnly" && active) {
                    const validation =
                      personalizedQuestionsValidationsState[qID][dID][
                        validationType
                      ];

                    if (
                      validation &&
                      personalizedQuestionInputsState &&
                      personalizedQuestionInputsState[dID] !== undefined
                    ) {
                      const input = personalizedQuestionInputsState[dID];
                      if (isNaN(input)) {
                        if (
                          failedValidationFields.hasOwnProperty(qID) === false
                        ) {
                          failedValidationFields[qID] = { [dID]: [] };
                        }
                        failedValidationFields[qID][dID].push({
                          qID,
                          dID,
                          validationType,
                          validation,
                          input
                        });
                      }
                    }
                  }
                });
              });
          }
        });
      };
      if (progress === 1) {
        handleValidationForTheSection("textFields");
      } else if (progress === 2) {
        // if upload logo is selected and no logo uploaded
        checkIfUploadLogoModeAndNoLogoUploaded(failedValidationFields);

        handleValidationForTheSection("restOfTheFields");
        handleValidationForTheSection("dependedFields");
      }
    }

    dispatch(setChequeValidationErrors(failedValidationFields));
    return failedValidationFields;
  };

  const checkIfUploadLogoModeAndNoLogoUploaded = failedValidationFields => {
    if (
      personalizedQuestionsProgressState.logo.logoMode === "uploadLogo" &&
      personalizedQuestionsProgressState.logo.uploadedLogo === null &&
      personalizedQuestionsProgressState.logo.tiff === null
    ) {
      failedValidationFields["logo-upload-wrapper"] = {
        qID: "logo-upload-wrapper"
      };
    }
  };

  const renderAddToCartPreviousBtn = () => {
    return (
      personalizedQuestionsProgressState.progress > 0 && (
        <Button
          className="add-to-cart-previous-btn"
          onClick={() => {
            if (personalizedQuestionsProgressState.progress === 1) {
              dispatch(jumpToReviewTabOnCustomizerAction(false));
              dispatch(saveInputsAction());
            }
            dispatch(
              setPersonalizedQuestionsProgressAction({
                displayQuestions:
                  personalizedQuestionsProgressState.progress === 1
                    ? false
                    : true,
                progress: personalizedQuestionsProgressState.progress - 1
              })
            );
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            {/* <img src={arrow_back_white} alt="arrow back" /> */}
            Previous
          </span>
        </Button>
      )
    );
  };

  const renderAddToCartBtnText = () => {
    if (personalizedQuestionsProgressState.displayQuestions) {
      if (
        personalizedQuestionsProgressState.progress === 3 &&
        bundleItemsState
      ) {
        if (
          (currentItemIsBundleItemState === false &&
            bundleItemsState &&
            bundleItemsState.length > 0) ||
          bundleItemsProgressState <
            (bundleItemsState && bundleItemsState.length - 1)
        )
          return (
            <span>
              Next
              {/*   <img src={arrow_forward_white} alt="arrow forward" /> */}
            </span>
          );
      }

      return personalizedQuestionsProgressState.progress === 3 ? (
        <span>{biidState ? `Confirm & Update Cart` : `Add to Cart`}</span>
      ) : (
        <span>
          Next{/*  <img src={arrow_forward_white} alt="arrow forward" /> */}
        </span>
      );
    } else
      return personalizedItemState ||
        (currentItemIsBundleItemState &&
          bundleItemsProgressState <
            (bundleItemsState && bundleItemsState.length - 1)) ? (
        <span>
          Next{/*  <img src={arrow_forward_white} alt="arrow forward" /> */}
        </span>
      ) : bundleItemsState &&
        bundleItemsState.length > 0 &&
        personalizedQuestionsProgressState.progress === 0 &&
        currentItemIsBundleItemState === false ? (
        <span>Next</span>
      ) : (
        <span>{biidState ? `Confirm & Update Cart` : `Add to Cart`}</span>
      );
  };

  const renderStockStatus = () => {
    if (inStock) {
      return null;
    } else {
      return <div className="add-to-cart-stock-status">Out of Stock</div>;
    }
  };

  const renderGrandTotal = () => {
    return (
      !isProductDetailsLoading &&
      packagePrices &&
      packagePrices.length > 0 && (
        <div className="add-to-cart-grand-total-wrapper">
          <div className="quantity-wrapper">
            <p
              style={{
                fontSize: "1.08em",
                fontWeight: 600
              }}
            >
              Quantity:
            </p>
            &nbsp;
            <p
              style={{
                fontSize: "1.08em",
                fontWeight: 600
              }}
              dangerouslySetInnerHTML={{ __html: selectedPackage.text }}
            ></p>
          </div>
          <div className="sub-total-wrapper">
            <p
              style={{
                fontSize: "1.08em",
                fontWeight: 600
              }}
            >
              Subtotal:
            </p>
            &nbsp;
            <FormattedNumber
              value={selectedPackage.value}
              style="currency"
              currency={currency}
              children={value => (
                <p
                  style={{
                    fontSize: "1.08em",
                    fontWeight: 600
                  }}
                  className="add-to-cart-grand-total-price"
                >
                  {value}
                </p>
              )}
            />
          </div>
        </div>
      )
    );
  };
  return (
    <div
      className="grand-total-container"
      style={{
        marginTop: "auto",
        paddingTop: "0px",
        padding: renderedBy === "chequeCustomization" ? "0 1rem" : ""
      }}
    >
      {renderGrandTotal()}
      <div className="add-to-cart-btn-container">
        {/*  {renderStockStatus()} */}
        {/*    <div
                id="buyBoxAddToCartBtn"
                className={inStock ? "" : "out-of-stock"}
              >
                <div
                  className={`addToCartBtn${shouldWiggle ? " wiggle" : ""}`}
                  title={translate("js.item.addtocart")}
                  onClick={() => handleAddToCart()}
                >
                </div>
              </div> */}
        {renderAddToCartPreviousBtn()}

        <Button
          btnRef={addToCartBtnRef}
          id="add-to-cart-btn"
          primary
          onClick={e => {
            e.preventDefault();
            if (isProductDetailsLoading) {
              return;
            }
            const REDIRECT_TO_BASKET_DIRECTLY_AFTER_ADDTOCART =
              process.env.REACT_APP_REDIRECT_TO_BASKET;
            // ONCLICK ADDTOCART
            const callBack = (statusCode, bundleItem = false) => {
              console.info(
                "post personalized questions",
                statusCode,
                bundleItem
              );
              if (statusCode === 200) {
                let quoteMode = false;
                dispatch(
                  setPersonalizedQuestionsProgressAction({
                    confirmLeave: false
                  })
                );
                if (!bundleItem) {
                  // lift review flag if it's review state
                  if (biidReviewState) fetch(LIFT_REVIEW_FLAG(VID, biidState));

                  if (REDIRECT_TO_BASKET_DIRECTLY_AFTER_ADDTOCART) {
                    const payload = {
                      vid: VID,
                      languageState: langState,
                      biid: biidState,
                      addedItemId:
                        personalizedQuestionsProgressState.bundleItemParentId ||
                        itemIdState
                    };
                    dispatch(
                      showContinueModalAfterAddingToCartAction(
                        quoteMode,
                        payload
                      )
                    );
                    return; // RETURN
                  } else {
                    dispatch(
                      showContinueModalAfterAddingToCartAction(quoteMode)
                    );
                  }

                  dispatch({
                    type: addToCartActions.SUCCESS_ADD_TO_CART,
                    payload: quoteMode ? "addQuote" : "addItem"
                  });
                  dispatch({
                    type: REQUEST_BASKET_AFTER_ADDING_TO_CART
                  });
                } else {
                }
                // clear the questions
                dispatch(setPersonalizedQuestionsAction({}));
                // clear the progress state
                dispatch(
                  setPersonalizedQuestionsProgressAction({
                    confirmLeave: false,
                    displayQuestions: false,
                    displayBundleItemsMessage: false,
                    progress: 0,
                    inputs: {},
                    font: {
                      selectedFont: "",
                      "Imprint Colour": "",
                      "2nd Ink Colour": ""
                    },
                    logo: {
                      logoMode: "",
                      selectedClipart: null,
                      uploadedLogo: null
                    }
                  })
                );
              }
            };

            if (personalizedQuestionsProgressState.displayQuestions) {
              if (personalizedQuestionsProgressState.progress === 3) {
                // main product is being displayed and will fetch the first bundle product available
                if (
                  currentItemIsBundleItemState === false &&
                  bundleItemsState &&
                  bundleItemsState.length > 0 &&
                  bundleItemsProgressState === 0
                ) {
                  let firstBundleItemId = bundleItemsState[0].id;
                  dispatch(reFetchProductInitialState(null, firstBundleItemId));
                  dispatch(fetchingProductRequestSaga(firstBundleItemId));

                  return;
                }
                // there are more bundle items to customize
                else if (
                  currentItemIsBundleItemState === true &&
                  bundleItemsState &&
                  bundleItemsState.length > 0 &&
                  bundleItemsProgressState < bundleItemsState.length - 1
                ) {
                  const nextProgressState = bundleItemsProgressState + 1;
                  const currentBundleItemId =
                    bundleItemsState[bundleItemsProgressState].id;
                  const nextBundleItemId =
                    bundleItemsState[nextProgressState].id;

                  postPersonalizedQuestions(
                    currentBundleItemId,
                    personalizedQuestionInputsState,
                    personalizedQuestionsProgressState,
                    statusCode => callBack(statusCode, true),
                    true
                  );
                  dispatch(reFetchProductInitialState(null, nextBundleItemId));
                  dispatch(fetchingProductRequestSaga(nextBundleItemId));

                  return;
                }
                console.info("bundleItem--1", bundleItemsProgressState);
                // Last bundle item or not a bundle item
                return postPersonalizedQuestions(
                  itemDetailState.itemId,
                  personalizedQuestionInputsState,
                  personalizedQuestionsProgressState,
                  callBack
                );
              }

              let failedValidationFields = handleValidation(
                personalizedQuestionsProgressState.progress
              );

              if (Object.keys(failedValidationFields).length > 0) {
                return;
              }
              dispatch(
                setPersonalizedQuestionsProgressAction({
                  progress: personalizedQuestionsProgressState.progress + 1
                })
              );
            } else {
              // does not have personalized questions but it's the last bundle item
              if (
                currentItemIsBundleItemState === true &&
                bundleItemsProgressState === bundleItemsState.length - 1 &&
                personalizedItemState === false
              ) {
                return postPersonalizedQuestions(
                  itemDetailState.itemId,
                  personalizedQuestionInputsState,
                  personalizedQuestionsProgressState,
                  callBack
                );
              } else if (
                currentItemIsBundleItemState === true &&
                personalizedItemState === false &&
                bundleItemsProgressState < bundleItemsState.length - 1
              ) {
                // current item is a non-personalized bundle item and there are more bundle items to fetch
                const nextProgressState = bundleItemsProgressState + 1;
                const currentBundleItemId =
                  bundleItemsState[bundleItemsProgressState].id;
                const nextBundleItemId = bundleItemsState[nextProgressState].id;

                dispatch(reFetchProductInitialState(null, nextBundleItemId));
                dispatch(fetchingProductRequestSaga(nextBundleItemId));

                return;
              }
              // current item is not a bundle item and does not have questions
              if (bundleItemsState && bundleItemsState.length > 0) {
                dispatch(
                  setPersonalizedQuestionsProgressAction({
                    selectedPackageForKitItem: selectedPackage
                  })
                );
              }
              handleAddToCart();
            }
          }}
        >
          <div className="add-to-cart-btn-text">{renderAddToCartBtnText()}</div>
        </Button>
        {/* <div id="buyBoxQuoteBtn">
                <div
                  className="addToCartBtn"
                  title={translate("js.item.addtoquote")}
                  onClick={() => handleAddToCart(true)}
                >
                  {translate("js.item.addtoquote")}
                </div>
              </div> */}
        {/* <div id="buyBoxEnquiryBtn">
                <div
                  id="enquiry-204"
                  className="addToCartBtn sendEnquiryBtn"
                  title={translate("js.item.enquiry")}
                  onClick={handleEnquiryModalOpenClicked}
                >
                  <div>{translate("js.item.enquiry")}</div>
                  
                </div>
              </div> */}
      </div>
    </div>
  );
}
