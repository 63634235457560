import React, { useState, useEffect, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  confirmLeaveAction,
  fetchMainProductSkusAndSkuIds
} from "../../redux/actions/productAction";
import "../../assets/css/product-sl.css";
import "./Styles/ProductPage.css";
import ImageCarousel from "./Components/ImageCarousel/ImageCarousel";
import OtherInfoTab from "./Components/OtherInfoTab/OtherInfoTab";
// import VendorList from "./Components/Vendors/VendorList";
import AddToCartBox from "./Components/AddToCart/AddToCartBox";
import AddedToCartModal from "./Components/AddToCart/Components/AddedToCartModal";
// import RecentlyViewedItems from "../../components/AC-RecentlyViewedItems/RecentlyViewedItems";
import HelmetSeo from "../../shared/components/AC-Helmet/HelmetSeo";
import EnquiryModal from "./Components/AddToCart/Components/Enquiry/EnquiryModal.jsx";
// import Reviews from "./Components/Review/components/Reviews";
import CartValidationErrors from "./Components/CartValidationErrors/CartValidationErrors";
import ConfirmDeliveryOptions from "./Components/AddToCart/Components/ConfirmDeliveryOptions/ConfirmDeliveryOptions";
import OutOfStockError from "./Components/CartValidationErrors/OutOfStockError";
import { htmlDecode } from "../../functions/htmldecoder";
import history from "../../history";

import {
  addRecentItems,
  addRecentViewItemsAction
} from "../../redux/actions/recentlyViewedItemsActions.js";
// import Accessories from "./Components/Accessories/Accessories";
import AccessoryModal from "./Components/Accessories/components/AccessoryModal";
import { I18nContext } from "../../i18n/index";
import Breadcrumb from "./Components/Breadcrumb/Breadcrumb";
import Loading from "../AC-Loading/Loading";
import { unblockWindow } from "../../functions/Utilities";

const ProductPage = () => {
  const dispatch = useDispatch();
  const { translate, currency, priceConvert } = React.useContext(I18nContext);
  const reviewsContainerRef = useRef();

  const [titleState, setTitleState] = useState("");
  const [enquiryModalState, setEnquiryModalState] = useState(false);
  const [reviewsWithImages, setReviewsWithImages] = useState([]);

  const [supplierInfoState, setSupplierInfoState] = React.useState({});
  const [starFilter, setStarFilter] = useState([]);
  const [avgRating, setAvgRating] = useState({
    avgRating: 0,
    countOfEachStar: {},
    totalValue: 0
  });

  // used by vendorList component
  const [allSuppliersWithDistance, setAllSuppliersWithDistance] =
    React.useState([]);
  const productInitialState = useSelector(
    state => state.productReducer.product,
    shallowEqual
  );

  const displayQuestionsState = useSelector(
    state =>
      state.productReducer.personalizedQuestionsProgress.displayQuestions,
    shallowEqual
  );

  const progressState = useSelector(
    state => state.productReducer.personalizedQuestionsProgress.progress,
    shallowEqual
  );

  const ItemCodeState = useSelector(
    state => state.productReducer.itemDetail.code,
    shallowEqual
  );

  const productImageSwitchState = useSelector(
    state => state.productReducer.productImageSwitch,
    shallowEqual
  );

  const priceState = useSelector(
    state => state.productReducer.priceInventory,
    shallowEqual
  );

  const itemDetailState = useSelector(
    state => state.productReducer.itemDetail,
    shallowEqual
  );

  const itemDetailsTitleState = useSelector(
    state => state.productReducer.itemDetail.title,
    shallowEqual
  );

  const itemDetailIdState = useSelector(
    state => state.productReducer.itemDetail.itemid,
    shallowEqual
  );

  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  const productInitialStateFromFetch = useSelector(
    state => state.productReducer.productInitial,
    shallowEqual
  );

  const noProductState = useSelector(
    state => state.productReducer.noproduct,
    shallowEqual
  );
  const supplierInfoReducer = useSelector(
    state => state.productReducer.supplierInfo,
    shallowEqual
  );

  const recentViewItemsState = useSelector(
    state => state.recentlyViewedItemsReducer.recentlyViewedItemsList,
    shallowEqual
  );

  const biidReviewState = useSelector(
    state => state.productReducer.biidReview,
    shallowEqual
  );

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  React.useEffect(() => {
    if (displayQuestionsState)
      window.addEventListener("beforeunload", unblockWindow);

    const unblock = history.block((location, action) => {
      if (displayQuestionsState) {
        dispatch(confirmLeaveAction({ url: location.pathname }));
        return false;
      }
      return true;
    });

    return () => {
      window.removeEventListener("beforeunload", unblockWindow);
      unblock();
    };
  }, [displayQuestionsState]);

  React.useEffect(() => {
    if (
      supplierInfoReducer &&
      supplierInfoReducer[0] &&
      supplierInfoReducer[0].distributorOrder &&
      supplierInfoReducer[0].distributorOrder.length > 0
    ) {
      if (supplierInfoReducer[0].distributorOrder.length > 0) {
        setSupplierInfoState({
          name: supplierInfoReducer[0].distributorOrder[0].name,
          city: supplierInfoReducer[0].distributorOrder[0].city,
          rating: supplierInfoReducer[0].distributorOrder[0].rating,
          rating_count: supplierInfoReducer[0].distributorOrder[0].rating_count
        });
      }
    }
  }, [supplierInfoReducer]);

  /*  useEffect(() => {
    if (itemDetailState.code != "") {
      dispatch(fetchMainProductSkusAndSkuIds(itemDetailState.itemid));
      window.scrollTo(0, 0);
    }
  }, [itemDetailState.code]); */

  useEffect(() => {
    if (productInitialStateFromFetch.title != "") {
      /*  if (productInitialState != "") {
        setTitleState(itemDetailsTitleState);
      } else { */
      setTitleState(productInitialStateFromFetch.title);
      /*       } */
    }
  }, [productInitialStateFromFetch /* , itemDetailsTitleState */]);

  // recently viewed items related TEST
  useEffect(() => {
    if (
      itemDetailIdState &&
      priceState &&
      priceState.prices &&
      supplierInfoReducer &&
      supplierInfoReducer[0] &&
      supplierInfoReducer[0].distributorOrder
    ) {
      let firstSuppDistId = supplierInfoReducer[0].distributorOrder[0].distid;

      let itemDetailsTemp = { ...itemDetailState };
      let {
        itemId: id,
        title,
        currency_sign = "$",
        image,
        image3: itemLargeImage,
        url = window.location.pathname.replace("/preview", "")
      } = itemDetailsTemp;
      title = title.length > 40 ? title.substr(0, 40).concat(" ...") : title;
      let tempPrice = priceState.prices
        .find(inv => inv.distributorId == firstSuppDistId)
        .price_1.toFixed(2);
      tempPrice = tempPrice.split(".");
      let price = {
        type: "default",
        value: { decimal: tempPrice[1], integer: tempPrice[0] }
      };
      itemLargeImage = itemLargeImage.substring(
        itemLargeImage.indexOf("/store"),
        itemLargeImage.length
      );

      let previouslyAddedItem = recentViewItemsState.filter(
        item => item.id == id
      )[0];

      if (previouslyAddedItem) {
        if (previouslyAddedItem.url != url && previouslyAddedItem.id == id) {
          return;
        }
      }

      if (recentViewItemsState.length >= 10) {
        let tempRecentViewItemsState = recentViewItemsState.filter((v, i) => {
          if (i == 0) {
            return false;
          }
          return true;
        });

        dispatch(
          addRecentItems([
            ...tempRecentViewItemsState.filter(item => item.id != id),
            { id, title, currency_sign, image, itemLargeImage, price, url }
          ])
        );
      } else {
        dispatch(
          addRecentViewItemsAction(
            id,
            title,
            currency_sign,
            image,
            itemLargeImage,
            price,
            url,
            recentViewItemsState
          )
        );
      }
    }
  }, [supplierInfoReducer, itemDetailIdState, priceState]);

  const handleEnquiryModalOpenClicked = () => {
    setEnquiryModalState(true);
  };

  if (!noProductState) {
    return (
      <React.Fragment>
        <EnquiryModal
          isLoggedIn={loginNameState !== ""}
          enquiryModalState={enquiryModalState}
          setEnquiryModalState={setEnquiryModalState}
        />
        <AccessoryModal />
        <HelmetSeo title={titleState} desc={titleState} />
        <div id="bd" className="item">
          <Breadcrumb back={true} productPage={true} />
          <div className="col s12">
            <div style={{ marginTop: "0%" }}>
              <div className="item-main">
                <h1
                  id="js-item-title-267578"
                  className="regularTitle title itemTitle itemTitleMobile"
                  dangerouslySetInnerHTML={{
                    __html: htmlDecode(titleState)
                  }}
                ></h1>
                <span className="product-page-item-code">
                  Product#: {ItemCodeState}
                </span>
                <div id="mainGridDiv" className="item-top-row row">
                  <div
                    id="leftGrid"
                    style={{
                      marginBottom:
                        isMobileState && biidReviewState ? "22px" : ""
                    }}
                  >
                    <ImageCarousel
                      productImageSwitch={productImageSwitchState}
                    />
                  </div>
                  <div id="rightGrid" style={{ position: "relative" }}>
                    <div id="titleGrid">
                      <AddToCartBox
                        handleEnquiryModalOpenClicked={
                          handleEnquiryModalOpenClicked
                        }
                      />
                    </div>
                  </div>

                  <ConfirmDeliveryOptions />
                  <CartValidationErrors />
                  <OutOfStockError />
                  <AddedToCartModal />
                </div>
                <div className="specs-info-container">
                  <OtherInfoTab fullWidth={true} />
                  {/*  <div className="c-full">
                    <div className="c-left">
                      Questions ?<br />{" "}
                      <a
                        style={{
                          color: "#038203",
                          textDecoration: "underline"
                        }}
                        href="mailto:BankService@deluxe.ca"
                      >
                        Contact Us
                      </a>
                    </div>
                    <div className="c-right"></div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 
          <Reviews
            avgRating={avgRating}
            setAvgRating={setAvgRating}
            starFilter={starFilter}
            setStarFilter={setStarFilter}
            reviewsContainerRef={reviewsContainerRef}
            reviewsWithImages={reviewsWithImages}
            setReviewsWithImages={setReviewsWithImages}
          />
          <RecentlyViewedItems /> */}
        {/* <GoBackButton standalone={true} /> */}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <HelmetSeo title="No Product" desc="No Product found on this name!" />

        <div id="bd" className="item">
          <div className="col s12">
            <Loading />
          </div>
        </div>
      </React.Fragment>
    );
  }
};

export default ProductPage;
