import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import zoom_in from "../../../../assets/icons/zoom_in.svg";
import zoom_out from "../../../../assets/icons/zoom_out.svg";
import zoom_out_map from "../../../../assets/icons/zoom_out_map.svg";
import expand_less from "../../../../assets/icons/expand_less.svg";
import expand_more from "../../../../assets/icons/expand_more.svg";

import LazyLoad from "react-lazyload";
import "./Styles/ImageCarousel.css";
import {
  setPersonalizedQuestionsProgressAction,
  setProductImagecarouselInitialAction
} from "../../../../redux/actions/productAction";
import { maskChars, usePrevious } from "../../../../functions/Utilities";
import { htmlDecode } from "../../../../functions/htmldecoder";

import "./Styles/FontStyles.css";
import { LINK_DISTRIBUTION, VID } from "../../../../project-config";
import { Helmet } from "react-helmet";

let defaultX = 0;
let defaultScale = 1;

const areEqual = (prevProps, nextProps) => {
  return nextProps.productImageSwitch == false;
};

const ImageCarousel = React.memo(function ImageCarousel(props) {
  const dispatch = useDispatch();

  const transformRef = useRef(null);
  const previewRef = useRef(null);

  const [mainImage, setMainImage] = useState("");

  const [imageLocation, setimageLocation] = useState(null);

  const [mainImageUrl, setMainImageUrl] = useState(
    "https://dlpreviewdev.avetti.io/preview/store/20201005259/items/images/"
  );

  const [wholeImageUrl, setWholeImageUrl] = useState("");
  const [galleryImagesCount, setGalleryImagesCount] = useState([]);
  const [sysNumImages, setSysNumImages] = useState(0);

  const [logo, setLogo] = useState(null);

  const productImageSwitchState = props.productImageSwitch;

  const productPageImageCarouselInitialState = useSelector(
    state => state.productReducer.productPageImageCarouselInitial,
    shallowEqual
  );

  const productInitialState = useSelector(
    state => state.productReducer.productInitial,
    shallowEqual
  );

  const customerPropsState = useSelector(
    state => state.loginReducer.customerProps,
    shallowEqual
  );

  const [curCodeState, setCurCodeState] = useState({
    curCode: null,
    productType: null
  });

  useEffect(() => {
    if (
      curCodeState.curCode === "USD" &&
      curCodeState.productType === "cheque"
    ) {
      dispatch(
        setPersonalizedQuestionsProgressAction({
          curCodeUsdAndChequeProduct: true
        })
      );
    }
  }, [curCodeState]);

  console.info("curCodeState", curCodeState);

  useEffect(() => {
    if (productInitialState && productInitialState.hiddenProperties) {
      const productType = productInitialState.hiddenProperties.find(
        prop => Object.keys(prop)[0] === "Sys_Product_Type"
      );

      if (productType) {
        setCurCodeState(curCodeState => {
          return {
            ...curCodeState,
            productType: Object.values(productType)[0]
          };
        });
      }
    }
  }, [productInitialState]);

  useEffect(() => {
    if (customerPropsState && productInitialState) {
      const curCode = customerPropsState.find(prop => prop.key === "curCode");

      if (curCode) {
        setCurCodeState(curCodeState => {
          return { ...curCodeState, curCode: curCode.value };
        });
      }
    }
  }, [customerPropsState]);

  const productTypeState = useMemo(() => {
    if (productInitialState && productInitialState.hiddenProperties) {
      const productType = productInitialState.hiddenProperties.find(
        prop => Object.keys(prop)[0].toLocaleLowerCase() === "product_type"
      );

      if (productType) {
        return Object.values(productType)[0];
      }
    }
    return "";
  }, [productInitialState]);

  const productCode = useSelector(
    state => state.productReducer.itemDetail.code,
    shallowEqual
  );

  const loadingState = useSelector(
    state => state.productReducer.loading,
    shallowEqual
  );

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const hiddenPropertiesState = useSelector(
    state => state.productReducer.itemDetail.hiddenProperties,
    shallowEqual
  );

  const mainItemIdState = useSelector(
    state => state.productReducer.itemDetail.mainitemid,
    shallowEqual
  );

  const itemIdState = useSelector(
    state => state.productReducer.itemDetail.itemid,
    shallowEqual
  );

  const itemTitleState = useSelector(
    state => state.productReducer.itemDetail.title,
    shallowEqual
  );

  const previousItemIdState = usePrevious(
    Object.assign({}, { itemIdState, itemTitleState })
  );

  const propertiesState = useSelector(
    state => state.productReducer.itemDetail.properties,
    shallowEqual
  );

  const personalizedQuestionsState = useSelector(
    state => state.productReducer.personalizedQuestions,
    shallowEqual
  );

  const trancodeState = useMemo(() => {
    if (
      personalizedQuestionsState &&
      personalizedQuestionsState.dependedFields
    ) {
      const question = personalizedQuestionsState.dependedFields.find(q =>
        q.quesCode.toLowerCase().includes("trancode")
      );
      return question;
    } else return null;
  }, [personalizedQuestionsState]);

  const maskedInputIds = personalizedQuestionsState.maskedInputIds;

  const personalizedQuestionsProgressDisplayQuestionsState = useSelector(
    state =>
      state.productReducer.personalizedQuestionsProgress.displayQuestions,
    shallowEqual
  );

  const personalizedQuestionsProgressState = useSelector(
    state => state.productReducer.personalizedQuestionsProgress,
    shallowEqual
  );

  const curCodeUsdAndChequeProductState = useMemo(() => {
    return (
      personalizedQuestionsProgressState &&
      personalizedQuestionsProgressState.curCodeUsdAndChequeProduct
    );
  }, [personalizedQuestionsProgressState.curCodeUsdAndChequeProduct]);

  const companyInformationQuestionState = useSelector(
    state =>
      state.productReducer.personalizedQuestionsProgress.detailIDs
        .companyInformationQuestion,
    shallowEqual
  );

  const companyInformationQuestionDetailIdsState = useSelector(
    state =>
      state.productReducer.personalizedQuestionsProgress.detailIDs
        .companyInformationQuestionDetailIds,
    shallowEqual
  );

  const [previewCollapsed, setPreviewCollapsed] = useState(false);

  const canvasRef = useRef();

  const drawImageActualSize = (ctx, canvas, image) => {
    // Use the intrinsic size of image in CSS pixels for the canvas element
    canvas.width = image.naturalWidth;
    canvas.height = image.naturalHeight;
    // Will draw the image as 300x227, ignoring the custom size of 60x45
    // given in the constructor
    ctx.drawImage(image, 0, 0);

    // To use the custom size we'll have to specify the scale parameters
    // using the element's width and height properties - lets draw one
    // on top in the corner:
    ctx.drawImage(image, 0, 0, image.width, image.height);

    /// this mode fills in whatever, in the image
    ctx.globalCompositeOperation = image.src.includes("logos/upload/")
      ? "copy"
      : "source-in";

    if (image.src.includes("logos/upload/") === false) {
      let {
        selectedFont,
        "Imprint Colour": imprintColor,
        "2nd Ink Colour": secondInkColor
      } = personalizedQuestionsProgressState.font;
      /// color to change GIF to:

      let colors = mapColors([imprintColor]);
      ctx.fillStyle = colors[0];

      /// fill color into non-alpha pixels
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }
  };

  useEffect(() => {
    if (canvasRef.current && logo) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      if (logo.data) {
        canvas.width = logo.width;
        canvas.height = logo.height;
        try {
          let imgData = context.getImageData(0, 0, canvas.width, canvas.height);
          const pixels = imgData.data;
          logo.data.forEach((pixel, index) => {
            pixels[index] = pixel;
          });

          context.putImageData(imgData, 0, 0);
        } catch (error) {
          console.error("logo canvas error preview", error);
        }
      } else {
        // const image = document.getElementById("pq-logo");
        const image = new Image();
        image.onload = () => drawImageActualSize(context, canvas, image);
        image.src = logo;

        /*     context.globalCompositeOperation = "copy";
      context.drawImage(image, 0, 0); */

        /*  /// this mode fills in whatever, in the image
      context.globalCompositeOperation = "source-in";

      /// color to change GIF to:
      context.fillStyle = "#c00";

      /// fill color into non-alpha pixels
      context.fillRect(0, 0, canvas.width, canvas.height); */
      }
    }
  }, [canvasRef.current, logo, personalizedQuestionsProgressState.font]);

  const mapColors = colors => {
    return colors.map(color => {
      color = color.toLowerCase();
      if (color === "navy blue") color = "navy";
      else if (color === "burgundy") color = "#800020";
      else if (color === "hunter green") color = "#355e3b";
      else if (color === "reflex blue") color = "#171796";
      return color;
    });
  };

  useEffect(() => {
    if (
      previousItemIdState &&
      previousItemIdState.itemIdState != undefined &&
      previousItemIdState.itemIdState != itemIdState
    ) {
      if (previousItemIdState.itemTitleState == itemTitleState)
        dispatch(setProductImagecarouselInitialAction(false));
    }
  }, [previousItemIdState]);

  useEffect(() => {
    /*   if (
      personalizedQuestionsProgressDisplayQuestionsState === false &&
      curCodeUsdAndChequeProductState
    ) {
      setMainImage(`${productCode}_usd`);
    } else */ if (
      productImageSwitchState ||
      productPageImageCarouselInitialState
    ) {
      setMainImage(productCode);
    }
  }, [productCode /*  personalizedQuestionsProgressDisplayQuestionsState, curCodeUsdAndChequeProductState */]);

  useEffect(() => {
    if (
      (productImageSwitchState || productPageImageCarouselInitialState) &&
      personalizedQuestionsProgressDisplayQuestionsState === false
    ) {
      setMainImageUrl(`${LINK_DISTRIBUTION}/store/${VID}/items/largeimages/`);
    } else if (personalizedQuestionsProgressDisplayQuestionsState) {
      setMainImageUrl(`${LINK_DISTRIBUTION}/store/${VID}/items/backgrounds/`);
    }
  }, [itemIdState, personalizedQuestionsProgressDisplayQuestionsState]);

  useEffect(() => {
    if (
      mainImage &&
      mainImageUrl
      /*   (mainImage && productImageSwitchState) ||
      (productPageImageCarouselInitialState && propertiesState) */
    ) {
      setWholeImageUrl(`${mainImageUrl}${mainImage}.jpg`);
    }
  }, [mainImage, mainImageUrl]);

  useEffect(() => {
    if (
      (propertiesState && productImageSwitchState) ||
      (productPageImageCarouselInitialState && propertiesState)
    ) {
      let tempNumber = propertiesState.filter(prop => {
        if (prop.propname === "Sys_Gallery_Images") {
          return true;
        } else {
          return false;
        }
      });

      tempNumber = tempNumber && tempNumber[0] && tempNumber[0].propvalue;
      let tempArray = [];

      for (let i = 1; i < Number(tempNumber); i++) {
        tempArray.push(i);
      }

      setGalleryImagesCount(tempArray);
    }
  }, [propertiesState]);

  useEffect(() => {
    if (
      (hiddenPropertiesState && productImageSwitchState) ||
      (productPageImageCarouselInitialState && hiddenPropertiesState)
    ) {
      let prop = hiddenPropertiesState.find(prop => {
        return prop.propname == "Sys_Num_Images";
      });
      // if (parseInt(prop.propvalue) >= 1) {
      //   setSysNumImages(parseInt(prop.propvalue));
      // }
    }
  }, [hiddenPropertiesState]);

  const handleHelmetforStyleSheet = () => {
    if (hiddenPropertiesState) {
      const customCSSFileProp = hiddenPropertiesState.find(
        prop => prop.propname === "customCSSFile"
      );
      if (customCSSFileProp) {
        console.info("customCSSFileProp", customCSSFileProp);
        return (
          <Helmet
            link={[
              {
                rel: "stylesheet",
                href: `${LINK_DISTRIBUTION}/store/${VID}/assets/items/customizercss/${customCSSFileProp.propvalue}`
              }
            ]}
          >
            {/*    <noscript>{`
            <link rel="stylesheet" type="text/css" href="${LINK_DISTRIBUTION}/store/${VID}/assets/items/customizercss/${customCSSFileProp.propvalue}" />
            `}</noscript> */}
          </Helmet>
        );
      }
    } else return null;
  };

  const renderTheInputsOnTheImage = () => {
    if (
      personalizedQuestionsProgressState &&
      personalizedQuestionsProgressState.progress > 0 &&
      personalizedQuestionsProgressState.inputs &&
      Object.keys(personalizedQuestionsProgressState.inputs).length > 0 &&
      personalizedQuestionsState &&
      Object.keys(personalizedQuestionsState).length > 0
    ) {
      let textFieldsIdsAndCoords =
        personalizedQuestionsState &&
        personalizedQuestionsState.textFields.reduce((a, question) => {
          let isCompanyInformationQuestion = null;
          let companyInformationFieldsArray = [];
          if (companyInformationQuestionState) {
            isCompanyInformationQuestion =
              question.quesID === companyInformationQuestionState.quesID;
          }

          let fontStyleMICRDigits =
            question.quesType === "startNumber" &&
            !question.quesCode.toLowerCase().includes("nbringdetails");
          question.quesDetails.forEach((detail, index) => {
            if (detail.previewVal.toLowerCase().includes("nonMICRFont")) {
              fontStyleMICRDigits = false;
            }

            let leftSymbol = "",
              rightSymbol = "";
            let appendLeft = "";
            let ignoreRendering = false;

            if (detail.defWords === "comet_bankBranchCd") {
              ignoreRendering =
                !personalizedQuestionsProgressState.separateMICRLines.includes(
                  detail.detailID
                );
            } else if (detail.defWords === "comet_bankCd") {
              ignoreRendering =
                !personalizedQuestionsProgressState.separateMICRLines.includes(
                  detail.detailID
                );
            } else if (detail.defWords === "comet_bankAcctCd") {
              const separateLines =
                personalizedQuestionsProgressState.separateMICRLines.includes(
                  detail.detailID
                );
              // start number
              if (
                separateLines === false &&
                personalizedQuestionsProgressState.detailIDs &&
                personalizedQuestionsProgressState.detailIDs
                  .startNumberDetailId /* &&
                personalizedQuestionsProgressState.detailIDs
                  .startNumberPrefixDetailId */
              ) {
                const startNumber =
                  personalizedQuestionsProgressState.inputs[
                    personalizedQuestionsProgressState.detailIDs
                      .startNumberDetailId
                  ];
                /*    const startNumberPrefix =
                  personalizedQuestionsProgressState.inputs[
                    personalizedQuestionsProgressState.detailIDs
                      .startNumberPrefixDetailId
                  ]; */
                appendLeft += /*   startNumberPrefix || */ startNumber
                  ? `${fontStyleMICRDigits ? `&#9288;` : ""}${
                      /*   startNumberPrefix
                          ? startNumberPrefix + "-" + startNumber
                          : */ startNumber
                    }${fontStyleMICRDigits ? `&#9288;` : "-"}`
                  : "";
              }
              //transit number
              if (
                separateLines === false &&
                personalizedQuestionsProgressState.detailIDs &&
                personalizedQuestionsProgressState.detailIDs
                  .transitNumberDetailId
              ) {
                const transitNumber =
                  personalizedQuestionsProgressState.inputs[
                    personalizedQuestionsProgressState.detailIDs
                      .transitNumberDetailId
                  ];

                appendLeft += transitNumber
                  ? `${
                      fontStyleMICRDigits ? "&nbsp;&#9286;" : ""
                    }${transitNumber}${fontStyleMICRDigits ? "&nbsp;" : "-"}`
                  : "";
              }

              // instituion number
              if (
                separateLines === false &&
                personalizedQuestionsProgressState.detailIDs &&
                personalizedQuestionsProgressState.detailIDs
                  .institutionNumberDetailId
              ) {
                const instituionNuber =
                  personalizedQuestionsProgressState.inputs[
                    personalizedQuestionsProgressState.detailIDs
                      .institutionNumberDetailId
                  ];
                appendLeft += instituionNuber
                  ? `${fontStyleMICRDigits ? "&#9289;" : ""}${instituionNuber}${
                      fontStyleMICRDigits ? "&#9286;" : "&nbsp;&nbsp;&nbsp;"
                    }`
                  : "";
              }

              // account number

              let trancodeFirstDetailValue =
                trancodeState &&
                trancodeState.quesDetails &&
                trancodeState.quesDetails[0] &&
                trancodeState.quesDetails[0].detailLabelText;

              leftSymbol = fontStyleMICRDigits ? "&nbsp;" : "";
              rightSymbol = fontStyleMICRDigits
                ? `&#9288;${
                    trancodeFirstDetailValue && curCodeUsdAndChequeProductState
                      ? trancodeFirstDetailValue
                      : ""
                  }`
                : "";
            }

            let vertical = false;
            if (detail.previewVal.includes("vertical")) vertical = true;

            if (isCompanyInformationQuestion) {
              companyInformationFieldsArray.push({
                vertical,
                ignoreRendering,
                appendLeft,
                applyFontStyle: detail.defWords === "Comet_ML",
                fontStyleMICRDigits,
                id: detail.detailID,
                x: detail.previewX,
                y: detail.previewY,
                h: detail.previewH,
                w: detail.previewW,
                leftSymbol,
                rightSymbol,
                isCompanyInformationQuestion
              });

              // if company name question fields are present add them to the array that is rendered
              if (index === question.quesDetails.length - 1) {
                a.push(companyInformationFieldsArray);
              }
            } else {
              a.push({
                vertical,
                ignoreRendering,
                appendLeft,
                applyFontStyle: detail.defWords === "Comet_ML",
                fontStyleMICRDigits,
                id: detail.detailID,
                x: detail.previewX,
                y: detail.previewY,
                h: detail.previewH,
                w: detail.previewW,
                leftSymbol,
                rightSymbol
              });
            }
          });

          return a;
        }, []);

      let otherTextFieldsInsideRestOfTheFields =
        personalizedQuestionsState.restOfTheFields.filter(
          question => question.quesInput === "text"
        );

      let otherTextFields = otherTextFieldsInsideRestOfTheFields.reduce(
        (a, question) => {
          question.quesDetails.forEach(detail => {
            a.push({
              applyFontStyle: detail.defWords === "Comet_ML",
              id: detail.detailID,
              x: detail.previewX,
              y: detail.previewY,
              h: detail.previewH,
              w: detail.previewW
            });
          });

          return a;
        },
        []
      );

      let dependedTextFields = personalizedQuestionsState.dependedFields.reduce(
        (a, question) => {
          question.quesDetails.forEach(detail => {
            let fontStyleMICRDigits = false;
            let ignoreRendering = false;
            if (question.quesCode.toLowerCase().includes("trancode")) {
              fontStyleMICRDigits = true;
              ignoreRendering = true;
            }

            a.push({
              defaultText: detail.detailLabelText,
              ignoreRendering,
              fontStyleMICRDigits,
              id: detail.detailID,
              x: detail.previewX,
              y: detail.previewY,
              h: detail.previewH,
              w: detail.previewW
            });
          });

          return a;
        },
        []
      );

      if (otherTextFields) textFieldsIdsAndCoords.push(...otherTextFields);

      if (dependedTextFields)
        textFieldsIdsAndCoords.push(...dependedTextFields);

      let logoQuestion = personalizedQuestionsState.restOfTheFields.find(
        question => question.quesCode.toLowerCase().includes("logooptions")
      );

      let logoToRender = null;
      let tiffToRender = null;

      let logoMode = personalizedQuestionsProgressState.logo.logoMode;
      if (logoMode) {
        let quesDetailID =
          personalizedQuestionsProgressState.inputs[logoQuestion.quesID];

        let quesDetail = logoQuestion.quesDetails.find(
          detail => detail.detailID === quesDetailID
        );

        let { previewX: x, previewY: y, previewH: h, previewW: w } = quesDetail;

        if (logoMode === "pickClipart")
          logoToRender =
            personalizedQuestionsProgressState.logo.selectedClipart &&
            personalizedQuestionsProgressState.logo.selectedClipart.url;
        else if (logoMode === "uploadLogo") {
          if (personalizedQuestionsProgressState.logo.tiff) {
            tiffToRender = personalizedQuestionsProgressState.logo.tiff;
          } else
            logoToRender = personalizedQuestionsProgressState.logo.uploadedLogo;
        } else if (logoMode === "existingLogo") {
          logoToRender =
            personalizedQuestionsProgressState.logo &&
            personalizedQuestionsProgressState.logo.existingLogoOption &&
            personalizedQuestionsProgressState.logo.existingLogoOption
              .detailImg;

          if (logoToRender)
            logoToRender = `${LINK_DISTRIBUTION}/${logoToRender}`;
        }

        logoToRender = {
          alt: quesDetail.detailDesc,
          id: logoQuestion.quesID,
          logo: logoToRender,
          tiff: tiffToRender,
          x,
          y,
          h,
          w
        };
      }

      if (logoToRender) textFieldsIdsAndCoords.push(logoToRender);
      let {
        selectedFont,
        "Imprint Colour": imprintColor,
        "2nd Ink Colour": secondInkColor
      } = personalizedQuestionsProgressState.font;
      let colors = mapColors([imprintColor, secondInkColor]);

      return textFieldsIdsAndCoords.map(field => {
        if (Array.isArray(field) && field.length > 0) {
          if (field[0].isCompanyInformationQuestion) {
            const renderDetailField = detailField => {
              if (
                detailField &&
                detailField.ignoreRendering === false &&
                Object.keys(detailField).includes("logo") === false
              ) {
                const renderInputValue = detailField => {
                  return personalizedQuestionsProgressState.inputs[
                    detailField.id
                  ] &&
                    personalizedQuestionsProgressState.inputs[
                      detailField.id
                    ] !== "Not provided:  Leave blank"
                    ? `${detailField.appendLeft ? detailField.appendLeft : ""}${
                        detailField.leftSymbol
                      }${htmlDecode(
                        personalizedQuestionsProgressState.inputs[
                          detailField.id
                        ]
                      )}${detailField.rightSymbol}`
                    : "";
                };
                const inputValue = renderInputValue(detailField);
                return (
                  <div
                    key={detailField.id}
                    style={{
                      fontWeight: detailField.applyFontStyle
                        ? "bold"
                        : "normal",
                      textAlign: "center",
                      fontSize: `${detailField.h}px`,
                      display:
                        detailField.w === "0" ||
                        detailField.h === "0" ||
                        inputValue === ""
                          ? "none"
                          : "",
                      width: `${detailField.w}px`,
                      height: `${detailField.h}px`,
                      color: `${
                        detailField.applyFontStyle
                          ? colors[0]
                          : colors[1] || colors[0]
                      }`
                    }}
                    className={`pq-user-input-over-image initial-position${
                      detailField.fontStyleMICRDigits
                        ? " MICRDigits"
                        : selectedFont && detailField.applyFontStyle
                        ? " " + selectedFont
                        : ""
                    }${detailField.vertical ? " vertical" : ""}`}
                    data-id={detailField.id}
                    dangerouslySetInnerHTML={{
                      __html: inputValue
                    }}
                  ></div>
                );
              }
            };

            const firstDetail = field[0];

            return (
              <div
                key={companyInformationQuestionState.quesID}
                style={{
                  top: `${firstDetail.y}px`,
                  left: `${firstDetail.x}px`
                }}
                className={`pq-user-input-over-image`}
                data-id={companyInformationQuestionState.quesID}
              >
                {field.map(field => renderDetailField(field))}
              </div>
            );
          }
        }
        if ((field && field.logo) || field.tiff) {
          setLogo(field.tiff || field.logo);
          return (
            <div
              key={field.id}
              style={{
                display: field.w === "0" || field.h === "0" ? "none" : "",
                top: `${field.y}px`,
                left: `${field.x}px`,
                width: `${field.w}px`,
                height: `${field.h}px`
              }}
              className="pq-user-input-over-image pq-logo-wrapper"
              data-id={field.id}
            >
              {logoMode && (
                <canvas
                  className="imageCarousel-canvas"
                  ref={canvasRef}
                ></canvas>
              )}
              {/*  {logoMode === "uploadLogo" && field.logo && (
                <img id={"pq-logo"} src={field.logo} alt={field.alt} />
              )} */}
            </div>
          );
        } else if (
          field &&
          field.ignoreRendering !== true &&
          Object.keys(field).includes("logo") === false
        ) {
          const renderInputValue = field => {
            // don't render certain question details
            /*       if (
              [
                personalizedQuestionsProgressState.detailIDs
                  .startNumberPrefixDetailId
              ].includes(field.id)
            )
              return null; */
            if (maskedInputIds.includes(field.id)) {
              const maskedText = maskChars(
                personalizedQuestionsProgressState.inputs[field.id],
                4
              );

              if (maskedText) {
                const handleAccountNumberSymbolDependedOnLength =
                  maskedText => {
                    const length = maskedText.length;
                    if (
                      length >= 11 &&
                      productTypeState.toLowerCase() === "deposit slip"
                    ) {
                      return `${maskedText.substring(0, 4)}${
                        field.fontStyleMICRDigits ? `&#9289;` : "-"
                      }${maskedText.substring(4)}`;
                    } else return maskedText;
                  };
                return `${field.appendLeft ? field.appendLeft : ""}${
                  field.leftSymbol
                }${handleAccountNumberSymbolDependedOnLength(maskedText)}${
                  field.rightSymbol
                }`;
              } else if (field.appendLeft) {
                return `${field.appendLeft ? field.appendLeft : ""}`;
              }
              return "";
            }
            return personalizedQuestionsProgressState.inputs[field.id] &&
              personalizedQuestionsProgressState.inputs[field.id] !==
                "Not provided:  Leave blank"
              ? `${field.appendLeft ? field.appendLeft : ""}${
                  field.leftSymbol ? field.leftSymbol : ""
                }${htmlDecode(
                  personalizedQuestionsProgressState.inputs[field.id]
                )}${field.rightSymbol ? field.rightSymbol : ""}`
              : "";
          };
          return (
            <div
              key={field.id}
              style={{
                fontSize: `${field.h}px`,
                display: field.w === "0" || field.h === "0" ? "none" : "",
                top: `${field.y}px`,
                left: `${field.x}px`,
                width: `${field.w}px`,
                height: `${field.h}px`,
                color: `${
                  field.applyFontStyle ? colors[0] : colors[1] || colors[0]
                }`
              }}
              className={`pq-user-input-over-image${
                field.fontStyleMICRDigits
                  ? " MICRDigits"
                  : selectedFont && field.applyFontStyle
                  ? " " + selectedFont
                  : ""
              }${field.vertical ? " vertical" : ""}`}
              data-id={field.id}
              dangerouslySetInnerHTML={{ __html: renderInputValue(field) }}
            ></div>
          );
        } else return null;
      });
    }
    return null;
  };

  const resetZoom = () => {
    const div = transformRef.current.contentRef.current;
    let scale = 1;
    let heightScale = 1;
    const previewDiv = previewRef.current;

    scale = previewDiv.offsetWidth / div.offsetWidth;
    heightScale = previewDiv.offsetHeight / div.offsetHeight;

    scale = scale < heightScale ? scale : heightScale;

    const wDifference = div.offsetWidth * scale - previewDiv.offsetWidth;
    const hDifference = div.offsetHeight - previewDiv.offsetHeight;

    let negative = false;
    if (wDifference < 0) {
      negative = true;
    } else {
      defaultScale = scale;
    }
    console.info(
      "wDifference",
      hDifference,
      wDifference,
      negative,
      heightScale
    );
    const widthDifference = Math.abs(
      div.offsetWidth * scale - previewDiv.offsetWidth
    );

    defaultX = !negative ? (widthDifference / 2) * -1 : widthDifference / 2;

    setimageLocation({ defaultX, scale });

    console.info(
      "transformRef",
      div,
      previewDiv,
      widthDifference,
      div.offsetWidth,
      previewDiv.offsetWidth
    );
    setTimeout(() => {
      div.style.transform = `translate(${defaultX}px, 0px) scale(${scale})`;
    }, 50);
  };

  return (
    <div id="imageGrid">
      {handleHelmetforStyleSheet()}
      <div className="preview-wrapper">
        {isMobileState && (
          <div
            className="preview-control"
            onClick={() => {
              setPreviewCollapsed(!previewCollapsed);
            }}
          >
            View Preview
            {previewCollapsed ? (
              <img src={expand_more} alt="expand more"></img>
            ) : (
              <img src={expand_less} alt="expand less"></img>
            )}
          </div>
        )}
        <div
          ref={previewRef}
          style={{ display: previewCollapsed ? "none" : "" }}
          className="preview"
          id="item-img"
        >
          {wholeImageUrl && (
            <div
              className={`${isMobileState ? "mobile-" : ""}magnifier-container`}
            >
              <TransformWrapper
                positionX={imageLocation && imageLocation.defaultX}
                positionY={0}
                defaultPositionX={imageLocation && imageLocation.defaultX}
                /*   defaultPositionY={0}
                defaultScale={defaultScale} */
                scale={imageLocation && imageLocation.scale}
              >
                {({ zoomIn, zoomOut, ...rest }) => {
                  return (
                    <React.Fragment>
                      <div className="zoom-tools">
                        <button onClick={zoomIn}>
                          <img src={zoom_in} alt="zoom in" />
                        </button>
                        <button onClick={zoomOut}>
                          <img src={zoom_out} alt="zoom out" />
                        </button>
                        <button onClick={resetZoom}>
                          <img src={zoom_out_map} alt="reset zoom" />
                        </button>
                      </div>
                      <TransformComponent ref={transformRef}>
                        {renderTheInputsOnTheImage()}
                        <img
                          onLoad={() => {
                            resetZoom();
                          }}
                          src={wholeImageUrl}
                          alt={`${itemTitleState}`}
                        ></img>
                      </TransformComponent>
                    </React.Fragment>
                  );
                }}
              </TransformWrapper>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}, areEqual);

export default ImageCarousel;
