import {
  FETCH_BASKET_SUCCESS,
  FETCH_SUPPLIERS_BASKET_SUCCESS
} from "../types.js";
import { BASKET_LINK, BASKET_LINK_TEMP } from "../links.js";
import { call, put } from "redux-saga/effects";
import { store } from "../../index";

export const fetchBasketAction = products => ({
  type: FETCH_BASKET_SUCCESS,
  payload: products
});

export const fetchSupplierBasketSuccessAction = suppliers => ({
  type: FETCH_SUPPLIERS_BASKET_SUCCESS,
  payload: suppliers
});

/* export const fetchBasket = () => {
  console.log("fetchBasket");
  let language = store.getState().mainReducer.lang;
  return dispatch => {
    fetch(BASKET_LINK.replace("$LANGUAGE", language))
      .then(res => res.json())
      .then(json => {
        let products = json.__Result.products;
        console.log("Products", products);
        dispatch(fetchBasketAction(products, products.length));
      })
      .catch(error => {
        console.warn("Server respond failed", error);
      });
  };
}; */

const api = async params => {
  try {
    const res = await fetch(BASKET_LINK(params.language, params.vid));
    const json = await res.json();
    let basket = json.__Result;

    return basket;
  } catch (error) {
    console.warn("fetch basket_link failed", error);
  }
};

export function* fetchBasketSaga() {
  let language = store.getState().mainReducer.lang;
  let currency = store.getState().mainReducer.currency;
  let params = { language, currency };

  try {
    const result = yield call(api, params);
    /*    const result2 = yield call(api, { ...params, vid: "20200522738" });
    const result3 = yield call(api, { ...params, vid: "20200528762" });
    
    
     */

    yield put(fetchBasketAction(result));
    // yield put(fetchSupplierBasketSuccessAction([result2, result3]));
  } catch (error) {
    console.error("fetch basket saga error", error);
  }
}
