import { UPLOAD_LOGO } from "../../../../../redux/links";

const handleUploadLogo = (
  quesDetailID,
  logoBinary,
  dispatchCallBack,
  tiffImage
) => {
  const DataURIToBlob = dataURI => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  };

  let form = new FormData();

  const file = DataURIToBlob(logoBinary);

  const fileType =
    file &&
    file.type &&
    file.type.includes("image/") &&
    file.type.split("image/")[1];

  if (fileType)
    form.append("filetoupload", file, `userUploadedImage.${fileType}`);
  form.append("uploadbutton", "Upload");

  fetch(UPLOAD_LOGO(quesDetailID), {
    method: "POST",
    body: form,
    headers: {
      Accept: "*/*",
      credentials: "same-origin"
    },
    mimeType: "multipart/form-data",
    data: form
  })
    .then(res => res.json())
    .then(json => dispatchCallBack(json, tiffImage))
    .catch(err => console.error("ERROR: failed uploading the logo", err));
};

export default handleUploadLogo;
