import ParseErrorMessage from "../../functions/errorMessageParser.js";

import {
  FETCHING_MENU_SUCCESS,
  FETCHING_MENU_FAILURE,
  FETCHING_MENU_REQUEST,
  FETCHING_CATEGORY_REQUEST,
  FETCHING_BY_STYLE_MENU_SUCCESS,
  CATEGORY_UNMOUNT,
  CATEGORY_FETCH_FLAG_SET,
  SET_STORES_NAVCAT,
  featuredMenuActions
} from "../types.js";

const initialState = {
  navCats: "",
  isLoading: true,
  errorMessage: "",
  navCategory: "",
  byStyleNavCats: {},
  featuredCategory: null,
  categoryFetchFlag: true
};

const menuReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCHING_BY_STYLE_MENU_SUCCESS:
      return { ...state, byStyleNavCats: payload };
    case FETCHING_MENU_REQUEST:
      return { ...state, isLoading: true };
    case FETCHING_MENU_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: ParseErrorMessage(payload)
      };
    case FETCHING_MENU_SUCCESS:
      return {
        ...state,
        isLoading: false,
        navCats: payload
      };
    case FETCHING_CATEGORY_REQUEST:
      return {
        ...state,
        navCategory: payload
      };
    case featuredMenuActions.REQUEST_FEATURED_MENU:
      return {
        ...state,
        featuredCategory: "fetching"
      };

    case featuredMenuActions.SUCCESS_FEATURED_MENU:
      return {
        ...state,
        featuredCategory: payload
      };
    case featuredMenuActions.FAILURE_FEATURED_MENU:
      return {
        ...state,
        featuredCategory: "error"
      };

    case SET_STORES_NAVCAT:
      return {
        ...state,
        storesNavCat: payload
      };

    case CATEGORY_UNMOUNT:
      return {
        ...state,
        navCategory: ""
      };

    case CATEGORY_FETCH_FLAG_SET:
      return {
        ...state,
        categoryFetchFlag: payload
      };
    default:
      return state;
  }
};

export default menuReducer;
