import { VID } from "../../../../../project-config";
import { POST_PERSONALIZED_QUESTIONS } from "../../../../../redux/links";

const postPersonalizedQuestion = (
  productId = null,
  inputs,
  personalizedQuestionsProgressState,
  dispatchCallBack,
  updatePersonalizedInfo = false
) => {
  const {
    transitNumberValue,
    institutionNumberValue,
    depositSlipSingleAccountNumberDetailIdsToAppendAfterBranchAndInstitutionNumber
  } = handleMappingDepositSlipAccountNumberToTransitAndInstitutionNumbers(
    personalizedQuestionsProgressState,
    inputs
  );

  let options = {
    disablePageCount: "0",
    _targetaddItems: `basket.html?vid=${VID}`,
    mode: updatePersonalizedInfo ? "updatePersonalizedInfo" : "addItems"
  };

  let form = new FormData();
  form.append("disablePageCount", "0");
  form.append("_targetaddItems", `basket.html?vid=${VID}`);
  form.append(
    "mode",
    updatePersonalizedInfo ? "updatePersonalizedInfo" : "addItems"
  );

  let formBody = [];
  for (let key in options) {
    let encodedKey = encodeURIComponent(key);
    let encodedValue = encodeURIComponent(options[key]);
    formBody.push(encodedKey + "=" + encodedValue);
  }

  Object.keys(inputs).forEach(key => {
    const input = inputs[key];
    if (input !== null) {
      if (Array.isArray(input)) {
        input.forEach((v, i) => {
          let encodedKey = encodeURIComponent(
            `answer${i !== 0 ? i + 1 : ""}_${key}`
          );
          let encodedValue = encodeURIComponent(v);
          formBody.push(encodedKey + "=" + encodedValue);
          // form.append(`answer${i !== 0 ? i + 1 : ""}_${key}`, v);
        });
      } else {
        let encodedKey = encodeURIComponent(`answer_${key}`);

        const value = handleMappingInputs(
          key,
          input,
          depositSlipSingleAccountNumberDetailIdsToAppendAfterBranchAndInstitutionNumber,
          transitNumberValue,
          institutionNumberValue
        );
        let encodedValue = encodeURIComponent(value);
        formBody.push(encodedKey + "=" + encodedValue);

        /* form.append(
          `answer_${key}`,
          handleMappingInputs(
            key,
            input,
            depositSlipSingleAccountNumberDetailIdsToAppendAfterBranchAndInstitutionNumber,
            transitNumberValue,
            institutionNumberValue
          )
        ); */
      }
    }
  });
  form.append("basketPage", "Submit");

  formBody.push("basketPage=Submit");

  formBody = formBody.join("&");

  fetch(POST_PERSONALIZED_QUESTIONS(productId), {
    method: "POST",
    body: formBody || form,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
    },
    credentials: "same-origin"
  })
    .then(res => res.status)
    .then(status => dispatchCallBack(status))
    .catch(err =>
      console.error("ERROR: failed posting personalized questions", err)
    );
};

const handleMappingInputs = (
  key,
  input,
  depositSlipSingleAccountNumberDetailIdsToAppendAfterBranchAndInstitutionNumber,
  transitNumberValue,
  institutionNumberValue
) => {
  if (
    depositSlipSingleAccountNumberDetailIdsToAppendAfterBranchAndInstitutionNumber.includes(
      key
    )
  ) {
    return `${transitNumberValue}-${institutionNumberValue}   ${
      input.length >= 11
        ? `${input.substring(0, 4)}-${input.substring(4)}`
        : input
    }`;
  } else {
    return input;
  }
};

const handleMappingDepositSlipAccountNumberToTransitAndInstitutionNumbers = (
  personalizedQuestionsProgressState,
  inputs
) => {
  let transitNumberValue = "",
    institutionNumberValue = "",
    depositSlipSingleAccountNumberDetailIdsToAppendAfterBranchAndInstitutionNumber =
      [];
  if (
    personalizedQuestionsProgressState &&
    personalizedQuestionsProgressState.depositSlipSingleAccountNumberDetailIdsToAppendAfterBranchAndInstitutionNumber &&
    personalizedQuestionsProgressState
      .depositSlipSingleAccountNumberDetailIdsToAppendAfterBranchAndInstitutionNumber
      .length > 0 &&
    personalizedQuestionsProgressState.detailIDs &&
    personalizedQuestionsProgressState.detailIDs.transitNumberDetailId &&
    personalizedQuestionsProgressState.detailIDs.institutionNumberDetailId
  ) {
    transitNumberValue =
      inputs[
        personalizedQuestionsProgressState.detailIDs.transitNumberDetailId
      ];
    institutionNumberValue =
      inputs[
        personalizedQuestionsProgressState.detailIDs.institutionNumberDetailId
      ];

    depositSlipSingleAccountNumberDetailIdsToAppendAfterBranchAndInstitutionNumber =
      personalizedQuestionsProgressState.depositSlipSingleAccountNumberDetailIdsToAppendAfterBranchAndInstitutionNumber;
  }

  return {
    transitNumberValue,
    institutionNumberValue,
    depositSlipSingleAccountNumberDetailIdsToAppendAfterBranchAndInstitutionNumber
  };
};

export default postPersonalizedQuestion;
