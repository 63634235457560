const staticContents = [
  {
    url: "privacy-california",
    title: "California Consumer Privacy Act",
    description:
      "California Specific Addendum applies to California residents, to support the California Consumer Privacy Act."
  },
  {
    url: "terms-and-conditions",
    title: "Terms & Conditions",
    description:
      "Terms and Conditions for reordering personal checks online with Deluxe OrderPoint."
  },
  {
    url: "privacy-policy",
    title: "Deluxe Canada Privacy Policy",
    description:
      "California Specific Addendum applies to California residents, to support the California Consumer Privacy Act."
  },
  {
    url: "accessibility",
    title: "Accessibility",
    description: "Deluxe website accessibility policy"
  }
];

module.exports = staticContents;
