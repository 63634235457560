import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { htmlDecode } from "../../../../../functions/htmldecoder";
import { maskChars } from "../../../../../functions/Utilities";

import classes from "./TextInputField.module.css";

export default function TextInputField({
  logoMode,
  curCodeUsdAndChequeProduct,
  hidden,
  readOnly,
  validationFailed,
  validations,
  setValidations,
  state,
  savedInputsState,
  setState,
  field,
  label,
  placeHolder,
  lines,
  addNewLine,
  addNewLineText,
  multiLine,
  questionCode,
  maskedInputIds,
  lastQuestion,
  firstQuestion,
  progress,
  progressState
}) {
  const [emailProofChecked, setEmailProofChecked] = useState(false);

  const [softwareEmailProofId, setSoftwareEmailProofId] = useState(null);

  const [maskedValues, setMaskedValues] = useState({});

  const inputRef = useRef();

  useEffect(() => {
    if (progressState === progress && firstQuestion) {
      if (inputRef && inputRef.current) {
        setTimeout(() => {
          inputRef.current.focus();
        }, 50);
      }
    }
  }, [progressState]);

  useEffect(() => {
    let validations = {};

    let softwareEmailProofFirstDetailId = "";
    if (lines.length > 0) {
      let inputs = lines.reduce((a, c) => {
        if (c && c.validations) {
          if (validations.hasOwnProperty(field) === false) {
            validations[field] = {};
          }
          // set active if setActiveOnMount
          if (c.validations.setActiveOnMount) {
            c.validations.active = true;
          }

          validations[field][c.id] = c.validations;
        }

        console.info("savedInputs", savedInputsState);
        if (
          ["trancode", "TextOptions-SW", "TextOptions-SW2"].some(q =>
            questionCode.toLowerCase().includes(q.toLowerCase())
          ) === false
        )
          a[c.id] =
            savedInputsState[c.id] || c.customerInput || c.defaultValue || "";

        // if it's US Dollar account and US Funds questions then set the default value
        if (
          ["trancode", "TextOptions-SW", "TextOptions-SW2"].some(q =>
            questionCode.toLowerCase().includes(q.toLowerCase())
          ) &&
          curCodeUsdAndChequeProduct
        ) {
          a[c.id] = c.detailLabelText;
        }

        // set email proof checked to true if there is a previously entered value
        if (
          questionCode === "softwareEmailProof" &&
          (!!c.customerInput || !!savedInputsState[c.id])
        ) {
          softwareEmailProofFirstDetailId = c.id;
        }

        if (maskedInputIds.includes(c.id))
          setMaskedValues(maskedValues => {
            return { ...maskedValues, [c.id]: maskChars(a[c.id], 4) };
          });
        return a;
      }, {});
      setState(inputs);
    } else {
      setState({
        [field]: ""
      });
    }

    setValidations(validations);

    // set email proof checked to true if there is a previously entered value
    if (softwareEmailProofFirstDetailId) {
      setSoftwareEmailProofId(softwareEmailProofFirstDetailId);
    }

    return () => {
      setValidations({ [field]: null });
      setState({
        [field]: ""
      });
    };
  }, []);

  useEffect(() => {
    if (softwareEmailProofId) {
      handleEmailProofChanged(softwareEmailProofId);
    }
  }, [softwareEmailProofId]);

  const handleOnPaste = event => {
    event.stopPropagation();
    const { name } = event.target;

    let paste = (event.clipboardData || window.clipboardData).getData("text");

    if (maskedInputIds.includes(name)) {
      const selectionStart = event.target.selectionStart;
      const selectionEnd = event.target.selectionEnd;
      const stateValue = state[name];

      if (
        selectionStart === selectionEnd &&
        selectionEnd === stateValue.length
      ) {
        setMaskedValues(maskedValues => {
          return {
            ...maskedValues,
            [name]: maskChars(stateValue + paste, 4)
          };
        });
        setState({
          [name]: stateValue + paste
        });
      } /* if(selectionStart === selectionEnd)  */ else {
        setMaskedValues(maskedValues => {
          return {
            ...maskedValues,
            [name]: maskChars(
              stateValue.substring(0, selectionStart) +
                paste +
                stateValue.substr(selectionEnd),
              4
            )
          };
        });
        setState({
          [name]:
            stateValue.substring(0, selectionStart) +
            paste +
            stateValue.substr(selectionEnd)
        });
      }
    }

    event.preventDefault();
  };

  const handleOnChange = e => {
    const { value, name } = e.target;
    const lastCharValue = value[value.length - 1];
    if (maskedInputIds.includes(name)) {
      if (value.length > state[name].length) {
        setMaskedValues(maskedValues => {
          return {
            ...maskedValues,
            [name]: maskChars(state[name] + lastCharValue, 4)
          };
        });
        setState({
          [name]: state[name] + lastCharValue
        });
      } else {
        if (value.length === 1) {
          setMaskedValues(maskedValues => {
            return {
              ...maskedValues,
              [name]: maskChars(value, 4)
            };
          });
          setState({ [name]: value });
        } else {
          setMaskedValues(maskedValues => {
            return {
              ...maskedValues,
              [name]: maskChars(state[name].slice(0, value.length), 4)
            };
          });
          setState({ [name]: state[name].slice(0, value.length) });
        }
      }
    } else {
      setState({ [name]: value });
    }
  };

  const handleEmailProofChanged = detailId => {
    if (emailProofChecked) {
      setState({
        [detailId]: ""
      });
    }
    console.info("emailProofChecked--3", validations, validations[field]);

    const foundValidation = validations && validations[field];
    if (foundValidation) {
      console.info("emailProofChecked--", foundValidation);
      const foundValidationFirstDetailId =
        foundValidation && foundValidation[detailId];
      if (foundValidationFirstDetailId.active !== undefined) {
        let setActiveValidation = { ...foundValidation };

        setActiveValidation[detailId].active = !emailProofChecked;
        setValidations({ [field]: setActiveValidation });
      }
    }
    setEmailProofChecked(!emailProofChecked);
  };

  const fillValidationMessages = validation => {
    let validationMessages = [];

    if (validation.validationType === "length") {
      let { min, max } = validation.validation;
      if (min !== undefined && max !== undefined) {
        if (min === max) {
          validationMessages.push(`Must be ${min} characters`);
        } else {
          validationMessages.push(
            `Must be between ${min} and ${max} characters`
          );
        }
      } else if (min !== undefined) {
        validationMessages.push(`Must be at least ${min} in length`);
      } else if (max !== undefined) {
        validationMessages.push(`Must not be greater than ${max} in length`);
      }
    }
    if (validation.validationType === "digitsOnly") {
      validationMessages.push(`Can only be digits`);
    }

    if (validation.validationType === "type") {
      if (validation.validation === "email") {
        validationMessages.push("Not a valid email address");
      }

      if (validation.validation === "required") {
        if (
          typeof validation.message === "object" &&
          validation.for === "logoID"
        ) {
          validationMessages.push(validation.message[logoMode]);
        } else validationMessages.push(validation.message || "Required");
      }
    }

    return validationMessages;
  };

  const renderInputValue = (decodedValue, state) => {
    return state && state !== "Not provided:  Leave blank"
      ? decodedValue || state
      : "";
  };

  const renderInputs = () => {
    if (questionCode === "softwareEmailProof" && lines.length > 0) {
      const line = lines[0];
      let validationMessages = [];

      if (validationFailed && line.failedValidations) {
        line.failedValidations.forEach(validation => {
          validationMessages = fillValidationMessages(validation);
        });
      }

      let decodedValue = "";
      if (line.defaultValue && state[line.id]) {
        decodedValue = htmlDecode(state[line.id]);
      }
      return (
        <div className={classes.intputGroup}>
          <label className={classes.title}>{label}</label>
          <div className={classes.checkboxInputFieldWrapper}>
            <input
              id={questionCode}
              aria-label="Proof Request"
              checked={emailProofChecked}
              onChange={() => handleEmailProofChanged(line.id)}
              type="checkbox"
            ></input>
            <label htmlFor={questionCode}>{line.detailLabelText}</label>
          </div>
          <div className={classes.inputWrapper}>
            {emailProofChecked && (
              <input
                maxLength={
                  validations &&
                  validations[field] &&
                  validations[field][line.id] &&
                  validations[field][line.id].length &&
                  validations[field][line.id].length.max
                }
                autoComplete="off"
                className={`${
                  validationMessages.length > 0
                    ? classes.inputValidationFailed
                    : ""
                }`}
                readOnly={
                  (readOnly || line.defaultValue) && !line.ignoreReadOnly
                }
                value={
                  state[line.id] &&
                  state[line.id] !== "Not provided:  Leave blank"
                    ? decodedValue || state[line.id]
                    : ""
                }
                placeholder={placeHolder || "Please enter your email address"}
                onChange={handleOnChange}
                name={line.id}
              />
            )}
            {emailProofChecked &&
              validationMessages.map(msg => (
                <p className={classes.validationMessage} role="alert">
                  {msg}
                </p>
              ))}
          </div>
        </div>
      );
    }
    if (lines.length > 0) {
      return (
        <div className={classes.intputGroup}>
          <label className={classes.title}>{label}</label>

          {lines.map((line, index, arr) => {
            const lastQuestionDetail = index === arr.length - 1;
            let validationMessages = [];
            if (validationFailed && line.failedValidations) {
              line.failedValidations.forEach(validation => {
                validationMessages = fillValidationMessages(validation);
              });
            }
            let decodedValue = "";
            if (line.defaultValue && state[line.id]) {
              decodedValue = htmlDecode(state[line.id]);
            }
            return (
              <div id={line.id} key={line.id} className={classes.inputWrapper}>
                <label>{line.label}:</label>
                <input
                  ref={index === 0 && firstQuestion ? inputRef : null}
                  /* onKeyDown={e => {
                    const key = e.key;
                    if (key === "Tab" && lastQuestion && lastQuestionDetail) {
                      e.preventDefault();
                      const addToCartBtnContainer = document.querySelector(
                        ".add-to-cart-btn-container"
                      );
                      if (addToCartBtnContainer) {
                        console.log(
                          "addToCartBtnContainer",
                          addToCartBtnContainer
                        );
                        addToCartBtnContainer.focus();
                      }
                    }
                  }} */
                  maxLength={
                    validations &&
                    validations[field] &&
                    validations[field][line.id] &&
                    validations[field][line.id].length &&
                    validations[field][line.id].length.max
                  }
                  aria-label={`${line.label} ${validationMessages.join(" ")}`}
                  autoComplete="off"
                  className={`${
                    validationMessages.length > 0
                      ? classes.inputValidationFailed
                      : ""
                  }`}
                  readOnly={
                    (readOnly || line.defaultValue) && !line.ignoreReadOnly
                  }
                  value={
                    maskedValues[line.id] ||
                    renderInputValue(decodedValue, state[line.id])
                  }
                  placeholder={placeHolder}
                  onChange={handleOnChange}
                  onPaste={handleOnPaste}
                  name={line.id}
                />
                {validationMessages.map((msg, index) => (
                  <p
                    key={index}
                    className={classes.validationMessage}
                    role="alert"
                  >
                    {msg}
                  </p>
                ))}
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div className={classes.inputWrapper}>
          <label className={classes.title}>{label}</label>
          <input
            maxLength={
              validations &&
              validations[field] &&
              validations[field].length &&
              validations[field].length.max
            }
            autoComplete="off"
            readOnly={readOnly}
            value={
              state[field] && state[field] !== "Not provided:  Leave blank"
                ? state[field]
                : ""
            }
            placeholder={placeHolder}
            onChange={handleOnChange}
            onPaste={handleOnPaste}
            name={field}
          />
        </div>
      );
    }
  };

  return (
    <div className={classes.wrapper}>{hidden ? null : renderInputs()}</div>
  );
}
