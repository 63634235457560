import React, { useContext, useState, useEffect } from "react";
import { FormattedNumber } from "react-intl";
import { I18nContext } from "../../../i18n/index";
const PriceTag = props => {
  const { currency, priceConvert } = React.useContext(I18nContext);
  const [priceState, setPriceState] = useState(0);

  useEffect(() => {
    if (props && props.value && props.value.integer && props.value.decimal) {
      setPriceState(
        // Number(props.value.integer + "." + props.value.decimal) / priceConvert
        Number(props.value.integer.replace(",", "") + "." + props.value.decimal)
      );
    } else if (props.price) {
      setPriceState(props.price);
    }
  }, [props]);

  return (
    <FormattedNumber
      /*   currencyDisplay="narrowSymbol" */
      value={priceState.toFixed("2")}
      style="currency"
      currency={currency}
      children={value => <span>{value}</span>}
    />
  );
};

export default PriceTag;
