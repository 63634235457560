import React from "react";
//import loader from "../../assets/img/loading.gif";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';



export default function Loading({ className }) {
  const [completed, setCompleted] = React.useState(0);

  


  return (
    <div>
      <div style={{
        textAlign: "center"
      }}>
      <CircularProgress color="secondary" />
    </div>
    </div>
  );
}
