import React, { useRef, useEffect } from "react";

export default function TiffCanvas({ classes, tiffLogo }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (canvasRef && canvasRef.current && tiffLogo) {
      const canvas = canvasRef.current;
      canvas.width = tiffLogo.width;
      canvas.height = tiffLogo.height;
      let context = canvasRef.current.getContext("2d");
      try {
        let imgData = context.getImageData(0, 0, canvas.width, canvas.height);
        const pixels = imgData.data;
        tiffLogo.data.forEach((pixel, index) => {
          pixels[index] = pixel;
        });

        context.putImageData(imgData, 0, 0);
      } catch (error) {
        console.error("logo canvas error:", error);
      }
    }
  }, [canvasRef, tiffLogo]);

  return <canvas className={classes.canvas} ref={canvasRef}></canvas>;
}
