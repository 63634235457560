import React, { useState, useEffect, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  handleFacetFilterParams,
  checkButtonsAction,
  updateFacetButtonsForGroupedFacets,
  handleSetFacetBread
} from "../../redux/actions/facetActions";
import CatLoading from "../AC-Loading/CatLoading";
import ReviewStarMaker from "../../functions/ReviewStarMaker.jsx";
import { facetImageGenerator } from "../../functions/facetImageGenerator.js";

import remove from "../../assets/icons/remove.svg";
import add from "../../assets/icons/add.svg";

import { I18nContext } from "../../i18n/index";
import OtherFacets from "./OtherFacets";
import { FormattedNumber } from "react-intl";

export default function Facets({ renderedBy }) {
  const { translate, currency } = React.useContext(I18nContext);
  const dispatch = useDispatch();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1600 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 1600, min: 1360 },
      items: 1
    },
    mdDesktop: {
      breakpoint: { max: 1360, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 768, min: 520 },
      items: 1
    },
    xsMobile: {
      breakpoint: { max: 520, min: 0 },
      items: 1
    }
  };
  const facetsState = useSelector(
    state => state.facetReducer.facets,
    shallowEqual
  );
  const filterUrlState = useSelector(
    state => state.facetReducer.filterUrl,
    shallowEqual
  );
  const loadingState = useSelector(
    state => state.categoryReducer.loading,
    shallowEqual
  );

  const buttonsState = useSelector(
    state => state.facetReducer.buttons,
    shallowEqual
  );

  const itemsAreBeingFetchedState = useSelector(
    state => state.facetReducer.itemsAreBeingFetched,
    shallowEqual
  );

  const [activeFacetTitles, setActiveFacetTitles] = useState([
    "PRICE",
    "PIckup_Locations",
    "Sellers",
    "Collections",
    "Categories"
  ]);

  const [activeShowMoreFacets, setActiveShowMoreFaces] = useState([]);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleCloseBasket = () => {
    document.getElementById("hideRecommendDiv").style.display = "none";
  };

  const handleFacet = (value, index, name, buttonState, facetTitleAndText) => {
    let bread = [value, name, !buttonState, facetTitleAndText, index];
    dispatch(checkButtonsAction(index, name, buttonState));
    dispatch(handleSetFacetBread(bread));
    dispatch(
      handleFacetFilterParams(value, name, buttonState, filterUrlState, index)
    );
  };

  const handleViewMoreFacetClick = (title, viewMoreActive) => {
    viewMoreActive
      ? setActiveShowMoreFaces([
          ...activeShowMoreFacets.filter(t => t !== title)
        ])
      : setActiveShowMoreFaces([...activeShowMoreFacets, title]);
  };

  const handleFacetTitleClick = (e, title, facetActive) => {
    facetActive
      ? setActiveFacetTitles([...activeFacetTitles.filter(t => t !== title)])
      : setActiveFacetTitles([...activeFacetTitles, title]);
  };

  useEffect(() => {
    if (
      facetsState &&
      facetsState[2] &&
      facetsState[2].Other &&
      facetsState[2].Other[0]
    ) {
      let name = facetsState[2].Other[0].title;
      // Adding the first 'other' facet to active states so that it will be active at first
      if (!activeFacetTitles.includes(name)) {
        setActiveFacetTitles([...activeFacetTitles, name]);
      }

      if (renderedBy === "storeProducts") {
        setActiveFacetTitles([
          ...activeFacetTitles,
          ...facetsState[2].Other.reduce((a, c) => {
            a.push(c.name);
            return a;
          }, [])
        ]);
      }
    }
  }, [facetsState]);

  const renderViewMoreButton = (
    numberOfFacetsValuesWithCountMoreThanZero,
    isActive,
    viewMoreActive,
    title
  ) => {
    return numberOfFacetsValuesWithCountMoreThanZero > 5 ? (
      <button
        onClick={() => handleViewMoreFacetClick(title, viewMoreActive)}
        className="view-more-button"
        style={{ display: isActive ? `block` : `none` }}
      >
        {viewMoreActive
          ? translate(`facet.LessButton`)
          : translate(`facet.MoreButton`)}
        <em className="material-icons view-more-icon">
          {viewMoreActive ? `keyboard_arrow_up` : `keyboard_arrow_down`}
        </em>
      </button>
    ) : null;
  };

  const renderFacetToggleIcon = isActive => {
    return (
      // <em
      //   role="button"
      //   aria-expanded={isActive}
      //   className={`icon-${isActive ? "minus" : "plus"} facet-toggle-icon`}
      // ></em>

      <em
        role="button"
        aria-expanded={isActive}
        className="material-icons facet-toggle-icon"
      >
        {isActive ? (
          <img src={remove} alt="minus icon"></img>
        ) : (
          <img src={add} alt="addition icon"></img>
        )}
      </em>
    );
  };

  const priceFacetExists = () => {
    return facetsState.find(facet => Object.keys(facet).includes("Price"));
  };

  const otherFacetExists = () => {
    let otherFacets = [];
    let found = facetsState.find(facet => Object.keys(facet).includes("Other"));
    if (found) otherFacets = found.Other;

    return otherFacets;
  };

  const renderPickUpLocation = () => {
    if (facetsState[2] && facetsState[2].Other) {
      let pickUpLocationFacet = facetsState[2].Other.find(
        other => other.name === "PIckup_Locations"
      );

      let index = facetsState[2].Other.findIndex(
        other => other.name === "PIckup_Locations"
      );

      if (pickUpLocationFacet) {
        let other = pickUpLocationFacet;
        return (
          <OtherFacets
            {...{
              other,
              index,
              renderViewMoreButton,
              handleFacet,
              translate,
              handleFacetTitleClick,
              renderFacetToggleIcon,
              activeFacetTitles,
              activeShowMoreFacets,
              buttonsState,
              facetImageGenerator
            }}
          />
        );
      }
    } else {
      return null;
    }
  };
  if (!loadingState && buttonsState && buttonsState.Price) {
    let reviewFacets = facetsState.find(facet =>
      Object.keys(facet).includes("Reviews")
    );
    let numberOfReviewsFacetWithCountMoreThanZero =
      reviewFacets && reviewFacets.Reviews.filter(f => f.count > 0).length;

    let activeFacetTitlesIncludesPriceTitle = activeFacetTitles.some(
      t => t === "PRICE"
    );

    let activeFacetTitleIncludesReviewsTitle = activeFacetTitles.some(
      t => t === "REVIEW"
    );

    return (
      <aside>
        {itemsAreBeingFetchedState ? (
          <CatLoading className="facetsBeingFetched" />
        ) : null}

        <div
          className={
            showMoreOptions
              ? "col s12 m12 l12 facetsContent more-options"
              : "col s12 m12 l12 facetsContent"
          }
        >
          <div className="filt">Filter By:</div>
          <div className="facetTitleWrapper">
            {renderPickUpLocation()}

            {Object.keys(buttonsState.Price).length > 0 ? (
              <h2
                data-facettitle="PRICE"
                onClick={e =>
                  handleFacetTitleClick(
                    e,
                    "PRICE",
                    activeFacetTitlesIncludesPriceTitle
                  )
                }
                className={
                  Object.values(buttonsState.Price).some(k => k === false)
                    ? "facet-has-checked-option facetTitle"
                    : "facetTitle"
                }
              >
                {translate("Price").toUpperCase()}
                {renderFacetToggleIcon(activeFacetTitlesIncludesPriceTitle)}
              </h2>
            ) : null}
            <div
              aria-expanded={activeFacetTitlesIncludesPriceTitle}
              className={
                activeFacetTitlesIncludesPriceTitle
                  ? "facetFilterWrapper scroll-bar-thin-style active"
                  : "facetFilterWrapper scroll-bar-thin-style"
              }
            >
              {priceFacetExists() &&
                facetsState[0].Price.map(({ text, count, value }, index) => {
                  if (count > 0) {
                    const prices = text.match(/\d+(\.\d+)?/g) || [];

                    const pricesText = prices.reduce((a, c, i) => {
                      const formattedPrice = (
                        <FormattedNumber
                          value={c}
                          style="currency"
                          currency={currency}
                          children={value => <span>{value}</span>}
                        />
                      );

                      a.push(formattedPrice);
                      if (i < prices.length - 1) a.push(" - ");
                      return a;
                    }, []);

                    return (
                      <div key={value} className="facetFilterContent">
                        <ul
                          className={
                            activeFacetTitlesIncludesPriceTitle
                              ? "priceFacet open"
                              : "priceFacet"
                          }
                        >
                          <li
                            onKeyDown={e => {
                              const key = e.key;
                              if (key === "Enter") {
                                if (e.target) e.target.click();
                              }
                            }}
                            onClick={() =>
                              handleFacet(
                                value,
                                index,
                                "Price",
                                buttonsState.Price[index],
                                { ["Price"]: text }
                              )
                            }
                            key={value}
                            name={value}
                            style={
                              count > 0
                                ? { opacity: "1", cursor: "pointer" }
                                : { opacity: "0.6", cursor: "not-allowed" }
                            }
                            tabIndex="0"
                          >
                            <span
                              className={
                                buttonsState.Price[index]
                                  ? "checkmark-box"
                                  : "checkmark-box checked"
                              }
                            ></span>

                            <span className="productText">{pricesText}</span>
                            <span className="productCount">({count})</span>
                          </li>
                        </ul>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
          {numberOfReviewsFacetWithCountMoreThanZero > 0 ? (
            <div className="facetTitleWrapper">
              {Object.keys(buttonsState.Reviews).length > 0 ? (
                <h2
                  data-facettitle="REVIEW"
                  onClick={e =>
                    handleFacetTitleClick(
                      e,
                      "REVIEW",
                      activeFacetTitleIncludesReviewsTitle
                    )
                  }
                  className={
                    Object.values(buttonsState.Reviews).some(k => k === false)
                      ? "facet-has-checked-option facetTitle"
                      : "facetTitle"
                  }
                >
                  {translate("js.item.reviews").toUpperCase()}
                  {renderFacetToggleIcon(activeFacetTitleIncludesReviewsTitle)}
                </h2>
              ) : null}
              <div
                aria-expanded={activeFacetTitleIncludesReviewsTitle}
                className={
                  activeFacetTitleIncludesReviewsTitle
                    ? "facetFilterWrapper scroll-bar-thin-style active"
                    : "facetFilterWrapper scroll-bar-thin-style"
                }
              >
                <ul
                  className={
                    activeFacetTitleIncludesReviewsTitle
                      ? "priceFacet open"
                      : "priceFacet"
                  }
                >
                  {facetsState[1].Reviews.map(
                    ({ text, count, value }, index) => {
                      if (count > 0) {
                        return (
                          <div key={value} className="facetFilterContent">
                            <li
                              onKeyDown={e => {
                                const key = e.key;
                                if (key === "Enter") {
                                  if (e.target) e.target.click();
                                }
                              }}
                              onClick={() =>
                                handleFacet(
                                  value,
                                  index,
                                  "Reviews",
                                  buttonsState.Reviews[index],
                                  { ["Review"]: text }
                                )
                              }
                              key={value}
                              name={value}
                              style={
                                count > 0
                                  ? { opacity: "1", cursor: "pointer" }
                                  : { opacity: "0.6", cursor: "not-allowed" }
                              }
                              tabIndex="0"
                            >
                              <span
                                className={
                                  buttonsState.Reviews[index]
                                    ? "checkmark-box"
                                    : "checkmark-box checked"
                                }
                              ></span>

                              <ReviewStarMaker
                                className="productText"
                                text={text}
                                item={false}
                              />
                              <span className="productCount">({count})</span>
                            </li>
                          </div>
                        );
                      }
                    }
                  )}
                </ul>
              </div>
            </div>
          ) : null}

          {otherFacetExists().map((other, index) => {
            if (
              window.location.pathname.includes("stores/") &&
              other.name === "Sellers"
            ) {
              return; // Don't render sellers facet if we are in the store page
            }
            // PIckup_Locations will be rendered at top
            if (other.name === "PIckup_Locations") return;

            return (
              <OtherFacets
                {...{
                  other,
                  index,
                  renderViewMoreButton,
                  handleFacet,
                  translate,
                  handleFacetTitleClick,
                  renderFacetToggleIcon,
                  activeFacetTitles,
                  activeShowMoreFacets,
                  buttonsState,
                  facetImageGenerator
                }}
              />
            );
          })}
        </div>
      </aside>
    );
  } else {
    return (
      <div className="col s12 m12 l12 facetsContent">
        <div className="facetTitleWrapper" style={{ minHeight: "300px" }}></div>
      </div>
    );
  }
}
