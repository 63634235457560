import React, { useEffect } from "react";
import { LINK_DISTRIBUTION } from "../../../../../project-config";
import { handleSelectedLogoOption } from "../utils/logoSelectionHandler";
import classes from "./Checkbox.module.css";

export default function Checkbox({
  progressState,
  state,
  savedInputsState,
  setState,
  buttons,
  label,
  name,
  type,
  firstOptionIsCheckedByDefault,
  dispatch
}) {
  useEffect(() => {
    const previouslyChecked = buttons.find(
      b => b.answer === "true" || savedInputsState[b.id] === true
    );

    if (previouslyChecked) {
      setState({ [previouslyChecked.id]: true });
      return;
    }
    if (buttons && buttons[0] && firstOptionIsCheckedByDefault) {
      setState({ [buttons[0].id]: true });
    } else {
      setState({ [buttons[0].id]: false });
    }
  }, []);

  const handleOnChange = e => {
    const { value, name } = e.target;
    if (state && state[value]) {
      setState({ [value]: false });
    } else setState({ [value]: true });
  };

  return (
    <div className={classes.wrapper}>
      {label && <p>{label}:</p>}
      {buttons.map(btn => {
        return (
          <div
            key={btn.id}
            className={`${classes.checkboxWrapper}${
              type ? " " + classes[type] : ""
            }`}
          >
            <input
              checked={!!(state && state[btn.id])}
              className={classes.checkbox}
              onChange={handleOnChange}
              type="checkbox"
              id={btn.id}
              name={name}
              value={btn.id}
            />
            {type === "color" && (
              <React.Fragment>
                <img
                  src={LINK_DISTRIBUTION + "/" + btn.altLabel}
                  alt={btn && btn.label}
                ></img>
              </React.Fragment>
            )}
            {type === "image" && (
              <img
                src={LINK_DISTRIBUTION + "/" + btn.altLabel}
                alt={btn && btn.label}
              ></img>
            )}
            {type !== "image" && <label htmlFor={btn.id}>{btn.label}</label>}
          </div>
        );
      })}
    </div>
  );
}
