import React from "react";

export default function ReviewStarMaker(props) {
  const starMaker = text => {
    let number = Number(text);
    let test = [];
    let item = props.item;
    for (let i = 0; i < 5; i++) {
      if (number <= i) {
        test.push(
          <em
            key={i}
            className={
              item
                ? "product-review-start-font material-icons"
                : "material-icons"
            }
          >
            star_border
          </em>
        );
      } else {
        test.push(
          <em
            key={i}
            className={
              item
                ? "product-review-start-font material-icons"
                : "material-icons"
            }
          >
            star
          </em>
        );
      }
    }

    return test;
  };
  return <>{starMaker(props.text)}</>;
}
