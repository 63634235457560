import React from "react";
import ReactDOM from "react-dom";
import "./styles/Backdrop.css";

const Backdrop = props => {
  return ReactDOM.createPortal(
    <div
      className={props.noclass ? "" : "backdrop"}
      onClick={props.onClick}
      style={
        props.dropDownList == true
          ? { background: "rgba(0, 0, 0, 0)" }
          : { background: "rgba(0, 0, 0, 0.75)" }
      }
    ></div>,
    document.getElementById("backdrop-hook")
  );
};

export default Backdrop;
