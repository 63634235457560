import React, { useEffect, useRef } from "react";
import history from "../../history.js";
import InnerHTML from "dangerously-set-inner-html";
import Content from "./Content.js";
import staticContents from "./StaticRoutes.js";
import "./StaticContent.css";

import { Helmet } from "react-helmet";

function StaticContent(props) {
  const containerRef = useRef(null);
  let link = history.location.pathname.split("/");
  if (link[link.length - 1] == "") {
    link = link[link.length - 2];
  } else {
    link = link[link.length - 1];
  }

  console.log("link", link);

  let indexLocation;

  let content = staticContents.find((content, index) => {
    if (content.url == link) {
      indexLocation = index;
      return true;
    } else {
      return false;
    }
  });

  let staticContent = Content.find(c => c.url === content.url);

  useEffect(() => {
    if (containerRef && containerRef.current) {
      window.scrollTo(0, 0);
    }
  }, [containerRef]);

  return (
    <div ref={containerRef}>
      <Helmet>
        <title>{content.title}</title>
        <meta name="description" content={content.description} />
      </Helmet>
      <div id="bd">
        <h1 className="static-title">
          {content.title}
          <div className="divider-gray-gradient"></div>
        </h1>

        <InnerHTML html={staticContent.content} />
      </div>
    </div>
  );
}

export default StaticContent;
