import React from "react";
import classes from "./Button.module.css";

export default function Button({
  id,
  primary,
  title,
  onClick,
  children,
  hidden,
  btnRef,
  onBlur,
  style,
  className,
  onKeyPress
}) {
  let btnClass = classes.btn + " ";

  if (primary) btnClass += classes.primary;
  else btnClass += classes.default;

  if (className) {
    btnClass += ` ${className}`;
  }

  return (
    <button
      onKeyDown={onKeyPress}
      onBlur={onBlur}
      ref={btnRef}
      id={id}
      style={({ visibility: hidden ? "hidden" : null }, style)}
      onClick={onClick}
      title={title}
      className={btnClass}
    >
      {children}
    </button>
  );
}
