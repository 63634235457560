import React from "react";
import classes from "./ReverseNumberingExtended.module.css";

export default function reverseNumberingExtended({
  state,
  setState,
  specialInstructionsQuestion,
  buttons,
  name
}) {
  const handleOnChange = e => {
    setState({ [name]: e.target.value });
  };

  return (
    <div className={classes.wrapper}>
      {buttons.map(btn => {
        if (["yes", "no"].includes(btn.label.toLowerCase())) return null;
        return (
          <div key={btn.value} className={classes.radioBtnWrapper}>
            <input
              checked={state[name] === btn.id}
              className={classes.radioBtn}
              id={btn.id}
              type="radio"
              onChange={handleOnChange}
              name="RV#"
              value={btn.id}
            />
            <label htmlFor={btn.id}>{btn.label}</label>
          </div>
        );
      })}
    </div>
  );
}
