import { useEffect, useRef } from "react";

export const isArray = obj => {
  return Object.prototype.toString.call(obj) === "[object Array]";
};

export const isObject = obj => {
  return Object.prototype.toString.call(obj) === "[object Object]";
};

export const setHTMLElementFixedPosition = (enable = true) => {
  if (enable) {
    document.querySelector("html").setAttribute("aria-hidden", "true");
  } else {
    document.querySelector("html").setAttribute("aria-hidden", "false");
  }
};

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const runAfterSomeTime = (func, miliseconds) => {
  setTimeout(func, miliseconds);
};

export const getBase64 = (
  file,
  callbackSetImage,
  callbackSetLoading = null
) => {
  let reader = new FileReader();
  if (callbackSetLoading) callbackSetLoading(true);
  reader.readAsDataURL(file);
  reader.onload = () => {
    callbackSetImage(reader.result);
    if (callbackSetLoading) callbackSetLoading(false);
  };
  reader.onerror = function (error) {
    if (callbackSetLoading) callbackSetLoading(false);
    console.error("Error: ", error);
  };
};

export const getDistanceBetweenTwoCoords = (lat1, lon1, lat2, lon2) => {
  const toRad = Value => {
    return (Value * Math.PI) / 180;
  };
  const R = 6371; // km
  let dLat = toRad(lat2 - lat1);
  let dLon = toRad(lon2 - lon1);
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);

  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;
  return d;
};

export const maskChars = (text, offSet = 4) => {
  if (text && text.length > offSet) {
    const maskedValue = Array.from(text.substring(0, text.length - offSet))
      .map(x => (x = "X"))
      .join("");
    return `${maskedValue}${text.substring(text.length - offSet)}`;
  } else return text;
};

export const validateEmail = email => {
  return email.match(
    /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
  );
};

export const unblockWindow = event => {
  // Cancel the event as stated by the standard.
  event.preventDefault();
  // Chrome requires returnValue to be set.
  event.returnValue = "";
};

export const pad = (zeros, num) => {
  // zeros = "00000"
  var s = zeros + num;
  return s.substr(s.length - zeros.length);
};
