import React, { useContext, useEffect } from "react";
import { PREVIEW } from "./project-config.js";
import { Route, Switch, Router, Redirect } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Helmet } from "react-helmet";

//TEST DWTC ADDED21121231233
import history from "./history.js";

import { IntlProvider } from "react-intl";

import "./assets/font/partOfTheCustomizerFonts/stylesheet.css";
import "./assets/css/menu.css";
import "./assets/css/minified.css";
import "./assets/css/avetti.css";
import "./assets/css/mailchimp.css";
import Menu from "./header/Menu.jsx";

import "./assets/css/sl.css";
import "./assets/css/b2b2c.css";
import "./assets/css/dl.css";
import "./assets/css/fontello.css";
import "./assets/jss/demo-ar.scss";

import staticContents from "./components/AC-StaticPages/StaticRoutes.js";

import {
  fetchMenu,
  fetchByStyle,
  setStoresNavCatAction,
  fetchFeaturedMenuAction
} from "./redux/actions/menuActions.js";
import {
  changeLanguageFromFunction,
  changeCountry,
  updateCurrency
} from "./redux/actions/mainActions.js";
import {
  fetchLogin,
  loginUserDataInitial
} from "./redux/actions/loginActions.js";

import changeUrlwithLanguageChange from "./i18n/functions/changeUrlwithLanguageChange";
import {
  I18nContext,
  mapCountryList,
  getCountryCode,
  currencyList,
  currencyDefine
} from "./i18n/index";
import { setGeoLocationState } from "./redux/actions/geoLocationActions.js";

export default function App() {
  // Build5Test file
  //24
  const { langCode, dispatchContext, currency } = useContext(I18nContext);

  const siteDownForMaintenanceState = useSelector(
    state => state.mainReducer.siteDownForMaintenance,
    shallowEqual
  );

  const countryCode = useSelector(
    state => state.mainReducer.country,
    shallowEqual
  );

  const dispatch = useDispatch(); //this hook gives us dispatch method
  const entryState = useSelector(
    state => state.mainReducer.entryState,
    shallowEqual
  );
  const languageButtonState = useSelector(
    state => state.mainReducer.languageButton,
    shallowEqual
  );
  const languageState = useSelector(
    state => state.mainReducer.lang,
    shallowEqual
  );
  const currencyState = useSelector(
    state => state.mainReducer.currency,
    shallowEqual
  );

  const loginInfoState = useSelector(
    state => state.loginReducer.userInfo,
    shallowEqual
  );

  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );

  const storesState = useSelector(
    state => state.storeReducer.stores,
    shallowEqual
  );

  //Change the directurlaccess state to false and determine which language to load...
  if (entryState === true && !localStorage.getItem("language")) {
    let temp = window.location.pathname.split("/").filter(temp => {
      if (temp != "" && temp != "preview") {
        return true;
      } else {
        return false;
      }
    })[0];

    if (
      !mapCountryList.filter(trans => {
        if (trans === temp) {
          return true;
        } else {
          return false;
        }
      }).length > 0
        ? true
        : false
    ) {
      temp = "en";
    }
    dispatchContext({ type: "setLanguage", payload: temp });
  }
  useEffect(() => {
    let languageStorage = localStorage.getItem("language");
    if (!languageStorage) {
      localStorage.setItem("language", languageState);
      languageStorage = "en";
    }
    let countryStorage = localStorage.getItem("country");
    if (!countryStorage) {
      countryStorage = "global";
      localStorage.setItem("country", countryStorage);
    }
    let currencyStorage = localStorage.getItem("currency");

    if (!currencyStorage) {
      localStorage.setItem("currency", currencyState);
      currencyStorage = currencyDefine(countryStorage);
    }
    dispatchContext({ type: "setCurrencyOnly", payload: currencyStorage });

    dispatch(changeCountry(countryStorage));
    if (
      !window.location.pathname.includes(
        `/${localStorage.getItem("language")}/`
      )
    ) {
      changeUrlwithLanguageChange(langCode, entryState);
    }
    if (localStorage.getItem("language") !== langCode) {
      dispatchContext({
        type: "setOnlyLanguage",
        payload: localStorage.getItem("language")
      });
    }

    dispatch(changeLanguageFromFunction(languageStorage));
    if (
      !window.location.pathname.includes(
        `/${localStorage.getItem("language")}/`
      )
    ) {
      dispatchContext({
        type: "setOnlyLanguage",
        payload: localStorage.getItem("language")
      });
      dispatch(fetchMenu(window.location, languageStorage));
    } else {
      dispatch(fetchMenu(window.location, languageStorage));
    }
    dispatch(fetchFeaturedMenuAction());
    //dispatch(fetchByStyle());
    dispatch(fetchLogin());

    let userDataStorage = localStorage.getItem("userLocation");

    if (
      !userDataStorage ||
      userDataStorage.length < 10 ||
      JSON.parse(userDataStorage).countrycode !== "ae"
    ) {
      //dispatch(fetchUserData("", langCode));
    } else {
      dispatch(
        loginUserDataInitial(JSON.parse(localStorage.getItem("userLocation")))
      );
    }

    let geoLocationStorage = localStorage.getItem("userLocationGoogleAPI");
    if (!geoLocationStorage || geoLocationStorage.length < 10) {
      // TODO
    } else {
      let geoLocationObj = JSON.parse(geoLocationStorage);
      geoLocationObj.initial = true;
      dispatch(setGeoLocationState(geoLocationObj));
    }

    dispatch(updateCurrency(currencyStorage));
    //dispatch(fetchBasket());
    //dispatch(fetchCurrencyInfo());
  }, []);

  useEffect(() => {
    let languageStorage = localStorage.getItem("language");
    let count = 0;
    if (!entryState && languageButtonState && count == 0) {
      count++;

      dispatch(fetchMenu(window.location, languageStorage));
      changeUrlwithLanguageChange(langCode, entryState);
    }
    return () => {
      count = 0;
    };
  }, [langCode, countryCode]);

  /*  useEffect(() => {
    let languageStorage = localStorage.getItem("language");

    if (!entryState && languageButtonState) {
      dispatch(fetchMenu(window.location, languageStorage));
    }
    if (
      !entryState &&
      languageButtonState &&
      !window.location.pathname.includes(`${langCode}/`)
    ) {
      

      changeUrlwithLanguageChange(langCode, entryState);
    }
  }, [countryCode]);
 */

  if (siteDownForMaintenanceState) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: siteDownForMaintenanceState
        }}
      ></div>
    );
  } else if (siteDownForMaintenanceState === false) {
    return (
      <IntlProvider locale={langCode}>
        <Helmet titleTemplate="%s - Checks Store" defaultTitle="Checks Store">
          <meta name="description" content="Checks Store" />
        </Helmet>

        <Router history={history}>
          <div className={`${langCode === "ar" ? "rtl " : ""}root-child`}>
            <Switch>
              <Route
                exact
                path={"/"}
                render={routeProps => {
                  return (
                    <>
                      <Menu {...routeProps} component="home" />
                    </>
                  );
                }}
              />
              <Route
                exact
                path={"/preview"}
                render={routeProps => {
                  return (
                    <>
                      <Menu {...routeProps} component="home" />
                    </>
                  );
                }}
              />
              {staticContents.map(content => {
                return (
                  <Route
                    key={content.url}
                    path={`/${content.url}`}
                    render={routeProps => (
                      <Menu {...routeProps} component="staticContent" />
                    )}
                  />
                );
              })}
              {staticContents.map(content => {
                return (
                  <Route
                    key={content.url}
                    path={`/${langCode}/${content.url}`}
                    render={routeProps => (
                      <Menu {...routeProps} component="staticContent" />
                    )}
                  />
                );
              })}
              <Route
                exact
                path={"/" + langCode + "/"}
                render={routeProps => {
                  return (
                    <>
                      <Redirect to={"/" + langCode + "/"} />
                      <Menu {...routeProps} component="home" />
                    </>
                  );
                }}
              />
              <Route
                exact
                path={"/preview/" + langCode}
                render={routeProps => {
                  return (
                    <>
                      <Menu {...routeProps} component="home" />
                    </>
                  );
                }}
              />
              <Route
                exact
                path={PREVIEW + "/login/"}
                render={routeProps => (
                  <Menu {...routeProps} component="login" />
                )}
              />
              <Route
                exact
                path={PREVIEW + "/" + langCode + "/login/"}
                render={routeProps => (
                  <Menu {...routeProps} component="login" />
                )}
              />
              <Route
                exact
                path={PREVIEW + "/about/"}
                render={routeProps => (
                  <Menu {...routeProps} component="aboutcomponent" />
                )}
              />
              <Route
                exact
                path={PREVIEW + "/" + langCode + "/about/"}
                render={routeProps => (
                  <Menu {...routeProps} component="aboutcomponent" />
                )}
              />
              <Route
                path={PREVIEW + "/wishlist"}
                render={routeProps => {
                  return (
                    <>
                      <Menu {...routeProps} component="wishlist" />
                    </>
                  );
                }}
              />
              {/* STORE ROUTE */}
              <Route
                path={PREVIEW + "/shop/exhibitors"}
                render={routeProps => (
                  <Menu {...routeProps} component="stores" />
                )}
              />
              {mapCountryList.map((lang, index) => {
                return (
                  <Route
                    key={index}
                    path={PREVIEW + "/" + lang + "/shop/exhibitors"}
                    render={routeProps => (
                      <Menu {...routeProps} component="stores" />
                    )}
                  />
                );
              })}
              <Route
                path={PREVIEW + "/exhibitors/"}
                render={routeProps => (
                  <Menu {...routeProps} component="storeProducts" />
                )}
              />
              <Route
                path={PREVIEW + "/" + langCode + "/exhibitors/"}
                render={routeProps => (
                  <Menu {...routeProps} component="storeProducts" />
                )}
              />
              <Route
                path={PREVIEW + "/" + langCode + "/wishlist"}
                render={routeProps => {
                  return (
                    <>
                      <Menu {...routeProps} component="wishlist" />
                    </>
                  );
                }}
              />
              <Route
                exact
                path={PREVIEW + "/search/:key"}
                render={routeProps => (
                  <Menu {...routeProps} component="search" />
                )}
              />
              <Route
                exact
                path={PREVIEW + "/" + langCode + "/search/:key"}
                render={routeProps => (
                  <Menu {...routeProps} component="search" />
                )}
              />
              <Route
                path={PREVIEW + "/cookiepage"}
                render={routeProps => (
                  <Menu {...routeProps} component="cookiepage" />
                )}
              />
              <Route
                path={PREVIEW + "/" + langCode + "/cookiepage"}
                render={routeProps => (
                  <Menu {...routeProps} component="cookiepage" />
                )}
              />
              <Route
                path={PREVIEW + "/shop/products/:cat/:value"}
                render={routeProps => (
                  <Menu {...routeProps} component="category" />
                )}
              />
              {mapCountryList.map((lang, index) => {
                return (
                  <Route
                    key={index}
                    path={PREVIEW + "/" + lang + "/shop/products/:cat/:value"}
                    render={routeProps => (
                      <Menu {...routeProps} component="category" />
                    )}
                  />
                );
              })}
              <Route
                path={PREVIEW + "/shop/products"}
                render={routeProps => (
                  <Menu {...routeProps} component="products" />
                )}
              />
              {mapCountryList.map((lang, index) => {
                return (
                  <Route
                    key={index}
                    path={PREVIEW + "/" + lang + "/shop/products"}
                    render={routeProps => (
                      <Menu {...routeProps} component="products" />
                    )}
                  />
                );
              })}
              <Route
                path={PREVIEW + "/shop/"}
                render={routeProps => (
                  <Menu {...routeProps} component="category" />
                )}
              />
              {mapCountryList.map((lang, index) => {
                return (
                  <Route
                    key={index}
                    path={PREVIEW + "/" + lang + "/shop/"}
                    render={routeProps => (
                      <Menu {...routeProps} component="category" />
                    )}
                  />
                );
              })}
              <Route
                path={PREVIEW + "/demo/shop/"}
                render={routeProps => (
                  <Menu {...routeProps} component="category" />
                )}
              />
              <Route
                path={PREVIEW + "/" + langCode + "/demo/shop/"}
                render={routeProps => (
                  <Menu {...routeProps} component="category" />
                )}
              />
              <Route
                path={PREVIEW + "/compareitems"}
                render={routeProps => {
                  return (
                    <>
                      <Menu {...routeProps} component="compareitems" />
                    </>
                  );
                }}
              />
              <Route
                path={PREVIEW + "/" + langCode + "/compareitems"}
                render={routeProps => {
                  return (
                    <>
                      <Menu {...routeProps} component="compareitems" />
                    </>
                  );
                }}
              />
              <Route
                path={PREVIEW + "/messages"}
                render={routeProps => {
                  return (
                    <>
                      <Menu {...routeProps} component="messages" />
                    </>
                  );
                }}
              />
              <Route
                path={PREVIEW + "/" + langCode + "/messages"}
                render={routeProps => {
                  return (
                    <>
                      <Menu {...routeProps} component="messages" />
                    </>
                  );
                }}
              />
              {/*   <Route
              path={PREVIEW + "/" + langCode + "/playground"}
              render={routeProps => <Playground />}
            /> */}
              <Route
                path={PREVIEW + "/"}
                render={routeProps => {
                  let new404Logic =
                    history.location.pathname.split("/")[
                      history.location.pathname.split("/").length - 1
                    ] !== "" &&
                    history.location.pathname.split("/")[
                      history.location.pathname.split("/").length - 2
                    ] !== ""
                      ? true
                      : false;
                  return (
                    <>
                      {new404Logic ? (
                        <Menu
                          langCode={langCode}
                          {...routeProps}
                          component="product"
                        />
                      ) : (
                        <>
                          <Menu {...routeProps} component="home" />
                        </>
                      )}
                    </>
                  );
                }}
              />
              <Route
                render={routeProps => {
                  return (
                    <>
                      <Menu {...routeProps} component="home" />
                    </>
                  );
                }}
              />
              )
            </Switch>
          </div>
        </Router>
      </IntlProvider>
    );
  } else {
    return null;
  }
}
