import { SET_HANDLER } from "../types.js";

const initialState = {
  notifyModalOpen: false
};

const handlersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_HANDLER:
      return {
        ...state,
        [payload.name]: payload.value
      };
    default:
      return state;
  }
};

export default handlersReducer;
