import React, { useContext, useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import history from "../../../../history";
import { PREVIEW } from "../../../../project-config.js";
import { Link } from "react-router-dom";
import GoBackButton from "./GoBackButton";

import {
  fetchCategoryFromRender,
  changeCategoryName,
  backToCategory
} from "../../../../redux/actions/categoryActions";

import keyboard_arrow_right from "../../../../assets/icons/keyboard_arrow_right.svg";

import info from "../../../../assets/icons/info.svg";

import { I18nContext } from "../../../../i18n/index";

const Breadcrumb = ({ back, productPage }) => {
  const dispatch = useDispatch();
  const { langCode, translate } = useContext(I18nContext);
  const [breadcrumb, setBreadcrumb] = useState([]);

  const navCatsState = useSelector(
    state => state.menuReducer.navCats,
    shallowEqual
  );

  const categoryParentsNameState = useSelector(
    state => state.categoryReducer.parents,
    shallowEqual
  );

  const breadcrumbState = useSelector(
    state => state.mainReducer.breadcrumb,
    shallowEqual
  );

  useEffect(() => {
    if (breadcrumbState.length > 1) {
      setBreadcrumb(
        breadcrumbState.filter(bread => {
          if (bread.name !== "Home" && bread.name !== "Shop") {
            return true;
          } else {
            return false;
          }
        })
      );
    }
  }, [breadcrumbState]);

  const handleBreadCrumbClicked = (cid, cat, parents) => {
    let category = navCatsState;
    for (let parent of parents) {
      category = category.childs.filter(c => {
        if (c.cid === parent[1]) {
          /*parent's cid equals cid */
          return true;
        }
      })[0];
    }

    dispatch(changeCategoryName(cat, parents));
    dispatch(fetchCategoryFromRender(cid, cat, parents, "", category));
  };

  return (
    <nav>
      <div className="sub-nav-bread">
        <ul className="breadcrumb">
          <li className="breadcrumb-list-item">
            <Link
              className="breadcrumb-link"
              to={langCode === "en" ? `/` : `/${langCode}`}
              property="v:title"
              rel="v:url"
            >
              Catalogue
              <img src={keyboard_arrow_right} alt="arrow right"></img>
            </Link>
            {categoryParentsNameState.length > 0
              ? categoryParentsNameState.map((parent, index) => {
                  let anotherArray = [];
                  for (let i = 0; i <= index; i++) {
                    anotherArray.push(categoryParentsNameState[i]);
                  }

                  if (back) {
                    return (
                      <React.Fragment key={index}>
                        <span
                          onClick={() => {
                            dispatch(backToCategory());
                            history.goBack();
                          }}
                          style={{
                            cursor: "pointer"
                          }}
                          className={
                            index == categoryParentsNameState.length - 1
                              ? "final_look last_breadcrumb final-bc"
                              : "home_look"
                          }
                          dangerouslySetInnerHTML={{ __html: parent[0] }}
                        ></span>
                        {index == categoryParentsNameState.length - 1 ? (
                          ""
                        ) : (
                          <img
                            src={keyboard_arrow_right}
                            alt="arrow right"
                          ></img>
                        )}
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <Link
                        key={index}
                        className="breadcrumb-link"
                        to={
                          PREVIEW === ""
                            ? "/" + categoryParentsNameState[index][2]
                            : PREVIEW + "/" + categoryParentsNameState[index][2]
                        }
                        onClick={e =>
                          handleBreadCrumbClicked(
                            parent[1],
                            parent[0],
                            anotherArray
                          )
                        }
                      >
                        <span
                          className={
                            index == categoryParentsNameState.length - 1
                              ? "final_look last_breadcrumb final-bc"
                              : "home_look"
                          }
                          dangerouslySetInnerHTML={{ __html: parent[0] }}
                        ></span>
                        {index == categoryParentsNameState.length - 1
                          ? ""
                          : " > "}
                      </Link>
                    );
                  }
                })
              : null}
          </li>
        </ul>
        {productPage && (
          <div
            onKeyDown={e => {
              const key = e.key;
              if (key === "Enter") {
                e.target.click();
              }
            }}
            tabIndex="0"
            className="product-page-specs-btn"
            onClick={() => {
              const specsEl = document.getElementById("product-specs");
              if (specsEl) {
                specsEl.scrollIntoView();
                specsEl.focus();
              }
            }}
          >
            <img src={info} alt="info icon"></img> <span>Specifications</span>
          </div>
        )}
        {/* {back && <GoBackButton />} */}
      </div>
    </nav>
  );
};

export default Breadcrumb;
