import React, { useState, useEffect, useRef } from "react";
import { LINK_DISTRIBUTION, VID } from "../../../../../project-config";
import { setPersonalizedQuestionsProgressAction } from "../../../../../redux/actions/productAction";
import { GET_LOGO_CLIPARTS } from "../../../../../redux/links";

import classes from "./ExtendedLogoSection.module.css";
import LogoUpload from "./LogoUpload";
import Select from "./Select";

export default function ExtendedLogoSection({
  questions,
  dependedFields,
  state,
  setInputsState,
  logoMode,
  progressState,
  dispatch,
  validationErrors
}) {
  const [selectedLogoCategory, setSelectedLogoCategory] = useState({
    value: "all",
    text: "All"
  });

  const [checkPreviouslySelectedClipart, setcheckPreviouslySelectedClipart] =
    useState(false);

  const [filteredCliparts, setFilteredCliparts] = useState(null);

  const [clipArts, setClipArts] = useState({ clipArts: [], categories: [] });

  const clipartCategorySelectRef = useRef(null);

  useEffect(() => {
    if (clipartCategorySelectRef.current) {
      const selectElement = clipartCategorySelectRef.current;

      const secondOption = selectElement.children[1];

      if (secondOption) {
        const secondOptionValue = secondOption.value;

        setTimeout(() => {
          setSelectedLogoCategory({
            ...selectedLogoCategory,
            value: secondOptionValue,
            text: secondOptionValue
          });
        }, 175);

        setTimeout(() => {
          setSelectedLogoCategory({
            ...selectedLogoCategory,
            value: "all",
            text: "All"
          });
        }, 230);
      }
    }
  }, [clipartCategorySelectRef.current]);

  useEffect(() => {
    if (logoMode === "pickClipart") {
      fetch(GET_LOGO_CLIPARTS())
        .then(res => res.json())
        .then(json => {
          if (json && json.__Result) {
            let clipArts = json.__Result.reduce((a, c) => {
              if (c.includes("-")) {
                const [category, id] = c.split("-");
                /*   if (a.some(o => o.id === id) === false) */
                a.push({
                  id,
                  category,
                  url: `${LINK_DISTRIBUTION}/store/${VID}/assets/logos/clipart/${c}`
                });
              } else if (c.includes("_")) {
                const parts = c.split("_");
                if (parts.length > 2) {
                  const last = parts[parts.length - 1];
                  const id = parts[parts.length - 2];

                  const category = parts.slice(0, parts.length - 2).join(" ");

                  /*  if (a.some(o => o.id === id) === false) */
                  a.push({
                    id,
                    category,
                    url: `${LINK_DISTRIBUTION}/store/${VID}/assets/logos/clipart/${c}`
                  });
                }
              }

              return a;
            }, []);

            let categories = [
              ...new Set(
                clipArts.reduce((a, c) => {
                  a.push(c.category);
                  return a;
                }, [])
              )
            ];

            categories = categories.map(category => ({
              value: category,
              text: category
            }));
            if (categories.length > 0)
              categories.unshift({
                value: "all",
                text: "All"
              });

            setClipArts({ clipArts, categories });
            setcheckPreviouslySelectedClipart(true);
          }
        })
        .catch(err => {
          console.error("error fetching the cliparts", err);
          setClipArts({ clipArts: null, categories: [] });
        });
    }
  }, [logoMode]);

  useEffect(() => {
    if (selectedLogoCategory.value !== "all" && clipArts && clipArts.clipArts) {
      setFilteredCliparts(
        clipArts.clipArts.filter(
          art => art.category === selectedLogoCategory.value
        )
      );
    } else setFilteredCliparts(null);
  }, [selectedLogoCategory]);

  const handlePreviouslySelectedClipart = logoIdQuestion => {
    if (logoIdQuestion && checkPreviouslySelectedClipart) {
      setcheckPreviouslySelectedClipart(false);
      const previousAnswer =
        logoIdQuestion.quesDetails &&
        logoIdQuestion.quesDetails[0] &&
        logoIdQuestion.quesDetails[0].customerInput;

      if (previousAnswer) {
        const clipart = clipArts.clipArts.find(
          clipart => clipart.id === previousAnswer
        );
        dispatch(
          setPersonalizedQuestionsProgressAction({
            logo: { ...progressState.logo, selectedClipart: clipart }
          })
        );
      }
    }
  };

  const renderSectionContent = () => {
    if (
      logoMode === "pickClipart" &&
      (filteredCliparts || clipArts.clipArts.length > 0)
    ) {
      let fieldDependsOnLogoModeAndPickClipart = dependedFields.find(
        field =>
          field.dependedOn === "logoMode" &&
          field.logoModeRequiredState.includes("pickClipart")
      );

      let firstDetailIdOrQuesID =
        (fieldDependsOnLogoModeAndPickClipart &&
          fieldDependsOnLogoModeAndPickClipart.quesDetails &&
          fieldDependsOnLogoModeAndPickClipart.quesDetails[0] &&
          fieldDependsOnLogoModeAndPickClipart.quesDetails[0].detailID) ||
        (fieldDependsOnLogoModeAndPickClipart &&
          fieldDependsOnLogoModeAndPickClipart.quesID);

      handlePreviouslySelectedClipart(fieldDependsOnLogoModeAndPickClipart);

      const handleBoxClicked = (e, box) => {
        if (fieldDependsOnLogoModeAndPickClipart) {
          setInputsState({
            [firstDetailIdOrQuesID]: box.id
          });
        }

        dispatch(
          setPersonalizedQuestionsProgressAction({
            logo: { ...progressState.logo, selectedClipart: box }
          })
        );
      };
      return (
        <React.Fragment>
          <div className={classes.selectSection}>
            <Select
              refHook={clipartCategorySelectRef}
              setState={setSelectedLogoCategory}
              state={selectedLogoCategory}
              label="Logos"
              field="artCategory"
              options={clipArts.categories}
            />
            <div className={classes.selectedArtWrapper}>
              <label>Selected</label>
              <div
                style={
                  progressState.logo.selectedClipart
                    ? {
                        backgroundImage: `url(${progressState.logo.selectedClipart.url})`
                      }
                    : { background: "unset", backgroundColor: "#ddd" }
                }
                className={classes.selectedArtBox}
              ></div>
            </div>
          </div>
          <div className={classes.clipartsContainer}>
            <div className={`${classes.clipartsWrapper} scroll-bar-thin-style`}>
              {(filteredCliparts || clipArts.clipArts).map(box => (
                <div key={box.id} className={classes.clipartBoxWrapper}>
                  <div
                    onKeyDown={e => {
                      const key = e.key;
                      if (key === "Enter") {
                        if (e.target) e.target.click();
                      }
                    }}
                    aria-label={`Logo: ${box.id}`}
                    tabIndex="0"
                    style={{ backgroundImage: `url(${box.url})` }}
                    className={`focusAble ${classes.clipartBox}${
                      box.id ===
                      (progressState.logo.selectedClipart &&
                        progressState.logo.selectedClipart.id)
                        ? " " + classes.active
                        : ""
                    }`}
                    onClick={e => handleBoxClicked(e, box)}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      );
    } else if (logoMode === "uploadLogo") {
      const logoUploadQuestion = questions.restOfTheFields.find(
        q => q.quesInput === "logoUpload"
      );

      const firstDetail =
        logoUploadQuestion &&
        logoUploadQuestion.quesDetails &&
        logoUploadQuestion.quesDetails[0];

      if (firstDetail) {
        return (
          <LogoUpload
            setInputsState={setInputsState}
            logoInputField={firstDetail}
            dispatch={dispatch}
            progressState={progressState}
            validationErrors={validationErrors}
          />
        );
      }
      return null;
    } else return null;
  };

  return <div className={classes.wrapper}>{renderSectionContent()}</div>;
}
