import {
  GET_GEOLOCATION_COOKIE_REQUEST,
  GET_GEOLOCATION_COOKIE_SUCCESS,
  GET_GEOLOCATION_COOKIE_FAILURE,
  CHANGE_GEOLOCATION
} from "../types.js";

const initialState = {
  geoLocation: "",
  errorMessage: ""
};

const geoLocationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_GEOLOCATION_COOKIE_REQUEST:
      return state;
    case GET_GEOLOCATION_COOKIE_SUCCESS:
      return { ...state, geoLocation: payload };
    case GET_GEOLOCATION_COOKIE_FAILURE:
      return { ...state, errorMessage: payload };
    case CHANGE_GEOLOCATION:
      return { ...state, geoLocation: payload };
    default:
      return state;
  }
};

export default geoLocationReducer;
