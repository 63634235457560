import React, { useState, useEffect, useMemo } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import {
  setPersonalizedQuestionsProgressAction,
  setUserInputsPersonalizedQuestions,
  setChequeValidations,
  setPersonalizedQuestionsAction
} from "../../../../redux/actions/productAction";
import RadioButtonGroup from "./components/RadioButtonGroup";
import TextInputField from "./components/TextInputField";
import ReviewSection from "./components/ReviewSection";
import ExtendedLogoSection from "./components/ExtendedLogoSection";
import Dropdown from "./components/Dropdown";
import BundleItemsMessage from "./components/BundleItemsMessage";
import Checkbox from "./components/Checkbox";
import { SECTION_BUTTONS } from "./data/section-buttons";

import classes from "./ChequeCustomization.module.css";
import SectionButtons from "./SectionButtons";
import GrandTotalAndAddToCartButton from "../AddToCart/Components/GrandTotalAndAddToCartButton/GrandTotalAndAddToCartButton";

export default function ChequeCustomization({
  activeSection,
  setActiveSection,
  addToCartBtnRef,
  packagePrices,
  selectedPackage,
  inStock,
  distributorId,
  numberOfItems
}) {
  const dispatch = useDispatch();

  const [
    questionIdsWithFailedValidations,
    setQuestionIdsWithFailedValidations
  ] = useState([]);

  const [bundleModalClosed, setBundleModalClosed] = useState(false);
  const [defaultValues, setDefaultValues] = useState(null);

  const customerPropsState = useSelector(
    state => state.loginReducer.customerProps,
    shallowEqual
  );

  const hiddenPropertiesState = useSelector(
    state => state.productReducer.itemDetail.hiddenProperties,
    shallowEqual
  );

  const showDisclaimerState = useMemo(() => {
    if (hiddenPropertiesState) {
      const prop = hiddenPropertiesState.find(
        p => p.propname === "Product_Disclaimer"
      );
      if (prop) return prop.propvalue.toLowerCase() === "true";
    }
    return false;
  }, [hiddenPropertiesState]);

  const personalizedState = useSelector(
    state => state.productReducer.itemDetail.personalized,
    shallowEqual
  );

  const personalizedQuestionsValidationErrorsState = useSelector(
    state => state.productReducer.personalizedQuestionsValidationErrors,
    shallowEqual
  );

  const productInitialState = useSelector(
    state => state.productReducer.productInitial,
    shallowEqual
  );

  const itemId = productInitialState.id;

  const productInitialBundleState = useSelector(
    state => state.productReducer.productInitial.bundle,
    shallowEqual
  );

  const personalizedQuestionsValidations = useSelector(
    state => state.productReducer.personalizedQuestionsValidations,
    shallowEqual
  );
  const currentItemIsBundleItemState = useSelector(
    state =>
      state.productReducer.personalizedQuestionsProgress
        .currentItemIsBundleItem,
    shallowEqual
  );
  const bundleItemsState = useSelector(
    state => state.productReducer.personalizedQuestionsProgress.bundleItems,
    shallowEqual
  );

  const biidReviewState = useSelector(
    state => state.productReducer.biidReview,
    shallowEqual
  );

  const jumpToReviewTabOnCustomizerState = useSelector(
    state => state.productReducer.jumpToReviewTabOnCustomizer,
    shallowEqual
  );

  useEffect(() => {
    if (
      bundleItemsState &&
      bundleItemsState.length > 0 &&
      currentItemIsBundleItemState === false
    ) {
      dispatch(
        setPersonalizedQuestionsProgressAction({
          displayBundleItemsMessage: true
        })
      );
    }
  }, [personalizedState, bundleItemsState]);

  useEffect(() => {
    if (bundleItemsState) {
      const bundleItemsIds = bundleItemsState.reduce((a, c) => {
        a.push(c.id);

        return a;
      }, []);

      if (bundleItemsIds.includes(itemId)) {
        const index = bundleItemsIds.indexOf(itemId);

        dispatch(
          setPersonalizedQuestionsProgressAction({
            progress: 0,
            currentItemIsBundleItem: true,
            bundleItemsProgress: index
          })
        );
        dispatch(setPersonalizedQuestionsAction({}));
      }
    } else if (productInitialBundleState) {
      const initialBundleItemsIds = productInitialBundleState.reduce((a, c) => {
        a.push(c.id);

        return a;
      }, []);

      dispatch(
        setPersonalizedQuestionsProgressAction({
          currentItemIsBundleItem: initialBundleItemsIds.includes(itemId),
          bundleItemsProgress: 0,
          bundleItems: [...productInitialBundleState],
          bundleItemParentId: itemId
        })
      );
    }
  }, [productInitialBundleState, itemId]);

  const tryFocusingElementInside = func => {
    try {
      func();
    } catch (error) {
      console.error("Error focusing element inside", func);
    }
  };

  useEffect(() => {
    if (Object.keys(personalizedQuestionsValidationErrorsState).length > 0) {
      let ids = Object.keys(personalizedQuestionsValidationErrorsState);
      setQuestionIdsWithFailedValidations(ids);

      let firstQuestionDetailId = Object.keys(
        personalizedQuestionsValidationErrorsState[ids[0]]
      )[0];

      if (firstQuestionDetailId === "qID") {
        firstQuestionDetailId = ids[0];
      }

      const line = document.getElementById(firstQuestionDetailId);
      const container = document.getElementById("chequeCustomizationContainer");

      if (line && container) {
        const topPos = line.offsetTop;
        container.scrollTop = topPos;
        line.focus();

        [
          () => line.getElementsByTagName("input")[0].focus(),
          () => line.querySelectorAll('[tabindex="0"]')[0].focus()
        ].forEach(tryFocusingElementInside);

        /*   try {
          line.getElementsByTagName("input")[0].focus();
        } catch (error) {
          console.error("Error focusing element inside", line);
        } */
      }
    }
  }, [personalizedQuestionsValidationErrorsState]);

  useEffect(() => {
    if (customerPropsState && customerPropsState.length > 0) {
      setDefaultValues(
        customerPropsState.reduce((a, c) => {
          if (c.key.toLowerCase().includes("comet_")) {
            a[c.key] = c.value;
          }

          return a;
        }, {})
      );
    } else setDefaultValues({});
  }, [customerPropsState]);

  const state = useSelector(
    state => state.productReducer.personalizedQuestionsProgress.inputs,
    shallowEqual
  );

  const savedInputsState = useSelector(
    state => state.productReducer.personalizedQuestionsProgress.savedInputs,
    shallowEqual
  );
  const setState = payload => {
    dispatch(setUserInputsPersonalizedQuestions(payload));
  };

  const setValidationsState = payload => {
    dispatch(setChequeValidations(payload));
  };

  const personalizedQuestionsState = useSelector(
    state => state.productReducer.personalizedQuestions,
    shallowEqual
  );

  const specialInstructionsQuestion =
    personalizedQuestionsState &&
    personalizedQuestionsState.textFields &&
    personalizedQuestionsState.textFields.find(
      q => q.quesCode === "splInstrDetails-Inst"
    );

  const personalizedQuestionsProgressState = useSelector(
    state => state.productReducer.personalizedQuestionsProgress,
    shallowEqual
  );

  const setProgressState = payload => {
    dispatch(setPersonalizedQuestionsProgressAction(payload));
  };

  useEffect(() => {
    if (
      personalizedQuestionsState &&
      Object.keys(personalizedQuestionsState).length > 0
    ) {
      dispatch(
        setPersonalizedQuestionsProgressAction({
          confirmLeave: true,
          displayQuestions: true,
          progress: jumpToReviewTabOnCustomizerState ? 3 : 1
        })
      );
    } else {
      dispatch(
        setPersonalizedQuestionsProgressAction({
          confirmLeave: false,
          displayQuestions: false
        })
      );
    }
  }, [personalizedQuestionsState]);

  const renderDependedInputSection = question => {
    if (personalizedQuestionsState.dependedFields.length > 0) {
      if (question.dependingFieldCode) {
        let foundQuestion = personalizedQuestionsState.dependedFields.find(
          q => q.quesCode === question.dependingFieldCode
        );

        if (foundQuestion) {
          if (foundQuestion.dependedOn === "logoMode") {
            if (
              foundQuestion.logoModeRequiredState.includes(
                personalizedQuestionsProgressState.logo.logoMode
              )
            ) {
              const readOnly =
                personalizedQuestionsProgressState.logo.logoMode ===
                "pickClipart";
              return renderTheInput(foundQuestion, readOnly);
            }
          }
        }

        return null;
      }
    }
    return null;
  };

  const renderExtendedInputSection = question => {
    if (question.quesDesc !== "Logo Options") return;

    let logoMode = personalizedQuestionsProgressState.logo.logoMode;
    if (!logoMode) return null;
    else {
      return (
        <ExtendedLogoSection
          questions={personalizedQuestionsState}
          dependedFields={personalizedQuestionsState.dependedFields}
          state={state}
          setInputsState={setState}
          dispatch={dispatch}
          logoMode={logoMode}
          progressState={personalizedQuestionsProgressState}
          validationErrors={personalizedQuestionsValidationErrorsState}
        />
      );
    }
  };

  const renderTheInput = (question, readOnly, options) => {
    const hidden = options && options.hidden;
    if (question.quesInput === "radio") {
      let type = null;
      let buttons = question.quesDetails;
      if (question.quesType.toLowerCase().includes("color")) {
        type = "color";
        buttons = buttons.map(btn => {
          btn.label = btn.previewVal;
          btn.altLabel = btn.detailImg;
          btn.id = btn.id = btn.detailID;
          return btn;
        });
      } else if (question.quesType === "typeStyle") {
        type = "image";
        buttons = buttons.map(btn => {
          btn.label = btn.previewVal;
          btn.altLabel = btn.detailImg;
          btn.id = btn.id = btn.detailID;
          return btn;
        });

        return (
          <Dropdown
            progress={question.progress}
            firstQuestion={question.firstQuestion}
            fontSelect={true}
            state={state}
            savedInputsState={savedInputsState}
            setState={setState}
            type={type}
            name={question.quesID}
            label={question.quesDesc}
            options={buttons}
            firstOptionIsCheckedByDefault={true}
            dispatch={dispatch}
            progressState={personalizedQuestionsProgressState}
            setProgressState={setProgressState}
          />
        );
      } else {
        buttons = buttons.map(btn => {
          btn.label = btn.detailDesc;
          btn.id = btn.id = btn.detailID;
          return btn;
        });
      }

      const failedValidations =
        personalizedQuestionsValidationErrorsState[question.quesID];

      if (
        ["splInstrDetails-software", "splInstrDetailsSoftware"].includes(
          question.quesCode
        ) ||
        question.quesCode.match(/software-\w*/gi) ||
        question.quesCode.match(/software.* question/gi)
      ) {
        buttons = buttons.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        });

        return (
          <Dropdown
            progress={question.progress}
            firstQuestion={question.firstQuestion}
            state={state}
            savedInputsState={savedInputsState}
            setState={setState}
            type={type}
            name={question.quesID}
            label={question.quesDesc}
            options={buttons}
            firstOptionIsCheckedByDefault={false}
            dispatch={dispatch}
            progressState={personalizedQuestionsProgressState}
            setProgressState={setProgressState}
            required={true}
            selectLabel="Choose Software"
            setValidations={setValidationsState}
            failedValidations={failedValidations}
          />
        );
      } else {
        const needsCheckboxActivation = ["textColor2"].includes(
          question.quesCode
        );

        const InkColorQuestion = ["Imprint Colour", "2nd Ink Colour"].includes(
          question.quesDesc
        );

        return (
          <RadioButtonGroup
            progress={question.progress}
            firstQuestion={question.firstQuestion}
            state={state}
            savedInputsState={savedInputsState}
            setState={setState}
            type={type}
            name={question.quesID}
            label={question.quesDesc}
            buttons={buttons}
            firstOptionIsCheckedByDefault={true}
            dispatch={dispatch}
            progressState={personalizedQuestionsProgressState}
            dependedFields={personalizedQuestionsState.dependedFields}
            InkColorQuestion={InkColorQuestion}
            needsCheckboxActivation={needsCheckboxActivation}
            setValidations={setValidationsState}
            failedValidations={failedValidations}
            questionCode={question.quesCode}
            specialInstructionsQuestion={specialInstructionsQuestion}
          />
        );
      }
    } else if (question.quesInput === "text") {
      let validationFailed = questionIdsWithFailedValidations.includes(
        question.quesID
      );
      let lines = question.quesDetails.map(line => {
        line.id = line.detailID;
        line.label = line.detailDesc;

        line.failedValidations =
          personalizedQuestionsValidationErrorsState[question.quesID] &&
          personalizedQuestionsValidationErrorsState[question.quesID][
            line.detailID
          ];
        line.defaultValue =
          line && line.answer !== undefined
            ? line.answer
            : defaultValues && defaultValues[line.defWords];
        return line;
      });

      return (
        <TextInputField
          progressState={personalizedQuestionsProgressState.progress}
          progress={question.progress}
          firstQuestion={question.firstQuestion}
          logoMode={personalizedQuestionsProgressState.logo.logoMode}
          curCodeUsdAndChequeProduct={
            personalizedQuestionsProgressState.curCodeUsdAndChequeProduct
          }
          hidden={hidden}
          readOnly={readOnly}
          validationFailed={validationFailed}
          validations={personalizedQuestionsValidations}
          setValidations={setValidationsState}
          state={state}
          savedInputsState={savedInputsState}
          setState={setState}
          field={question.quesID}
          lines={lines}
          label={question.quesDesc}
          questionCode={question.quesCode}
          maskedInputIds={personalizedQuestionsState.maskedInputIds}
          lastQuestion={question.lastQuestion}
        />
      );
    } else if (question.quesInput === "checkbox") {
      let type = null;
      let buttons = question.quesDetails;
      if (question.quesType.toLowerCase().includes("color")) {
        type = "color";
        buttons = buttons.map(btn => {
          btn.label = btn.previewVal;
          btn.altLabel = btn.detailImg;
          btn.id = btn.id = btn.detailID;
          return btn;
        });
      } else {
        buttons = buttons.map(btn => {
          btn.label = btn.detailDesc;
          btn.id = btn.id = btn.detailID;
          return btn;
        });
      }

      const firstOptionIsCheckedByDefault = !question.quesCode
        .toLowerCase()
        .includes("textoptions-sl");
      return (
        <Checkbox
          progress={question.progress}
          firstQuestion={question.firstQuestion}
          state={state}
          savedInputsState={savedInputsState}
          setState={setState}
          type={type}
          name={question.quesID}
          label={question.quesDesc}
          buttons={buttons}
          firstOptionIsCheckedByDefault={firstOptionIsCheckedByDefault}
          dispatch={dispatch}
          progressState={personalizedQuestionsProgressState}
        />
      );
    } else return null;
  };

  const renderTheSection = name => {
    const renderTheQuestion = (question, options) => {
      return (
        <div key={question.quesCode} className={classes.questionWrapper}>
          {renderTheInput(question, false, options)}
          {renderDependedInputSection(question)}
          {renderExtendedInputSection(question)}
        </div>
      );
    };

    if (name === "checkInformationSection") {
      if (
        personalizedQuestionsState &&
        personalizedQuestionsState.textFields &&
        personalizedQuestionsState.textFields.length > 0
      ) {
        return (
          <div className={classes.questionsWrapper}>
            {personalizedQuestionsState.textFields.map(
              (question, index, arr) => {
                question.firstQuestion = index === 0;
                question.progress = 1;
                question.lastQuestion = index === arr.length - 1;
                return renderTheQuestion(question);
              }
            )}
            {personalizedQuestionsState.dependedFields.map(question => {
              if (
                ["trancode", "TextOptions-SW", "TextOptions-SW2"].some(q =>
                  question.quesCode.toLowerCase().includes(q.toLowerCase())
                )
              ) {
                return renderTheQuestion(question, { hidden: true });
              }
            })}
          </div>
        );
      }
    } else if (name === "checkDesignSection") {
      if (
        personalizedQuestionsState &&
        personalizedQuestionsState.restOfTheFields &&
        personalizedQuestionsState.restOfTheFields.length > 0
      ) {
        return (
          <div className={classes.questionsWrapper}>
            {personalizedQuestionsState.restOfTheFields.map(
              (question, index) => {
                question.progress = 2;
                question.firstQuestion = index === 0;
                return (
                  question.renderDefault !== false &&
                  renderTheQuestion(question)
                );
              }
            )}
          </div>
        );
      }
    } else if (name === "checkReviewSection") {
      let keys = Object.keys(personalizedQuestionsState);
      let allTheQuestions = [];
      keys.forEach(key => {
        if (key === "dependedFields") {
          allTheQuestions = [
            ...allTheQuestions,
            ...personalizedQuestionsState[key].filter(q =>
              q.quesCode.toLowerCase().includes("logoid")
            )
          ];
        } else if (key !== "maskedInputIds")
          allTheQuestions = [
            ...allTheQuestions,
            ...personalizedQuestionsState[key]
          ];
      });
      return (
        <ReviewSection
          dispatch={dispatch}
          questions={allTheQuestions}
          inputs={state}
          progressState={personalizedQuestionsProgressState}
          maskedInputIds={personalizedQuestionsState.maskedInputIds}
        />
      );
    } else return null;
  };

  const renderTheDisclaimer = () => {
    if (activeSection.progress > 0 && showDisclaimerState)
      return (
        <p className={classes.disclaimer}>
          The image of the cheque shown is intended to provide a visual
          representation of how the imprint on all cheques in your order will
          appear. The colours or design may vary slightly from those displayed
          on this screen
        </p>
      );
    else return null;
  };

  const renderTheSections = () => {
    return (
      <div className={classes.sectionsWrapper}>
        {SECTION_BUTTONS.map(section => (
          <div
            key={section.name}
            className={`${classes.sectionWrapper}${
              section.progress === activeSection.progress
                ? " " + classes.active
                : ""
            }`}
            id={section.name}
          >
            {renderTheSection(section.name)}
          </div>
        ))}
      </div>
    );
  };

  if (
    personalizedQuestionsProgressState.displayBundleItemsMessage === true &&
    personalizedQuestionsProgressState.displayQuestions === false &&
    bundleModalClosed === false
  ) {
    const itemTitle = productInitialState.title;

    return (
      <BundleItemsMessage
        itemId={itemId}
        bundleItemParentId={
          personalizedQuestionsProgressState.bundleItemParentId
        }
        onClosed={() => setBundleModalClosed(true)}
        dispatch={dispatch}
        bundleItems={bundleItemsState}
        displayBundleItemsMessage={
          personalizedQuestionsProgressState.displayBundleItemsMessage
        }
        itemTitle={itemTitle}
      />
    );
  } else if (personalizedQuestionsProgressState.displayQuestions)
    return (
      <React.Fragment>
        {/*      <BundleItemsMessage
          dispatch={dispatch}
          bundleItems={bundleItemsState}
          displayBundleItemsMessage={
            personalizedQuestionsProgressState.displayBundleItemsMessage
          }
        /> */}
        <div
          className={`${classes.outerContainer}${
            showDisclaimerState ? " " + classes.checkProduct : ""
          }`}
        >
          <SectionButtons {...{ activeSection, setActiveSection }} />
          {renderTheDisclaimer()}
          <div id="chequeCustomizationContainer" className={classes.container}>
            <div className={classes.wrapper}>
              <div className={classes.sectionsAndHeaderWrapper}>
                {/*  {renderSectionHeader()} */}
                {renderTheSections()}
              </div>
            </div>
          </div>
          <GrandTotalAndAddToCartButton
            {...{
              addToCartBtnRef,
              packagePrices,
              selectedPackage,
              inStock,
              distributorId,
              numberOfItems,
              renderedBy: "chequeCustomization"
            }}
          />
        </div>
      </React.Fragment>
    );
  else return null;
}
