import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { LINK_DISTRIBUTION, PREVIEW } from "../../../../project-config";
import MoreShare from "./components/MoreShare.jsx";
import htmldecoder from "../../../../functions/htmldecoder";
import { runAfterSomeTime } from "../../../../functions/Utilities.js";
import { toggleCompare } from "../../../../header/Compare.jsx";
import {
  deleteCompareItem,
  toggleCompareAction,
  fetchComparedItemDetails,
  deleteComparedItemsDetails
} from "../../../../redux/actions/compareActions";

import history from "../../../../history.js";
import { FormattedNumber } from "react-intl";
import { I18nContext, langCodeList } from "../../../../i18n/index";
import "./Styles/ItemCard.css";
import PriceTag from "../../../../shared/components/PriceTag/PriceTag";
import { unMountProductPageAction } from "../../../../redux/actions/productAction";

const MORE_SHARE_TOGGLE_TIMEOUT = 100;

const ItemCard = props => {
  const { recently = false } = props;
  const dispatch = useDispatch();
  const { langCode, currency, translate, priceConvert } =
    React.useContext(I18nContext);

  const [productOnSale, setProductOnSale] = useState(
    false /* Math.round(Math.random()) */
  );

  const [moreActive, setMoreActive] = useState(false);

  const {
    id,
    code,
    title,
    desc,
    currency_sign,
    image,
    itemLargeImage,
    price,
    prices,
    url,
    properties,
    itemSellerName
  } = props.itemCard;

  const URL = useMemo(() => {
    let tempUrl = url;
    langCodeList.map(lang => {
      if (tempUrl.substring(0, 5).includes(`${lang}/`)) {
        tempUrl = tempUrl.replace(`${lang}`, "");
      }
    });

    if (tempUrl.includes("/product/")) {
      tempUrl = tempUrl.replace("/product/", "");
    } else {
      tempUrl = tempUrl.replace("product/", "");
    }

    const shouldIncludeSlash = () => {
      if (tempUrl.charAt(0) == "/") {
        return "";
      } else {
        return "/";
      }
    };
    tempUrl =
      langCode !== "en"
        ? PREVIEW + "/" + langCode + shouldIncludeSlash() + tempUrl
        : PREVIEW + shouldIncludeSlash(tempUrl) + tempUrl;

    return tempUrl;
  }, [langCode, url]);

  const [productPromotion, setProductPromotion] = useState(
    properties && properties.PromoText
  );

  const renderPrice = () => {
    let price = { quantity: null, price: null };
    if (prices.length > 0) {
      if (prices.length > 1) {
        const skuPrices = prices.filter(p => p.itemcode.includes("-"));

        const sortedSkuprices = skuPrices.sort((a, b) => {
          const aNum = parseInt(a.itemcode.split("-")[1]);
          const bNum = parseInt(b.itemcode.split("-")[1]);
          return aNum - bNum;
        });

        const firstSkuPrice = sortedSkuprices[0];

        if (firstSkuPrice) {
          price = firstSkuPrice.packagePrices[0];
        }
      } else if (prices.length === 1) {
        price = prices[0].packagePrices[0];
      }
    }
    if (price === undefined) {
      console.info("price", prices);
      price = { quantity: 1, price: prices[0].price_1 };
    }

    return price;
  };

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const [favouriteState, setFavouriteState] = useState("favorite_border");
  const [compareIconState, setCompareIconState] = useState("");

  const compareListState = useSelector(
    state => state.compareListReducer.compareList,
    shallowEqual
  );

  let imageUrl = `${LINK_DISTRIBUTION}/${itemLargeImage}`;

  // if (imageUrl.includes("sls3.avetti.ca")) {
  //   imageUrl = imageUrl;
  // }

  let isItemWishlisted = props.wishListState.some(w => w.id == id);
  let isItemCompared =
    compareListState && compareListState.some(i => i.id == id);
  const handleToggleWishlistIcon = (e, id) => {
    e.stopPropagation();
    isItemWishlisted
      ? setFavouriteState("favourite_border")
      : setFavouriteState("favourite");
    props.toggleWish(
      e,
      id,
      code,
      title,
      desc,
      currency_sign,
      image,
      price,
      url
    );
    runAfterSomeTime(() => setMoreActive(false), MORE_SHARE_TOGGLE_TIMEOUT);
  };

  const handleToggleCompareListIcon = (event, itemId) => {
    event.stopPropagation();
    let compareid = String(itemId);
    isItemCompared && compareIconState === ""
      ? setCompareIconState("-outlined")
      : setCompareIconState("");

    // if item's compare checkbox is not checked
    if (!isItemCompared) {
      dispatch(fetchComparedItemDetails(compareid));
    } else {
      dispatch(deleteComparedItemsDetails(compareid));
    }

    toggleCompare(
      //event,
      compareid,
      title,
      currency_sign,
      image,
      price,
      url,
      compareListState,
      isItemCompared,
      dispatch,
      deleteCompareItem,
      toggleCompareAction,
      translate
    );

    runAfterSomeTime(() => setMoreActive(false), MORE_SHARE_TOGGLE_TIMEOUT);
  };

  const handleClickOnProductCard = () => {
    dispatch(unMountProductPageAction());

    history.push(URL);
  };

  const renderPromotion = () => {
    if (productPromotion)
      return (
        <div className="item-card-promotion-container">
          <span className="item-card-promotion--promo">{productPromotion}</span>{" "}
        </div>
      );
    else return null;
  };

  const renderSale = () => {
    if (productOnSale)
      return (
        <div className="item-card-sale-container">
          <span className="item-card-sale--sale">50% OFF</span>{" "}
        </div>
      );
    else return null;
  };

  return (
    <Link
      className={`home-item--wrapper${isMobileState ? ` mobile` : ``}${
        moreActive ? ` more-active` : ``
      }`}
      to={URL}
      onClick={event => {
        event.preventDefault();
        window.scrollTo(0, 0);
        handleClickOnProductCard(URL);
      }}
    >
      <div
        className={`home-item${isMobileState ? ` mobile` : ``}${
          moreActive ? ` more-active` : ``
        }${productPromotion ? ` promo-item` : ``}`}
        style={{ cursor: "pointer" }}
      >
        <div className={`item-card-image${isMobileState ? ` mobile` : ``}`}>
          <figure className="item-card-figure">
            <span
              title={title}
              className="figure"
              style={{ backgroundImage: `url(${imageUrl})` }}
            ></span>
          </figure>
          {renderPromotion()}
          {renderSale()}
        </div>

        <div className={`item-card-regular${isMobileState ? ` mobile` : ``}`}>
          <div
            className="product-title"
            dangerouslySetInnerHTML={{
              __html: htmldecoder(title)
            }}
          ></div>
          <div className="item-code-wrapper">
            <div className="emptyCode">Code #</div>
            <div
              className="product-code"
              dangerouslySetInnerHTML={{
                __html: htmldecoder(code)
              }}
            ></div>
          </div>
          <div className={`product-price${productOnSale ? " price-deal" : ""}`}>
            <div className="product-price--main">
              <div className="product-price--main--left">
                Starting at:&nbsp;
              </div>

              <div className="product-price--main--right">
                <PriceTag price={renderPrice().price} />
              </div>
            </div>

            {productOnSale ? (
              <div className="product-price--deal">
                <PriceTag
                  value={{
                    integer:
                      price.type != "empty"
                        ? Math.round(price.value.integer / 2)
                        : 0,
                    decimal: price.type != "empty" ? price.value.decimal : 0
                  }}
                />
              </div>
            ) : null}
          </div>
          <button
            aria-label={`Shop for ${htmldecoder(title)}`}
            type="button"
            className="configure"
            tabIndex={0}
          >
            Shop
          </button>
        </div>
      </div>
    </Link>
  );
};

export default ItemCard;
