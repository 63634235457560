import React, { useState, useEffect, useContext, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { CATEGORY_FETCH_LINK } from "../redux/links.js";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { PROJECT_LINK } from "../project-config";
import ItemCard from "../components/AC-CategoryPage/components/ItemCard/ItemCard";
import Grid from "@material-ui/core/Grid";

import { toggleWishListAction } from "../redux/actions/wishListActions";

import { I18nContext } from "../i18n";

function ItemsShow() {
  const { langCode } = useContext(I18nContext);

  const dispatch = useDispatch();

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const containerRef = useRef({ current: null });

  const [products, setProducts] = useState([]);
  console.log("containerRef", containerRef, products);

  const featuredProducts = useSelector(
    state => state.mainReducer.featuredProducts,
    shallowEqual
  );

  const featuredCategoryState = useSelector(
    state => state.menuReducer.featuredCategory,
    shallowEqual
  );

  /*   useEffect(() => {
    if (containerRef && containerRef.current && products.length > 0) {
      console.log("asdsad");
      containerRef.current.goToSlide(0);
    }
  }, [containerRef.current]); */

  useEffect(() => {
    if (featuredProducts && featuredProducts[1] && featuredProducts[1].items) {
      setProducts(featuredProducts[1].items);
    }
  }, [featuredProducts]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1600 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 1600, min: 1360 },
      items: 5
    },
    mdDesktop: {
      breakpoint: { max: 1360, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 768, min: 520 },
      items: 2
    },
    xsMobile: {
      breakpoint: { max: 520, min: 0 },
      items: 1
    }
  };

  const wishListState = useSelector(
    state => state.wishListReducer.wishlist,
    shallowEqual
  );

  const toggleWish = (
    e,
    id,
    code,
    title,
    desc,
    currency_sign,
    image,
    price,
    url
  ) => {
    e.preventDefault();
    dispatch(
      toggleWishListAction(
        id,
        code,
        title,
        desc,
        currency_sign,
        image,
        price,
        url,
        wishListState
      )
    );
  };

  const renderPlaceholderCards = () => {
    return Array(6)
      .fill(0, 0, 6)
      .map((v, i) => (
        <Grid
          key={i}
          item
          className="item-card-item"
          xs={12}
          style={{ padding: "0 15px" }}
        >
          <div
            className="placeholder-item-card-wrapper"
            style={{ boxShadow: "0px 0px 1px 0px #c8c8c8" }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                height: "260px"
              }}
            ></div>
            <div>
              <div
                style={{
                  backgroundColor: "#f5f5f5",
                  height: "40px"
                }}
              ></div>
            </div>
          </div>
        </Grid>
      ));
  };

  const isLoading = featuredProducts === "fetching";

  return (
    <div>
      <div className="category-container featured-item-con">
        <h2 style={{ margin: "20px 0 0 0" }}>
          {featuredCategoryState && featuredCategoryState.description}
        </h2>
        <div className="divider-gray-gradient"></div>
        {/*  {!isLoading ? ( */}
        <Carousel
          ref={containerRef}
          swipeable={false}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          autoPlay={isMobileState ? true : false}
          autoPlaySpeed={3000}
          keyBoardControl={true}
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["mobile", "xsMobile"]}
          // deviceType={this.props.deviceType}
          dotListClass="custom-dot-list-style"
        >
          {!isLoading
            ? products.map((item, index) => (
                <div
                  onFocus={() => {
                    if (containerRef && containerRef.current) {
                      containerRef.current.goToSlide(index);
                    }
                  }}
                  key={item.id}
                  className="owl-item col-lg-12 col-md-12 col-sm-12 col-xs-12"
                >
                  <ItemCard
                    key={item.id}
                    itemCard={item}
                    toggleWish={toggleWish}
                    wishListState={wishListState}
                  />
                </div>
              ))
            : renderPlaceholderCards()}
        </Carousel>
      </div>
    </div>
  );
}

export default ItemsShow;
