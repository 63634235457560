import React, { useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { setAddToCartValidationErrors } from "../../../../redux/actions/productAction";
import { I18nContext } from "../../../../i18n";
import classes from "./Styles/CartValidationErrors.module.css";
import close_white from "../../../../assets/icons/close_white.svg";
import Modal from "@material-ui/core/Modal";

const CartValidationErrors = () => {
  const dispatch = useDispatch();

  const { translate } = useContext(I18nContext);

  const validationErrorsState = useSelector(
    state => state.productReducer.itemDetail.cartValidationErrors,
    shallowEqual
  );

  const handleModalClose = () => {
    dispatch(setAddToCartValidationErrors([]));
  };

  if (validationErrorsState && validationErrorsState.length > 0) {
    return (
      <Modal
        open={validationErrorsState.length > 0}
        onClose={
          document.body.onkeyup = function(e){
            if(e.keyCode === 32){
              handleModalClose()
            }
          }
        }
      >
        <div className={classes.container} onClick={handleModalClose}>
          <div
            className={classes.wrapper}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <div className={classes.head}>
              <h4>{translate("cartValidation.problemAddingToCart")}</h4>
              <em tabIndex={0}>
                <img
                  src={close_white}
                  alt="close icon"
                  onClick={handleModalClose}
                ></img>
              </em>
            </div>
            <div className={classes.body} role="alert">
              <h6>{translate("cartValidation.followingIsRequired")}</h6>
              <div className={classes.errTextWrapper}>
                {validationErrorsState.map(err => (
                  <p className={classes.errText}>{err}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  } else {
    return null;
  }
};

export default CartValidationErrors;
