import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  jumpToReviewTabOnCustomizerAction,
  saveInputsAction,
  setPersonalizedQuestionsProgressAction
} from "../../../../redux/actions/productAction";
import { SECTION_BUTTONS } from "./data/section-buttons";

import classes from "./SectionButtons.module.css";

let initialLoad = true;

export default function SectionButtons({
  activeSection,
  setActiveSection,
  quantityTabState
}) {
  const dispatch = useDispatch();

  const personalizedQuestionsProgressState = useSelector(
    state => state.productReducer.personalizedQuestionsProgress,
    shallowEqual
  );

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const biidReviewState = useSelector(
    state => state.productReducer.biidReview,
    shallowEqual
  );

  useEffect(() => {
    if (personalizedQuestionsProgressState.progress !== undefined) {
      const container = document.getElementById("chequeCustomizationContainer");
      const rightGridEl = document.getElementById("rightGrid");
      if (container) {
        container.scrollTop = 0;
      }

      if (rightGridEl && isMobileState && !initialLoad) {
        window.scrollTo(0, rightGridEl.offsetTop - 55); // 55 is the height of the header
      }

      setActiveSection(
        SECTION_BUTTONS.find(
          btn => btn.progress === personalizedQuestionsProgressState.progress
        )
      );

      initialLoad = false;
    }
  }, [personalizedQuestionsProgressState.progress]);

  console.info("bundleitems", personalizedQuestionsProgressState.bundleItems);

  return (
    <div id="section-buttons" className={classes.sectionBtns}>
      {SECTION_BUTTONS.map(btn => (
        <div
          tabIndex={btn.progress < activeSection.progress ? "0" : "-1"}
          key={btn.name}
          onKeyPress={e => {
            const key = e.key;
            if (key === "Enter") {
              const target = e.target;
              if (target) target.click();
            }
          }}
          onClick={() => {
            if (btn.progress === 0) {
              dispatch(saveInputsAction());
              dispatch(jumpToReviewTabOnCustomizerAction(false));
              dispatch(
                setPersonalizedQuestionsProgressAction({
                  displayQuestions: false
                })
              );
            }
            if (btn.progress < activeSection.progress)
              dispatch(
                setPersonalizedQuestionsProgressAction({
                  progress: btn.progress
                })
              );
          }}
          style={{
            width: `${100 / SECTION_BUTTONS.length}%`,
            backgroundColor:
              btn.progress <= activeSection.progress ? "#f5f5f5" : "#F9F9F9",
            color: btn.progress < activeSection.progress ? "#038203" : null,
            cursor: btn.progress < activeSection.progress ? "pointer" : null,
            textDecoration:
              btn.progress < activeSection.progress ? "underline" : null
          }}
          className={`${classes.sectionBtn}${
            activeSection.name === btn.name ? " " + classes.active : ""
          }`}
          name={btn.name}
          data-progress={btn.progress.toString()}
          //onClick={() => handleSectionClicked(btn)}
        >
          <h2
            style={{
              fontSize: "1.08em",
              margin: 0,
              padding: 0,
              backgroundColor: "#fff0",
              display: "block",
              fontWeight: 600
            }}
          >
            {btn.title}
          </h2>
          {/*  {btn.progress < activeSection.progress && (
              <p
                onClick={() => {
                  dispatch(
                    setPersonalizedQuestionsProgressAction({
                      progress: btn.progress
                    })
                  );
                }}
              >
                Edit
              </p>
            )} */}
        </div>
      ))}
    </div>
  );
}
