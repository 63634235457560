import React, { useState, useContext } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  VID,
  PROJECT_LINK,
  PREVIEW,
  LINK_DISTRIBUTION
} from "../project-config.js";
import ProfileIcon from "../assets/img/Profile.png";
import Modal from "../shared/components/UIElements/Modal";
import Drawer from "@material-ui/core/Drawer";
import LazyLoad from "react-lazyload";
import Login from "../components/AC-LoginBox/Login";
import { I18nContext } from "../i18n";
import account_circle from "../assets/icons/account_circle.svg";
import account_circle_filled from "../assets/icons/account_circle_filled.svg";
import close_white from "../assets/icons/close_white.svg";
import { confirmLeaveAction } from "../redux/actions/productAction.js";

import { handleLogout } from "../redux/actions/loginActions.js";

export default function Profile({
  modalOpenedByIcon,
  setModalOpened,
  logoutOnly
}) {
  const dispatch = useDispatch();
  const { langCode, translate } = useContext(I18nContext);

  const [Loginstate, LoginsetState] = useState({
    top1: false,
    left1: false,
    bottom1: false,
    right1: false
  });

  const [AfterLoginstate, AfterLoginsetState] = useState({
    top1: false,
    left1: false,
    bottom1: false,
    right1: false
  });

  const toggleDrawerLogin = (side, open) => event => {
    if (event.key === "Tab" || event.key === "Shift") {
      return;
    }

    LoginsetState({ ...Loginstate, [side]: open });
  };

  const toggleDrawerAfterLogin = (side, open) => event => {
    if (event.key === "Tab" || event.key === "Shift") {
      return;
    }

    AfterLoginsetState({ ...AfterLoginstate, [side]: open });
  };

  const sideListLogin = side => (
    <div role="presentation">
      <Login />
    </div>
  );

  const securityTokenState = useSelector(
    state => state.loginReducer.securityToken,
    shallowEqual
  );
  const loginNameState = useSelector(
    state => state.loginReducer.loginName,
    shallowEqual
  );

  const companyNameState = useSelector(
    state => state.loginReducer.companyname,
    shallowEqual
  );

  const isLoggedInState = loginNameState || companyNameState;

  const languageState = useSelector(
    state => state.mainReducer.lang,
    shallowEqual
  );

  const displayQuestionsState = useSelector(
    state =>
      state.productReducer.personalizedQuestionsProgress.displayQuestions,
    shallowEqual
  );

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const logout = e => {
    e.preventDefault();
    dispatch(handleLogout(securityTokenState));
  };

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (setOpen) setOpen(modalOpenedByIcon);
  }, [modalOpenedByIcon]);

  React.useEffect(() => {
    if (setModalOpened) setModalOpened(open);
  }, [open]);

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  if (logoutOnly) {
    return (
      <div>
        <button
          className="configure"
          style={{ fontStyle: "normal" }}
          role="button"
          onClick={e => {
            if (isLoggedInState) logout(e);
          }}
        >
          {translate("jsp.header_logout")}
        </button>
        <button
          className="configure"
          style={{ fontStyle: "normal" }}
          role="button"
          onClick={e => {
            if (!isLoggedInState)
              window.location.href = `${LINK_DISTRIBUTION}/signin.html?vid=${VID}`;
          }}
        >
          <a></a>
          {translate("js.header.login")}
        </button>
      </div>
    );
  }

  return isLoggedInState != "" ? (
    <LazyLoad height={18} offset={100} debounce={300} fadein={true}>
      {/* <div className="icon-wrapper" onClick={
          langCode === "ar"
            ? toggleDrawerAfterLogin("left", true)
            : toggleDrawerAfterLogin("right", true)
        }>
                    <img
                      src={ProfileIcon}
                      alt="Profile"
                      className="img-responsive iconInfo3"  
                    />
                  </div> */}
      <div
        className="login-text-container"
        onClick={() => {
          const urlToAccountPreferences = `${LINK_DISTRIBUTION}/myaccount.html?mode=shippingaddress&vid=${VID}`;
          if (displayQuestionsState) {
            dispatch(confirmLeaveAction({ url: urlToAccountPreferences }));
          } else window.location.href = urlToAccountPreferences;
        }}
      >
        <div tabIndex="0" className="focusAble" role="link">
          <span className="icon--text" title={companyNameState}>
            {companyNameState}
          </span>
          <div
            style={{ marginLeft: isMobileState ? "" : "5px" }}
            className="icon-wrapper"
          >
            {isMobileState ? (
              <img src={account_circle} alt="account preferences icon"></img>
            ) : (
              <img
                src={account_circle_filled}
                alt="account preferences icon"
              ></img>
            )}
          </div>
        </div>
        <div>
          <a
            role="link"
            aria-label="Account Preferences"
            href={"#"}
            className="profile-action-link"
            onClick={e => e.preventDefault()}
          >
            Account Preferences
          </a>
        </div>
      </div>

      <Drawer
        className="loginState"
        anchor={langCode === "ar" ? "left" : "right"}
        open={langCode === "ar" ? AfterLoginstate.left : AfterLoginstate.right}
        onClose={
          langCode === "ar"
            ? toggleDrawerAfterLogin("left", false)
            : toggleDrawerAfterLogin("right", false)
        }
      >
        <div className={langCode == "ar" ? "inner arabic" : "inner"}>
          <div style={{ backgroundColor: "#666", color: "white" }}>
            <h6 className="minicart-title CustomerLogin">
              MyAccount
              <img
                onClick={
                  langCode === "ar"
                    ? toggleDrawerAfterLogin("left", false)
                    : toggleDrawerAfterLogin("right", false)
                }
                style={{ cursor: "pointer", fontSize: "38px" }}
                src={close_white}
              ></img>
            </h6>
          </div>
          <ul className="user-profile-dropdown">
            <a
              href={`${PROJECT_LINK}/myaccount.html?mode=changepassword&vid=${VID}&iu=${languageState}`}
            >
              <li className="myaccount-box">
                <em className="material-icons">vpn_key</em>{" "}
                {translate("jsp.header_changepassword")}
              </li>
            </a>

            <a
              href={`${PROJECT_LINK}/myaccount.html?mode=viewmixorder&vid=${VID}&iu=${languageState}`}
            >
              <li className="myaccount-box">
                <em className="material-icons">visibility</em>{" "}
                {translate("jsp.header_vieworders")}
              </li>
            </a>
            <a
              href={`${PROJECT_LINK}/myaccount.html?mode=activities&vid=${VID}&iu=${languageState}`}
            >
              <li className="myaccount-box">
                <em className="material-icons">notifications</em>{" "}
                {translate("jsp.header_notifications")}
              </li>
            </a>
            <a
              href={`${PROJECT_LINK}/myaccount.html?mode=billingaddress&vid=${VID}&iu=${languageState}`}
            >
              <li className="myaccount-box">
                <em className="material-icons">store_mall_directory</em>{" "}
                {translate("jsp.header_billingaddress")}
              </li>
            </a>
            <a
              href={`${PROJECT_LINK}/myaccount.html?mode=shippingaddress&vid=${VID}&iu=${languageState}`}
            >
              <li className="myaccount-box">
                <em className="material-icons">local_shipping</em>{" "}
                {translate("jsp.header_shippingaddress")}
              </li>
            </a>
            <a href="javascript:void(0);" onClick={logout}>
              <li className="myaccount-box">
                <em className="material-icons">power_settings_new</em>{" "}
                {translate("jsp.header_logout")}
              </li>
            </a>
          </ul>
        </div>
      </Drawer>

      {/* <Modal
        className="fim-dropdown sign-display display-signin"
        show={open}
        onCancel={handleClickAway}
        style={{ zIndex: "9999" }}
        dropDownList={true}
        noClass
        noHeader
        noFooter
      >
        
      </Modal> */}
    </LazyLoad>
  ) : (
    <>
      {/* <div
        className="icon-wrapper"
        onClick={event => {
          langCode === "ar"
            ? toggleDrawerLogin("left", true)(event)
            : toggleDrawerLogin("right", true)(event);
        }}
      >
        <div className="icon-text">
          <span
            className="icon--text"
            style={{
              textDecoration: "none"
            }}
          >
            Sign In
          </span>
        </div>
        <div
          style={{ marginLeft: isMobileState ? "" : "5px" }}
          className="icon-wrapper"
        >
          <img src={account_circle} alt="account circle icon" />
        </div>
      </div>
      <div
        style={{ display: isMobileState ? "none" : "flex", height: "15px" }}
      ></div>

      <Drawer
        className="loginState"
        anchor={langCode === "ar" ? "left" : "right"}
        open={langCode === "ar" ? Loginstate.left : Loginstate.right}
        onClose={
          langCode === "ar"
            ? toggleDrawerLogin("left", false)
            : toggleDrawerLogin("right", false)
        }
      >
        <div style={{ backgroundColor: "#666", color: "white" }}>
          <h6 className="minicart-title CustomerLogin">
            Sign in
            <img
              onClick={event => {
                langCode === "ar"
                  ? toggleDrawerLogin("left", false)(event)
                  : toggleDrawerLogin("right", false)(event);
              }}
              style={{ cursor: "pointer", fontSize: "38px" }}
              src={close_white}
            ></img>
          </h6>
        </div>
        {langCode === "ar" ? sideListLogin("left") : sideListLogin("right")}
      </Drawer> */}
    </>
  );
}
