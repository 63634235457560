import { SHOW_CONFIRM, HIDE_CONFIRM } from "../types.js";

const initialState = {
  showConfirm: false,
  confirmMessage: ""
};

const compareListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_CONFIRM:
      return {
        ...state,
        showConfirm: true,
        confirmMessage: payload
      };

    case HIDE_CONFIRM:
      return {
        ...state,
        showConfirm: false,
        confirmMessage: ""
      };
    default:
      return state;
  }
};

export default compareListReducer;
