import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";

import htmldecoder from "../../../../functions/htmldecoder";
import info from "../../../../assets/icons/info.svg";

import { I18nContext } from "../../../../i18n/index";
import "./Styles/OtherInfoTab.css";

const PROP_KEYS_TO_BE_IGNORED = [
  "Sys_Num_Images",
  "UPC",
  "Gallery Images",
  "Pdf Files",
  "Sys_Package_Price_Enabled",
  "createtime"
];

const OtherInfoTab = ({ fullWidth }) => {
  const { translate } = React.useContext(I18nContext);

  /*   const longDescState = useSelector(
    state => state.productReducer.productInitial.longdesc,
    shallowEqual
  ); */

  const { longdesc, longdesc2, longdesc3, longdesc4 } = useSelector(
    state => state.productReducer.itemDetail,
    shallowEqual
  );

  const propertiesReduxState = useSelector(
    state => state.productReducer.productInitial.properties,
    shallowEqual
  );

  const [propertiesState, setPropertiesState] = useState([]);

  useEffect(() => {
    if (propertiesReduxState && propertiesReduxState.length > 0) {
      setPropertiesState(
        propertiesReduxState.filter(
          property =>
            !PROP_KEYS_TO_BE_IGNORED.includes(Object.keys(property)[0])
        )
      );
    }

    return () => {
      setPropertiesState([]);
    };
  }, [propertiesReduxState]);

  return (
    <div
      id="product-specs"
      tabIndex="0"
      className={`product-details-specs-container${
        fullWidth ? " full-width" : ""
      }`}
      style={{ backgroundColor: "white" }}
    >
      <div className="product-details-specs-wrapper">
        <div className="product-specs-wrapper">
          <h2>
            <img src={info} alt="info icon"></img>
            {translate("js.item.specifications")}
          </h2>
          <div className="products-specs-content">
            <ul>
              {longdesc && (
                <li>
                  <div dangerouslySetInnerHTML={{ __html: longdesc }} />
                </li>
              )}
              {longdesc2 && (
                <li>
                  <div dangerouslySetInnerHTML={{ __html: longdesc2 }} />
                </li>
              )}
              {longdesc3 && (
                <li>
                  <div dangerouslySetInnerHTML={{ __html: longdesc3 }} />
                </li>
              )}
              {longdesc4 && (
                <li>
                  <div dangerouslySetInnerHTML={{ __html: longdesc4 }} />
                </li>
              )}
              {propertiesState && propertiesState.length > 0
                ? propertiesState
                  ? propertiesState.map(property => {
                      let key = Object.keys(property)[0];
                      let value = Object.values(property)[0];
                      return (
                        <li key={key}>
                          <label>
                            <strong>{key}:&nbsp;</strong>
                          </label>
                          <div>{value}</div>
                        </li>
                      );
                    })
                  : null
                : null}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherInfoTab;
