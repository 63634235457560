import { takeLatest } from "redux-saga/effects";
import {
  FETCHING_MENU_SUCCESS,
  REQUEST_BASKET_AFTER_ADDING_TO_CART
} from "../types";
import { fetchBasketSaga } from "../../redux/actions/basketActions";

export function* basketFetchSaga() {
  //yield takeLatest(ADD_TO_CART_MODAL_CLOSE, fetchBasketSaga);
  yield takeLatest(FETCHING_MENU_SUCCESS, fetchBasketSaga);
}
export function* basketFetchAfterAddedSaga() {
  yield takeLatest(REQUEST_BASKET_AFTER_ADDING_TO_CART, fetchBasketSaga);
}
