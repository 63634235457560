import {
  SET_SELECTED_STORE,
  FETCH_STORES_REQUEST,
  FETCH_STORES_SUCCESS,
  FETCH_STORES_FAILURE,
  FETCHING_CATEGORY_SUCCESS
} from "../types";

const initialState = {
  selectedStore: null,
  loading: true
  /*  stores: [] */
  /* storeItems: [],
  parents: [],
  
  errorMessage: "" */
};

const storeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SELECTED_STORE:
      return {
        ...state,
        selectedStore: payload
      };
    case FETCH_STORES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCHING_CATEGORY_SUCCESS: {
      if (payload.cat == "Exhibitors")
        return {
          ...state,
          stores: payload.categoryItems
        };
    }
    case FETCH_STORES_SUCCESS:
      return {
        ...state,
        numberOfItems: payload.numberOfItems,
        stores: payload.stores,
        tempFacets: payload.json[2].facets,
        pages: payload.tempages,
        cat: payload.cat,
        cidN: payload.cid,
        parents: payload.parents,
        loading: false,
        currentPage: 1,
        scroolPage: 1,
        resetCid: payload.resetCid
      };
    case FETCH_STORES_FAILURE:
      return {
        ...state,
        errorMessage: payload
      };
    default:
      return state;
  }
};

export default storeReducer;
