import { PREVIEW } from "../project-config.js";
import { translations } from "../i18n/index";
const categoryMapping = (arg, lang) => {
  let allCategoryNames = [];
  let navTemp = { cid: 0, name: 0 };
  navTemp = arg;
  let foundCat = null;

  let activeFacets = {};

  const searchChildCats = (cat, name) => {
    if (Array.isArray(cat)) {
      cat.forEach(c => {
        return searchChildCats(c, name);
      });
    } else {
      if (cat.childs && cat.childs.length > 0) {
        const FOUND_CAT = cat.childs.find(c => c.URL.includes(name));

        if (FOUND_CAT) foundCat = FOUND_CAT;
        else return searchChildCats(cat.childs, name);
      }
    }
  };

  const nestedRouting = category => {
    if (navTemp != undefined) {
      searchChildCats(navTemp, category);

      if (foundCat) {
        allCategoryNames.push([
          foundCat.description,
          foundCat.cid,
          foundCat.URL
        ]);

        navTemp = foundCat;
      } else {
        navTemp = { cid: 0, name: 0 };
      }
      /*     
      let temp = navTemp.childs.find(child => {
        let name = child.URL.split("/");
        name = name.filter(f => f !== "");
        name = name[name.length - 1];
        

        let parentName = child.description.replace("&amp;", "&");
        if (name.includes("&amp;")) {
          name = name.replace(" &amp; ", "-");
        }
        if (category.toLowerCase().includes(name)) {
          allCategoryNames.push([parentName, child.cid, child.URL]);
          return true;
        } else {
          return false;
        }
      });

      

      if (temp != undefined) {
        navTemp = temp;
      } else {
        navTemp = { cid: 0, name: 0 };
      } */
    } else {
      navTemp = { cid: 0, name: 0 };
    }
  };

  let params = window.location.pathname.replace(`${PREVIEW}`, "");

  //Translate Part
  params = params.replace(`/${lang}/`, "/");

  params = params.split("/").filter(param => {
    if (param !== "" && param !== "preview" && param !== "shop") {
      return true;
    } else {
      return false;
    }
  });

  if (params[0] != "" && !params.includes("exhibitors")) {
    if (params[0] == "products") {
      activeFacets = { [params[1]]: params[2] };
      params = [params[0]];
    }

    params
      .filter(fil => (fil != "" ? true : false))
      .map(routeCat => {
        nestedRouting(routeCat);
      });
  } else {
    params
      .filter(fil => (fil != "" ? true : false))
      .map(routeCat => {
        nestedRouting(routeCat);
      });
  }

  return { ...navTemp, parents: allCategoryNames, activeFacets };
};

export default categoryMapping;
