import React, { useContext } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { backToCategory } from "../../../../redux/actions/categoryActions";
import history from "../../../../history";
import { I18nContext } from "../../../../i18n/index";
import { unMountProductPageAction } from "../../../../redux/actions/productAction";
function GoBackButton({ standalone }) {
  const dispatch = useDispatch();
  const { langCode, translate } = useContext(I18nContext);

  return (
    <div
      className={standalone ? "sub-nav-bread" : ""}
      style={{ width: standalone ? "" : "150px" }}
    >
      <button
        className={`backBtn${standalone ? " standalone" : ""}`}
        onClick={() => {
          dispatch(unMountProductPageAction());
          dispatch(backToCategory());
          history.go(-1);
        }}
      >
        {translate("compItems.goBackButton")}
      </button>
    </div>
  );
}

export default GoBackButton;
