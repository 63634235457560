import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { PREVIEW } from "../../project-config.js";
import history from "../../history.js";
import Category from "../../pages/Category.jsx";

import {
  fetchCategoryFromRender,
  changeKeyword,
  changeCategoryName
} from "../../redux/actions/categoryActions.js";

import { I18nContext } from "../../i18n";

export default function Search() {
  const { translate, langCode } = useContext(I18nContext);
  const dispatch = useDispatch();

  const navCategoryState = useSelector(
    state => state.menuReducer.navCategory,
    shallowEqual
  );

  const [keyword, setKeyword] = useState("");

  console.log("keyword", keyword);

  useEffect(() => {
    if (keyword) {
      dispatch(changeKeyword(keyword));
      dispatch(changeCategoryName(keyword, [], "search", ""));
      dispatch(
        fetchCategoryFromRender(
          "search",
          keyword,
          [],
          keyword,
          navCategoryState
        )
      );
    } else {
      if (window.location.pathname.includes("search/")) {
        let tempKeyword = history.location.pathname.split(`search/`)[1];
        tempKeyword = tempKeyword
          .replace(/</g, "")
          .replace(/>/g, "")
          .replace(/"/g, "")
          .replace(/&.+;/g, "")
          .replace(/%3C/gi, "")
          .replace(/%3E/gi, "")
          .replace(/&lt/gi, "")
          .replace(/&gt/gi, "")
          .replace(/&amp/gi, "")
          .replace(/&quot/gi, "")
          .replace(/&apos/gi, "")
          .replace(/&sol/gi, "")
          .replace(/&#x2F;/gi, "")
          .replace(/=/gi, "")
          .replace(/&equals;/gi, "")
          .replace(/&.+/g, "");

        setKeyword(tempKeyword);
      }
    }
  }, [keyword]);

  return <Category />;
}
