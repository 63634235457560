import React, { useState, useEffect, useContext } from "react";
import history from "../../history";
import {
  VID,
  PREVIEW,
  PROJECT_LINK,
  LINK_DISTRIBUTION
} from "../../project-config.js";
import {
  LOGIN_SIGNIN_LINK,
  LOGIN_CHECK_LINK,
  LOGOUT_LINK
} from "../../redux/links.js";
import CatLoading from "../AC-Loading/CatLoading.jsx";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import Async from "react-code-splitting";
import {
  setSignInMessageAction,
  updateLoginInfo
} from "../../redux/actions/loginActions.js";
import { I18nContext } from "../../i18n/index";
import classes from "./Styles/Login.module.css";

const Footer = () => <Async load={import("../AC-Footer/Footer.jsx")} />;

export default function Login() {
  const { langCode, translate } = useContext(I18nContext);

  const [isLogined, setisLogined] = useState(false);
  const [loginInfo, setloginInfo] = useState();
  const [isLoading, setisLoading] = useState(false);
  const dispatch = useDispatch();

  const languageState = useSelector(
    state => state.mainReducer.lang,
    shallowEqual
  );

  useEffect(() => {
    return () => {
      dispatch(setSignInMessageAction({ message: "" }));
    };
  }, []);

  const messageState = useSelector(
    state => state.loginReducer.message,
    shallowEqual
  );

  const [open, setOpen] = React.useState(false);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  function handleSubmit(event) {
    event.preventDefault();
    setisLoading(true);
    var form = new FormData();
    form.append("doSubmit", "Log In");
    form.append("capchacount", "0");
    form.append("loginInput", event.target.email.value);
    form.append("login", event.target.email.value);
    form.append("password", event.target.password.value);
    form.append("logSubmit", "Log In");
    fetch(LOGIN_SIGNIN_LINK.replace("$LANGUAGE", langCode), {
      method: "POST",
      body: form,
      mimeType: "multipart/form-data"
    })
      .then(res => {
        return res;
      })
      .then(res => res.json())
      .then(json => {
        dispatch(setSignInMessageAction(json));
      })
      .catch(err => {
        console.log("error---", err);
      })
      .then(() => {
        console.log("check login");
        fetchJson(LOGIN_CHECK_LINK).then(json => {
          console.log("check login3", json);

          sessionStorage.setItem("UserData", JSON.stringify(json.__Result));
          dispatch(updateLoginInfo(json.__Result));
          setloginInfo(json.__Result);
          setisLoading(false);
        });
      });
  }

  const fetchJson = async url => {
    const response = await fetch(url);
    return await response.json();
  };

  function logout(event) {
    event.preventDefault();
    setisLoading(true);
    var form = new FormData();
    form.append("securitytoken", loginInfo.securitytoken);

    fetch(LOGOUT_LINK, {
      method: "POST",
      body: form,
      mimeType: "multipart/form-data"
    })
      .then(res => res.json())
      .then(json => console.log("TEST FORM", json))

      // It parses the output
      .catch(function (error) {
        console.log("error---", error);
      })
      .then(() => {
        fetchJson(LOGIN_CHECK_LINK).then(json => {
          sessionStorage.setItem("UserData", JSON.stringify(json.__Result));
          if (json.__Result.loggedin == true) {
            setloginInfo(json.__Result);
          } else {
            setloginInfo(json.__Result);
          }
        });
      })
      .then(() => {
        setisLoading(false);
      });
  }

  useEffect(() => {
    if (loginInfo != undefined) {
      if (loginInfo.loggedin == true) {
        setisLogined(true);
      } else {
        setisLogined(false);
      }
    }
  }, [loginInfo]);

  useEffect(() => {
    fetchJson(LOGIN_CHECK_LINK).then(json => {
      console.log("check login2", json);
      sessionStorage.setItem("UserData", JSON.stringify(json.__Result));

      if (json.__Result.loggedin == true) {
        setloginInfo(json.__Result);
      }
    });
  }, []);

  if (isLoading) {
    return (
      <div>
        <div>
          <div>
            <CatLoading />
          </div>
        </div>
      </div>
    );
  } else if (!isLogined) {
    return (
      <React.Fragment>
        {/* <h6 className="minicart-title CustomerLogin">
        {translate("CustomerLogin")}
                <em  onClick={handleDrawerClose}
                  className="material-icons"
                  style={{ cursor: "pointer", fontSize: "38px" }}
                >
                  close
                </em>
              </h6> */}

        <div
          className="row"
          style={{
            marginBottom: "0px",
            height: "100%"
          }}
        >
          <div className={classes.loginWrapper} id="loginBox">
            <div>
              <form name="loginPopup" onSubmit={handleSubmit} autoComplete="on">
                <div
                  className="error-text"
                  id="login_error"
                  style={{ display: "block" }}
                />
                <div>
                  <div
                    className={`col l12 m12 s12 ${classes.loginInputContainer}`}
                  >
                    <div>
                      <input
                        id="email"
                        className="ml-input"
                        name="loginInput"
                        type="email"
                        required=""
                        placeholder={translate("EnteryourEmail")}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={`col l12 m12 s12 ${classes.loginInputContainer}`}
                >
                  <div>
                    <input
                      id="password"
                      className="ml-input"
                      name="password"
                      type="password"
                      required=""
                      placeholder={translate("EnterYourPassword")}
                      autoComplete="on"
                    />
                  </div>
                </div>

                <div className="signin-message-wrapper">
                  {messageState && <p>{messageState.message}</p>}
                </div>

                <div
                  className="loginButtons"
                  style={{
                    paddingRight: "0px"
                  }}
                >
                  {/*<span>
                  <a
                    href="https://c10in1.avetti.ca/preview/forgot_password.html?vid=20190607423&amp;mt=3"
                    className="view-cart title-link text-uppercase"
                  >
                    Forgot Password?{" "}
                    <em className="material-icons arrowposs">navigate_next</em>
                  </a>
                </span>
                <Button color="primary">
                  Login 
                </Button>*/}

                  <button
                    type="submit"
                    className="btn waves-effect waves-light btn-light-dark"
                    style={{
                      background: "rgb(204, 0, 0)",
                      width: "100%",
                      margin: "10px 0px 20px 0px"
                    }}
                  >
                    {translate("Login")}
                  </button>
                  <div className="data-login">
                    <a
                      className={`services-right ${classes.forgotPasswordLink}`}
                      href={`${LINK_DISTRIBUTION}/forgot_password.html?vid=${VID}&amp;mt=1&ml=${languageState}`}
                    >
                      {translate("ForgotPassword")}
                    </a>
                    <div className={`all_services ${classes.allServices}`}>
                      {" "}
                      {translate("Signupmessage")}
                    </div>

                    {/*        <div
                      className="login_registerbtn"
                      style={{ margin: "10px 0px" }}
                    >
                      <a
                        href={`${LINK_DISTRIBUTION}/register.html?vid=${VID}&mt=1&ml=${languageState}`}
                        className="waves-effect waves-light btn cr-button"
                        style={{
                          background: "#000",
                          width: "100%",
                          color: "white",
                          textTransform: "uppercase",
                          fontSize: "13px"
                        }}
                      >
                        {translate("CreateAccount")}
                      </a>
                    </div> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </React.Fragment>
    );
  } else {
    console.log(loginInfo);
    history.goBack();
    return (
      <React.Fragment>
        <div id="bd">
          <div className="header-cart-2 z-depth-1">
            <h6>
              Welcome <strong>{loginInfo.loginname}</strong>
            </h6>
            <div className="login-box-inline actions text-center">
              <form name="logoutPopup" onSubmit={logout} autoComplete="on">
                <button
                  type="submit"
                  className="btn waves-effect waves-light btn-light-dark"
                >
                  {translate("jsp.header_logout")}
                </button>
              </form>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </React.Fragment>
    );
  }
}
