import React from "react";

// core components

import StaticContent from "../components/AC-StaticPages/StaticContent.jsx";

import MainCategories from "./MainCategories";

import FeaturedChecks from "./FeaturedChecks";

function Main(props) {
  if (props.component === "staticContent") {
    return (
      <React.Fragment>
        <div id="bd">
          <StaticContent />
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <div id="bd">
          <div className="item">
            <div style={{ padding: "unset" }}></div>

            <MainCategories />
            <FeaturedChecks />

            {/* <ServicesBox />

            <BestSellingProduct /> */}
            {/* <Collections /> */}
            {/* <FeaturedSellers /> */}
            {/* <Featured /> */}
            {/* <FeaturedPromotions /> */}

            {/* <SubscriptionBox /> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Main;
