import { call, put } from "redux-saga/effects";
import {
  FETCHING_CATEGORY_SUCCESS,
  FETCHING_CATEGORY_FAILURE,
  FETCHING_CATEGORY_REQUEST,
  FETCHING_CATEGORY_REQUEST_SAGA,
  CHANGE_CATEGORY_NAME,
  FETCHING_SEARCH_SUCCESS,
  FETCHING_SEARCH_FAILURE,
  CHANGE_KEYWORD_NAME,
  SET_FETCHED_CATEGORY_ITEMS_COUNT,
  FETCHING_CATEGORY_NO_ITEM_FOUND,
  BACK_TO_CATEGORY_REQUEST,
  BACK_TO_CATEGORY_FINISH,
  SET_CATEGORY_DISTANCE
} from "../types.js";
import { CATEGORY_FETCH_LINK, SEARCH_FETCH_LINK } from "../links.js";
import { PREVIEW } from "../../project-config";
import { store } from "../../index";
import buttonsMapping from "../../functions/buttonsMapping.js";
import categoryMapping from "../../functions/categoryMapping.js";
import mapDynamicFacetSliders from "../../functions/mapDynamicFacetSliders";
import capitalize, {
  allFirstLettersCapitalize
} from "../../functions/capitalize";
import { htmlDecode } from "../../functions/htmldecoder";
import { object } from "prop-types";

export const setCategoryDistanceAction = distance => ({
  type: SET_CATEGORY_DISTANCE,
  payload: distance
});

export const backToCategory = () => ({
  type: BACK_TO_CATEGORY_REQUEST
});
export const backToCategoryNormalize = () => ({
  type: BACK_TO_CATEGORY_FINISH
});

export const setFetchedCategoryItemsCount = (itemsCount = -1) => ({
  type: SET_FETCHED_CATEGORY_ITEMS_COUNT,
  payload: itemsCount
});

export const fetchingCategoryNoItemFound = () => ({
  type: FETCHING_CATEGORY_NO_ITEM_FOUND
});

export const fetchingCategorySuccess = (
  json,
  cid,
  cat,
  tempages,
  categoryItems,
  priceButtonsTemp,
  dynamicSlider,
  parents,
  link,
  filterUrl,
  urlFilterParams,
  resetCid,
  numberOfItems
) => ({
  type: FETCHING_CATEGORY_SUCCESS,
  payload: {
    json,
    cid,
    cat,
    tempages,
    categoryItems,
    priceButtonsTemp,
    dynamicSlider,
    parents,
    link,
    filterUrl,
    urlFilterParams,
    resetCid,
    numberOfItems
  }
});

export const fetchingSearchSuccess = (
  json,
  tempages,
  numberOfItems,
  categoryItems,
  priceButtonsTemp,
  dynamicSlider,
  keyword
) => ({
  type: FETCHING_SEARCH_SUCCESS,
  payload: {
    json,
    tempages,
    numberOfItems,
    categoryItems,
    priceButtonsTemp,
    dynamicSlider,
    keyword
  }
});

export const fetchingCategoryRequest = navCategory => ({
  type: FETCHING_CATEGORY_REQUEST,
  payload: navCategory
});

export const fetchingCategoryRequestSaga = (
  cid,
  cat,
  parents,
  keyword,
  navCategory
) => ({
  type: FETCHING_CATEGORY_REQUEST_SAGA,
  payload: { cid, cat, parents, keyword, navCategory }
});

export const fetchingCategoryFailure = error => ({
  type: FETCHING_CATEGORY_FAILURE,
  payload: error
});

export const fetchingSearchFailure = error => ({
  type: FETCHING_SEARCH_FAILURE,
  payload: error
});

class LocalDataService {
  api = link =>
    fetch(link)
      .then(res => res.json())
      .then(json => {
        if (json.length === 0) {
          return { json };
        }
        let tempJson = Object.assign({}, json);

        let tempages = [];
        for (let i = 1; i <= Number(json[0].numOfPages); i++) {
          tempages.push(i);
        }
        let numberOfItems = Number(json[3].itemsCount);
        let categoryItems = json[1].items.map(item => {
          const newObj = Object.assign({}, item);
          // update the new object
          let image = newObj.image;
          newObj.image = image.replace("thumbnails", "images");
          return newObj;
        });
        let priceButtonsTemp = buttonsMapping(json);
        let dynamicSlider = {};
        dynamicSlider = mapDynamicFacetSliders(json);

        let urlFilterParams = "";

        return {
          json,
          tempages,
          categoryItems,
          priceButtonsTemp,
          dynamicSlider,
          urlFilterParams,
          numberOfItems
        };
      })
      .catch(err => {
        console.error("fetching category error:", err);
      });
}

export function* fetchFunctionSaga(action) {
  try {
    let link = "";
    link = setLink(
      action.payload.cid,
      action.payload.keyword,
      action.payload.navCategory
    );

    let filterUrl = link;
    let resetCid = action.payload.cid;

    const api = new LocalDataService();

    const category = yield call(api.api, link);

    if (category.json.length === 0) {
      yield put(fetchingCategoryNoItemFound());
    } else {
      console.info(
        "cat fetchsuccess",
        category.json,
        "13",
        action.payload.cid,
        "12",
        action.payload.cat,
        "10",
        category.tempages,
        "9",
        category.categoryItems,
        "8",
        category.priceButtonsTemp,
        "7",
        category.dynamicSlider,
        "6",
        action.payload.parents,
        "5",
        link,
        "4",
        filterUrl,
        "3",
        category.urlFilterParams,
        "2",
        resetCid,
        "1",
        category.numberOfItems
      );
      yield put(
        fetchingCategorySuccess(
          category.json,
          action.payload.cid,
          action.payload.cat,
          category.tempages,
          category.categoryItems,
          category.priceButtonsTemp,
          category.dynamicSlider,
          action.payload.parents,
          link,
          filterUrl,
          category.urlFilterParams,
          resetCid,
          category.numberOfItems
        )
      );
    }
  } catch (e) {
    console.error("error fetching category:", e);
    yield put(fetchingCategoryFailure(e.message));
  }
}

export const fetchCategoryFromDirectUrl = () => {
  let category = store.getState().menuReducer.navCats;
  let storesState = store.getState().storeReducer.stores;
  let lang = store.getState().mainReducer.lang;

  if (window.location.href.includes("search/")) {
    let keyword = window.location.href.split("search/")[1];

    try {
      keyword = decodeURI(keyword);
    } catch (err) {
      console.error("can't decode url", keyword, err);
    }

    return dispatch => {
      dispatch(fetchingCategoryRequest(category));
      dispatch(
        fetchingCategoryRequestSaga(
          "search",
          keyword,
          [
            ["search", ""],
            [keyword, ""]
          ],
          keyword,
          category
        )
      );
    };
  } else {
    let keyword = "";
    category = categoryMapping(category, lang);

    if (window.location.pathname.includes("/exhibitors/")) {
      keyword = `exhibitors/${
        window.location.pathname.split("exhibitors/")[1]
      }`;
    }

    if (category && category.description) {
      category.description = category.description.replace("&amp;", "&");
    }

    return dispatch => {
      dispatch(changeCategoryName(category.description, category.parents));
      // Need to check if the url includes store in order to make sure the stores are populated, which is required to find out the selected store
      if (
        window.location.pathname.includes("exhibitors/") &&
        storesState == undefined
      ) {
        let category = store.getState().menuReducer.navCats;

        let storesNavCat = category.childs.find(navCat =>
          navCat.URL.includes("exhibitors")
        );

        dispatch(fetchingCategoryRequest(storesNavCat));
        let parent = [[storesNavCat.name, storesNavCat.cid, storesNavCat.URL]];
        //dispatch(setStoresNavCatAction(storesNavCat));
        dispatch(
          fetchingCategoryRequestSaga(
            storesNavCat.cid,
            storesNavCat.name,
            parent,
            "",
            storesNavCat
          )
        );
      }

      dispatch(fetchingCategoryRequest(category));
      dispatch(
        fetchingCategoryRequestSaga(
          category.cid,
          category.description,
          category.parents,
          keyword,
          category
        )
      );
    };
  }
};

export const fetchCategoryFromRender = (
  cid,
  cat,
  parents,
  keyword,
  category
) => {
  return dispatch => {
    console.info(
      "fetching category from render",
      cid,
      cat,
      parents,
      keyword,
      category
    );
    dispatch(fetchingCategoryRequest(category));
    dispatch(fetchingCategoryRequestSaga(cid, cat, parents, keyword, category));
  };
};

export const changeCategoryName = (cat, parents, keywordArg, longdesc) => {
  let keyword = "";
  if (keywordArg == "search") {
    keyword = keywordArg;
  }
  return {
    type: CHANGE_CATEGORY_NAME,
    payload: { cat, parents, keyword, longdesc }
  };
};

export const changeKeyword = keyword => {
  return {
    type: CHANGE_KEYWORD_NAME,
    payload: { keyword }
  };
};

const setLink = (cid, keyword, cat) => {
  let language = store.getState().mainReducer.lang;

  const { lat, lng } = store.getState().loginReducer.userInfo;
  const { lat: geoLat, long: geoLng } =
    store.getState().geoLocationReducer.geoLocation;

  const distance = null; // store.getState().categoryReducer.distance;

  let link = "";

  if (cid == "search") {
    link = SEARCH_FETCH_LINK(keyword, language);
  } else if (keyword.includes("exhibitors/")) {
    keyword = keyword.replace("exhibitors/", "").replace(/-/g, " ");

    keyword = allFirstLettersCapitalize(keyword);
    if (keyword == "Lg") keyword = "LG";

    link =
      CATEGORY_FETCH_LINK(cid, language, lat, lng, distance) +
      `&Sellers=${keyword}`;
  } else {
    link = CATEGORY_FETCH_LINK(
      cid,
      language,
      geoLat || lat,
      geoLng || lng,
      distance
    );
  }

  if (cat && cat.activeFacets && Object.keys(cat.activeFacets).length > 0) {
    let keys = Object.keys(cat.activeFacets);
    if (cat.activeFacets[keys[0]]) {
      let facets = link.includes("?") ? "&" : "&";
      let count = 0;

      const handleConcat = count => {
        if (count > 0) {
          return "&";
        } else {
          return "";
        }
      };

      for (let key of keys) {
        if (cat.activeFacets[key]) {
          facets += `${handleConcat(count)}${capitalize(
            key
          )}=${allFirstLettersCapitalize(cat.activeFacets[key])}`;
          count += 1;
        }
      }

      link = link + facets;
    }
  }
  return link;
};
