import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/features/array";
import "core-js/features/object";
import "core-js/es/symbol";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App.js";
// import { register } from "./serviceWorker.js";
import { Provider } from "react-redux";
import { I18nContextProvider } from "./i18n/index";
import configureStore from "./redux/index.js";

import CacheBuster from "./CacheBuster";
import MetaJson from "./prefetchdata/meta.json";
import { PROJECT_LINK, LINK_DISTRIBUTION } from "./project-config";

if (!navigator.cookieEnabled) {
  alert("You need to enable cookies in order to load this site.");
}

//Cache and LocalStorage Conditions
let metaData = MetaJson.version;
if (!localStorage.getItem("version")) {
  localStorage.setItem("version", metaData);
}
if (metaData !== localStorage.getItem("version")) {
  localStorage.removeItem("language");
  localStorage.removeItem("country");
  localStorage.removeItem("currency");

  localStorage.removeItem("compareItemDetails");
  localStorage.removeItem("userLocation");
  /*   localStorage.removeItem("userLocationGoogleAPI"); */
  /*   localStorage.removeItem("wishList"); */
  localStorage.removeItem("compareList");
  localStorage.removeItem("recentViewItems");
  localStorage.setItem("version", metaData);
}

const IS_PUBLISHED =
  process.env.REACT_APP_IS_PUBLISHED == "true" ? true : false;

//IsPublished

// register();

export const store = configureStore();

ReactDOM.render(
  <CacheBuster>
    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
      if (loading) return null;
      if (!loading && !isLatestVersion) {
        // You can decide how and when you want to force reload
        refreshCacheAndReload();
      }

      return (
        <Provider store={store}>
          <I18nContextProvider>
            <App />
          </I18nContextProvider>
        </Provider>
      );
    }}
  </CacheBuster>,
  document.getElementById("root")
);
